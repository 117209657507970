import { useGetList } from "react-admin";
import { RadioGroupInputProps } from "./common/RadioGroupInput";
import { SliderInput, SliderInputProps } from "./common/SliderInput";
import { Grid } from "@mui/material";

type SliderRadioGroupInputProps = {
  slider: Pick<SliderInputProps, "ariaLabel" | "title">;
};
export type TotalCostSliderInputProps = RadioGroupInputProps &
  SliderRadioGroupInputProps;

const TotalCostSliderInput = (props: TotalCostSliderInputProps) => {
  const {
    source,
    minMaxResource = "",
    slider: { ariaLabel, title },
  } = props;
  const { data, isLoading } = useGetList(
    minMaxResource,
    {},
    {
      /* trigger query only when resource is defined */
      enabled: minMaxResource !== "",
    },
  );

  if (!source) {
    throw new Error(
      `Props source is mandatory on <OptimizedRadioGroupInput />`,
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        {!isLoading && (
          <SliderInput
            min={parseInt(data?.[0]?.min)}
            max={parseInt(data?.[0]?.max)}
            title={title}
            ariaLabel={ariaLabel}
            source={source}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TotalCostSliderInput;
