import { ReactComponent as MaintenanceIllustration } from "./MaintenanceIllustration.svg";
import { Link } from "react-admin";
import HomeIcon from "@mui/icons-material/Home";
import { Typography, Button, Stack, Container, Box } from "@mui/material";

const MaintenancePage = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: "100px" }}>
      <Box textAlign="center" alignItems={"center"}>
        <Box maxWidth={"300px"} margin={"auto"}>
          <MaintenanceIllustration />
        </Box>
        <Typography variant="h1" gutterBottom>
          Under Maintenance
        </Typography>
        <Typography variant="body1" paragraph>
          We are currently performing maintenance on this section of the Trust
          Portal.
        </Typography>
        <Typography variant="body1" paragraph>
          Sorry for the inconvenience. Please check back later.
        </Typography>
        <Stack
          direction="row"
          sx={{ marginX: "auto", gap: 2, justifyContent: "center" }}
        >
          <Link to="/">
            <Button startIcon={<HomeIcon />}>Go Home</Button>
          </Link>
        </Stack>
      </Box>
    </Container>
  );
};

export default MaintenancePage;
