/* eslint-disable react/display-name */
import { forwardRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { Link, LinkProps, useMatch } from "react-router-dom";
import {
  MenuItem,
  ListItemIcon,
  Tooltip,
  useMediaQuery,
  Theme,
  Chip,
} from "@mui/material";
import { useTranslate, useBasename } from "ra-core";
import { MenuItemLinkProps, useSidebarState } from "react-admin";
import { isString } from "../../types/type-guards/type-guards";

export const CustomMenuItemLink = forwardRef<any, CustomMenuItemLinkProps>(
  (props, ref) => {
    const {
      className,
      primaryText,
      leftIcon,
      onClick,
      resourceTotal,
      ...rest
    } = props;

    const isSmall = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down("md"),
    );
    const translate = useTranslate();
    const basename = useBasename();
    const [, setOpen] = useSidebarState();
    const handleMenuTap = useCallback(
      (e: any) => {
        if (isSmall) {
          setOpen(false);
        }
        onClick && onClick(e);
      },
      [setOpen, isSmall, onClick],
    );

    const to = isString(props.to) ? props.to : props.to.pathname || "";
    const match = useMatch({ path: to, end: to === `${basename}/` });
    const TotalChip = () => {
      if (resourceTotal) {
        return (
          <Tooltip
            title={translate("resourceTotal", {
              _: "Total Number of Resources",
            })}
          >
            <Chip
              sx={{
                borderRadius: "15px",
                margin: 0,
                padding: 0,
                height: "2em",
              }}
              label={resourceTotal}
            />
          </Tooltip>
        );
      }
      return null;
    };
    return (
      <StyledMenuItem
        className={clsx(className, {
          [MenuItemLinkClasses.active]: !!match,
        })}
        // @ts-ignore
        component={LinkRef}
        ref={ref}
        tabIndex={0}
        {...rest}
        onClick={handleMenuTap}
      >
        {leftIcon && (
          <ListItemIcon className={MenuItemLinkClasses.icon}>
            {leftIcon}
          </ListItemIcon>
        )}
        {isString(primaryText)
          ? translate(primaryText, { _: primaryText })
          : primaryText}
        <TotalChip />
      </StyledMenuItem>
    );
  },
);

const PREFIX = "RaMenuItemLink";

export const MenuItemLinkClasses = {
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
};

const StyledMenuItem = styled(MenuItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  justifyContent: "space-between",
  [`&.${MenuItemLinkClasses.active}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${MenuItemLinkClasses.icon}`]: { minWidth: theme.spacing(5) },
}));

const LinkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link ref={ref} {...props} />
));

interface CustomMenuItemLinkProps extends MenuItemLinkProps {
  resourceTotal?: string;
}
