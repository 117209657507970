import {
  CheckboxGroupInput,
  CheckboxGroupInputProps,
  ReferenceInputProps,
  useTranslate,
} from "react-admin";
import { FiltersCommonInputProps } from "../../types/default";
import AutoCompleteInput from "./common/AutoCompleteInput";

const OwnerInput = (props: ReferenceInputProps & FiltersCommonInputProps) => {
  const translate = useTranslate();
  return (
    <AutoCompleteInput
      {...props}
      label={translate("filters.owner.label")}
      key="owner"
      attributes_to_get="owner_id"
      search_attribute="owner_id"
      filters={["is_active"]}
    />
  );
};

export const OwnerInactiveCheckboxInput = (props: CheckboxGroupInputProps) => {
  const translate = useTranslate();
  const choices = [
    { id: "false", name: translate("filters.is_active.label.false") },
    { id: "null", name: translate("filters.is_active.label.null") },
  ];
  return (
    <CheckboxGroupInput
      sx={{
        // button of each radio
        [`& .MuiCheckbox-root`]: {
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
      }}
      label=""
      choices={choices}
      row={false}
      {...props}
    />
  );
};

export default OwnerInput;
