/**
 * Format a float with a single digit if digit is not 0
 * @param number
 * @returns {string} formatted value
 */
const formatDigit = (number: number): string => {
  return number.toFixed(1).replace(".0", "");
};

/**
 * Format an amout of money needed to be displayed on the UI
 * @param currency
 * @param currencySign
 * @returns {string} formatted value with currency symbol
 */
export const formatCurrency = (
  currency: number,
  currencySign: string = "$",
): string => {
  const value: number = Math.round(currency);
  const absoluteValue = Math.abs(value);
  if (absoluteValue < 1000) {
    return `${currencySign}${value}`;
  }
  if (absoluteValue < 10 ** 6) {
    return `${currencySign}${formatDigit(value / 1000)}K`;
  }
  if (absoluteValue >= 10 ** 6) {
    return `${currencySign}${formatDigit(value / 10 ** 6)}M`;
  }
  throw new Error(`Error: ${currency} cannot be formatted...`);
};
