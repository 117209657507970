import { Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

const Field = ({
  Icon,
  value,
  isLink,
  styles,
}: {
  Icon?: React.ReactElement;
  value?: string;
  isLink: boolean;
  styles?: SxProps<Theme>;
}) => {
  return (
    <Typography
      variant={isLink ? "link" : "body2"}
      sx={{
        display: "flex",
        alignItems: "center",
        margin: "auto",
        gap: 0.5,
        color: "black",
        ...styles,
      }}
      noWrap
    >
      {Icon && Icon}
      {value && value}
    </Typography>
  );
};

export default Field;
