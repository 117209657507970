import "./styles/main.css";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "@aws-amplify/core";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { I18nContextProvider } from "react-admin";
import { i18nProvider } from "./providers/i18nProvider/i18nProvider";
import { Auth } from "aws-amplify";
import { datadogRum } from "@datadog/browser-rum";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "TrustAPI",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_REGION,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: process.env.REACT_APP_DATADOG_SERVICE_NAME, //'trust-portal-dev',
  env: process.env.ENVIRONMENT, //'maxencea',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.REACT_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <AppProvider>
      <I18nContextProvider value={i18nProvider}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nContextProvider>
    </AppProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
