import { Box, Typography, TypographyProps, Tooltip } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { memo, FC, ElementType } from "react";
import PropTypes from "prop-types";
import ErrorLabel from "../Label/ErrorLabel";
import CaptionLabel from "../Label/CaptionLabel";
import { fieldPropTypes } from "../../types/default";

const NameField: FC<NameFieldProps> = memo((props) => {
  const { source, subtitle = true, tooltipTitle, renderTooltip } = props;
  const record = useRecordContext(props);
  const hasOwner = "owner" in record && record.owner;
  const isActive = record?.is_active;
  const hasActiveOwner = hasOwner && isActive === true;

  const renderSubtitle = (active: boolean) => {
    return active ? (
      <CaptionLabel value={record.owner} />
    ) : (
      <ErrorLabel value={record.owner} />
    );
  };

  const content = (
    <Box display="flex" flexDirection="column">
      <Typography component="span" variant="body2" noWrap>
        {source && record[source]}
      </Typography>
      {subtitle && renderSubtitle(hasActiveOwner)}
    </Box>
  );

  if (!tooltipTitle && !renderTooltip) return content;

  return (
    <Tooltip
      {...(renderTooltip
        ? { classes: { tooltip: "kpiPopover" }, arrow: false }
        : { arrow: true })}
      title={
        tooltipTitle ? tooltipTitle : renderTooltip?.(record, hasActiveOwner)
      }
    >
      {content}
    </Tooltip>
  );
});

NameField.displayName = "TextField";

NameField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
  /**
   * Hide / Show subtitle text
   * Default value: true
   */
  subtitle: PropTypes.bool,
};

export interface NameFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
  subtitle?: boolean;
  // activate a tooltip by indicating a tooltip content
  tooltipTitle?: React.ReactNode;
  // callback fnct to display a tooltip with record informations
  renderTooltip?: (record: any, active: boolean) => React.ReactNode;
}

export default NameField;
