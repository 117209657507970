import { defaultTheme } from "react-admin";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    gradient: {
      main: string;
    };
  }

  interface Palette {
    gradient: {
      main: string;
    };
  }

  interface Typography {
    fontFamily: {
      link: React.CSSProperties;
      tableHeader: React.CSSProperties;
    };
  }
  interface TypographyOptions {
    button?: {
      textTransform?: string;
    };
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link: true;
    tableHeader: true;
  }
}
export const resmedTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#006DB7",
    },
    secondary: {
      main: "#f50057",
    },
    gradient: {
      main: "linear-gradient(311deg, #4156A6 6.73%, #2062AF 58.19%, #006DB7 99.59%)",
    },
    warning: {
      main: "#FFC82A",
    },
    success: {
      main: "#64AD4D",
    },
    error: {
      main: "#EB5757",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Helvetica Neue", "sans-serif"].join(","),
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
    },
    caption: {
      fontSize: 13,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize" as "capitalize",
    },
    subtitle1: {
      fontSize: 11,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 600,
    },
    link: {
      fontSize: 14,
      color: "grey",
      "&:hover": {
        cursor: "pointer",
        color: "#006DB7",
      },
    },
    tableHeader: {
      maxWidth: "10vw",
      textOverflow: "ellipsis",
      color: "#424243",
      fontSize: 13,
      fontWeight: 600,
      alignItems: "center",
      textTransform: "capitalize",
    },
  },
  sidebar: {
    width: defaultTheme?.sidebar?.width,
    closedWidth: 68,
  },
  components: {
    RaLayout: {
      styleOverrides: {
        root: {
          zIndex: 0,
          "& .RaLayout-content": {
            padding: 25,
            overflow: "hidden",
            display: "block",
            width: "75vw",
          },
          "& .RaLayout-appFrame": {
            marginTop: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#424243",
          color: "white",
          fontSize: 12,
          fontWeight: 600,
          padding: 10,
          lineHeight: 1.5,
          "&.kpiPopover": {
            padding: "2em",
            color: "#424243",
            backgroundColor: "white",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.5,
            boxShadow: "2px 2px 10px 4px rgba(0,0,0,0.04)",
            minWidth: "max-content",
          },
        },
        arrow: {
          color: "#424243",
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root.MuiTableRow-hover:hover": {
            backgroundColor: "#EFF9FF",
          },
          "& .RaDatagrid-headerCell": {
            color: "#424243",
            fontSize: 13,
            fontWeight: 600,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .RaDatagrid-rowOdd": {
            backgroundColor: "#F6F6F6",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
        },
        "& .RaDatagrid-headerCell": {
          color: "#424243",
          fontSize: 13,
          fontWeight: 600,
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          "& .RaSidebar-fixed": {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FAFAFA",
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: "#424243",
          padding: "10px 10px 10px 13px",
          "& .RaMenuItemLink-icon": {
            display: "none",
          },
          "&.RaMenuItemLink-active": {
            fontWeight: 600,
            backgroundColor: "#FAFAFA",
            color: "#006DB7",
            padding: 10,
            borderLeft: "3px solid #006DB7",
          },
        },
      },
    },
    RaSelectColumnsButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-sizeSmall": {
            lineHeight: 2.1,
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          minHeight: "fit-content",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          padding: 0,
          "& .RaFilterForm-filterFormInput .MuiFormControl-root": {
            marginTop: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: "#F6F6F6",
          textWrap: "wrap",
        },
        label: {
          textOverflow: "inherit",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#787878",
          borderRadius: 1,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: 13,
          fontWeight: 600,
        },
      },
    },
  },
};
