import { fieldPropTypes } from "../../types/default";
import { Box, Typography, TypographyProps, Tooltip } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { memo, FC, useEffect, useState, ComponentType } from "react";
import SuccessField from "./kpi/SuccessField";
import WarnField from "./kpi/WarnField";
import ErrorField from "./kpi/ErrorField";
import NAField from "./kpi/NAField";
import Field from "./Field";

export const tooltipStyling = {
  tooltip: {
    sx: {
      padding: "2em",
      color: "#424243",
      backgroundColor: "white",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
      boxShadow: "2px 2px 10px 4px rgba(0,0,0,0.04)",
      minWidth: "max-content",
    },
  },
};

const PercentageField: FC<PercentageFieldProps> = memo((props) => {
  const {
    source,
    tooltipContent: Content,
    isLink,
    maxError = 50,
    minWarn = 51,
    maxWarn = 99,
    minSuccess = 100,
  } = props;
  const record = useRecordContext(props);
  const [value, setValue] = useState<number | null>(null);
  let valueComponent: React.ReactNode = null;
  if (record[source]?.assigned_policies && value === null) {
    valueComponent = (
      <Tooltip title="No Resource">
        <NAField />
      </Tooltip>
    );
  } else if (record[source]?.assigned_policies === 0) {
    valueComponent = (
      <Tooltip title="No Policies">
        <Field isLink={isLink} value={"-"} />
      </Tooltip>
    );
  } else if (value !== null && value <= maxError) {
    valueComponent = <ErrorField isLink={isLink} value={`${value}%`} />;
  } else if (value !== null && value >= minWarn && value <= maxWarn - 1) {
    valueComponent = <WarnField isLink={isLink} value={`${value}%`} />;
  } else if (value !== null && value === minSuccess) {
    valueComponent = <SuccessField isLink={isLink} value={`${value}%`} />;
  } else {
    valueComponent = (
      <Tooltip title="Data not available">
        <NAField />
      </Tooltip>
    );
  }

  const displayable: boolean =
    record[source] && record[source].percentage !== null;

  useEffect(() => {
    if (record && record[source]) {
      setValue(record[source].percentage);
    } else {
      setValue(null);
    }
  }, [record, source]);

  return (
    <>
      {displayable ? (
        <Tooltip
          classes={{ tooltip: "kpiPopover" }}
          title={<Content {...props} record={record} />}
        >
          <Box sx={{ padding: 1 }}>{valueComponent}</Box>
        </Tooltip>
      ) : (
        <Box sx={{ padding: 1 }}>{valueComponent}</Box>
      )}
    </>
  );
});

PercentageField.displayName = "TextField";

PercentageField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface PercentageFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  source: string;
  tooltipContent: ComponentType<any>;
  isLink: boolean;
  maxError: number;
  minWarn: number;
  maxWarn: number;
  minSuccess: number;
}

export default PercentageField;
