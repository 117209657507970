import { RowClickFunction } from "react-admin";
import { Stack } from "@mui/material";
import ListLayout, { ListLayoutProps } from "../../Layouts/ListLayout";
import { ReactNode } from "react";

export const AccountList = (props: AccountListProps) => {
  const {
    priorisedColumns,
    preferenceKey,
    storeKey = "accountList",
    resource,
    rowClick = "show",
    fixedColumns,
    actions,
    filterDefaultValues,
    dynamicColumn,
    header,
    sort,
    hasInfo,
  } = props;

  return (
    <Stack>
      {header}
      <ListLayout
        priorisedColumns={priorisedColumns && priorisedColumns}
        preferenceKey={preferenceKey}
        storeKey={storeKey}
        resource={resource}
        rowClick={rowClick}
        actions={actions}
        fixedColumns={fixedColumns}
        filterDefaultValues={filterDefaultValues}
        dynamicColumn={dynamicColumn}
        sort={sort}
        hasInfo={hasInfo}
      />
    </Stack>
  );
};

interface AccountListProps extends ListLayoutProps {
  hasInfo?: boolean;
  resource: string;
  rowClick?: RowClickFunction | string;
  header: ReactNode;
  sort?: { field: string; order: "ASC" | "DESC" };
}

export default AccountList;
