import { Typography, Grid, Box } from "@mui/material";

const generateKey = (prefix: string, index: number) => `${prefix}-${index}`;

const SectionHeaders = ({ sectionHeaders }: { sectionHeaders: string[] }) => (
  <Grid xs={3} item direction="column" sx={{ paddingRight: "2%" }}>
    <Box height="2em"></Box>
    {sectionHeaders.map((header, index) => (
      <Typography
        key={generateKey("header", index)}
        sx={{ textAlign: "right" }}
        variant="body2"
        fontWeight={600}
      >
        {header}
      </Typography>
    ))}
  </Grid>
);

export default SectionHeaders;
