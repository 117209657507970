import { useContext, Children } from "react";
import {
  useTranslate,
  useListContext,
  useBasename,
  Link,
  DatagridHeaderCell,
  useResourceContext,
  SortPayload,
  useGetList,
} from "react-admin";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Button,
  Box,
  Chip,
  Stack,
} from "@mui/material";
import { AppContext } from "../../context/AppContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { isString } from "../../types/type-guards/type-guards";
import { formatCurrency } from "../../utils/currency-utils";

const getSortInfo = (sort: SortPayload, source: any) => {
  const showArrow = sort.field === source && sort.field === source;
  return { showArrow };
};

const handleSort = (
  sort: SortPayload,
  setSort: (sort: SortPayload) => void,
  field: string,
) => {
  const newOrder =
    sort.field === field && sort.order === "ASC" ? "DESC" : "ASC";
  setSort({ field, order: newOrder });
};

const TotalValueChip = ({ valueType, value }: TotalValueChipProps) => {
  const translate = useTranslate();
  return (
    <Chip
      sx={{ width: "fit-content", justifyContent: "flex-start" }}
      label={
        <span>
          <Typography
            sx={{ fontSize: 12, fontWeight: 600, textAlign: "left" }}
            component="span"
          >
            {translate(`commons.columnTotal.${valueType}`)}
          </Typography>{" "}
          {value}
        </span>
      }
    />
  );
};

const NameHeader = ({ child }: any) => {
  const source = child.props.source;
  const { setSort, sort } = useListContext();
  const { showArrow } = getSortInfo(sort, source);
  const resource = useResourceContext();

  return (
    <Button
      onClick={() => handleSort(sort, setSort, source)}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      sx={{
        textAlign: "left",
        justifyContent: "flex-start",
        color: "#424243",
        "&:hover": {
          backgroundColor: "white",
        },
      }}
      startIcon={
        showArrow && sort.order === "ASC" ? (
          <ArrowUpwardIcon />
        ) : (
          showArrow && <ArrowDownwardIcon />
        )
      }
    >
      <DatagridHeaderCell
        sort={{ field: "name", order: "ASC" }}
        resource={resource}
        field={child}
        sx={{
          border: "none",
          color: "#424243",
          fontSize: 13,
          fontWeight: 600,
          textTransform: "capitalize",
          verticalAlign: "top",
        }}
      />
    </Button>
  );
};

const BaseHeader = ({
  label,
  tooltip,
  source,
}: {
  label: string;
  tooltip?: boolean;
  source: string;
}) => {
  const { setSort, sort } = useListContext();
  const translate = useTranslate();
  const { showArrow } = getSortInfo(sort, source);

  const headerContent = (
    <Typography noWrap variant="tableHeader">
      {translate(label)}
    </Typography>
  );

  return (
    <Button
      onClick={() => handleSort(sort, setSort, source)}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      startIcon={
        showArrow &&
        (sort.order === "ASC" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)
      }
      sx={{
        color: "#424243",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "white",
          cursor: "pointer",
        },
      }}
    >
      {tooltip ? (
        <Tooltip title={translate(label)}>{headerContent}</Tooltip>
      ) : (
        headerContent
      )}
    </Button>
  );
};

const FullTitleHeader = ({
  child,
  displayTotal,
  total,
  source,
}: {
  child: React.ReactElement;
  source: string;
  displayTotal?: boolean;
  total?: { data: StandardTotal[] | undefined; isLoading: boolean };
}) => {
  const label: string = isString(child.props.label)
    ? child.props.label
    : "Default Label";

  const cost = total?.data?.filter((item) => item.standard_code === source)?.[0]
    ?.cost;
  const cost_type = total?.data?.filter(
    (item) => item.standard_code === source,
  )?.[0]?.cost_type;

  return (
    <TableCell key={label} sx={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
      <Stack direction="column" alignItems="flex-start">
        <BaseHeader label={label} tooltip source={child.props.source} />
        {displayTotal && cost && !total?.isLoading && (
          <TotalValueChip
            value={formatCurrency(cost)}
            valueType={cost_type || ""}
          />
        )}
      </Stack>
    </TableCell>
  );
};

const InfoHeader = ({
  child,
  displayTotal,
  total,
}: {
  child: any;
  displayTotal?: boolean;
  total?: { data: StandardTotal[] | undefined; isLoading: boolean };
}) => {
  const basename = useBasename();
  const translate = useTranslate();
  const appContext = useContext(AppContext);
  const label = isString(child.props.label)
    ? child.props.label
    : "Default Label";
  const source = child.props.source;

  const cost = total?.data?.filter((item) => item.standard_code === source)?.[0]
    ?.cost;
  const cost_type = total?.data?.filter(
    (item) => item.standard_code === source,
  )?.[0]?.cost_type;

  return (
    <TableCell
      key={child.props.label}
      sx={{ whiteSpace: "nowrap", verticalAlign: "top" }}
    >
      <Stack direction="column" alignContent={"flex-start"}>
        <Stack direction="row" alignItems={"center"}>
          <BaseHeader label={label} source={source} />
          <Link to={`${basename}/faq#${source}`} display={"flex"}>
            <Tooltip
              title={
                <Box>
                  <Typography fontWeight={600} noWrap fontSize={12}>
                    {translate(label)}
                  </Typography>
                  <Typography fontSize={12}>
                    {translate(`${appContext.entityType}.${source}.tooltip`, {
                      _: "",
                    })}
                  </Typography>
                </Box>
              }
              placement="bottom-start"
              arrow
            >
              <InfoOutlinedIcon
                sx={{
                  alignSelf: "baseline",
                  color: "#424243",
                  fontSize: 14,
                  fontWeight: 600,
                  alignItems: "end",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Tooltip>
          </Link>
        </Stack>
        {displayTotal && !total?.isLoading && cost !== undefined && (
          <TotalValueChip
            value={formatCurrency(cost)}
            valueType={cost_type || ""}
          />
        )}
      </Stack>
    </TableCell>
  );
};

const DatagridHeader = ({ children, hasInfo }: DatagridHeaderProps) => {
  const { filterValues } = useListContext();
  const { data, isLoading } = useGetList("finops/accounts/total", {
    filter: filterValues,
  });

  const renderHeaderCell = (child: React.ReactElement) => {
    if (!child) {
      return null;
    }

    const { source } = child.props;
    const isName = source === "name";

    return isName ? (
      <NameHeader child={child} />
    ) : hasInfo ? (
      <InfoHeader
        child={child}
        displayTotal
        total={{ data: data, isLoading }}
      />
    ) : (
      <FullTitleHeader
        child={child}
        displayTotal
        total={{ data, isLoading }}
        source={source}
      />
    );
  };

  return (
    <TableHead
      sx={{ display: "table-header-group" }}
      className="RaDatagrid-thead"
    >
      <TableRow sx={{ display: "table-row" }} className="RaDatagrid-headerRow">
        {Children.map(children, renderHeaderCell)}
      </TableRow>
    </TableHead>
  );
};

export default DatagridHeader;

export type FaqDataType = {
  rodDashboard: {
    headers: {
      volumeState: {
        title: string;
        description: string;
      };
      gpType: {
        title: string;
        description: string;
      };
      SQLServer: {
        title: string;
        description: string;
      };
    };
  };
  codDashboard: {};
};

interface DatagridHeaderProps {
  children?: any;
  hasInfo?: boolean;
}
interface TotalValueChipProps {
  value: string;
  valueType: string;
}

interface StandardTotal {
  id: string;
  cost: number;
  standard_code: string;
  cost_type: string;
}
