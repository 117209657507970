import { useRecordContext } from "ra-core";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";
import { TextFieldProps as BaseTextFieldProps } from "react-admin";
import { Link, useLocation } from "react-router-dom";

export interface LaunchLinkFieldProps extends BaseTextFieldProps {
  format?: "external" | "filteredOnID";
}

export const LaunchLinkField = ({ source, format }: LaunchLinkFieldProps) => {
  const record = useRecordContext();
  const value = source !== undefined ? record[source] : undefined;
  const location = useLocation();

  if (format === "filteredOnID") {
    const url = {
      // use replace to remove last "/" in URL if any. from https://localhost/rod/  to https://localhost/rod
      pathname: `${location.pathname.replace(/\/$/, "")}/accounts`,
      search: `filter=${JSON.stringify({ search: value })}`,
    };
    return (
      <IconButton
        color="primary"
        component={Link}
        to={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LaunchIcon />
      </IconButton>
    );
  }
  return (
    <IconButton
      color="primary"
      href={value}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LaunchIcon />
    </IconButton>
  );
};
