import {
  CustomRoutes,
  Resource,
  TextField,
  useBasename,
  usePermissions,
} from "react-admin";
import FinopsAccountList from "../../components/Lists/Accounts/ROD/FinopsAccountList";
import { Navigate, Route } from "react-router-dom";
import FaqPage from "../../components/Faq/FaqPage";
import { ROD_DASHBOARD } from "../../definition/definition";
import { ResourceProps } from "../../types/default";
import FinopsOwnerList from "../../components/Lists/Accounts/ROD/FinopsOwnerList";
import AccountShow from "../../components/Lists/Accounts/AccountShow";
import { RDSDetailsPanel } from "../../components/Lists/Accounts/ROD/FinopsAccountShow";
import Breakdown from "../../components/Breakdown/Breakdown";
import BooleanField from "../../components/Fields/BooleanField";
import FinopsHistoryList from "../../components/Lists/Accounts/ROD/FinopsHistoryList";

export const Resources = (props: ResourceProps) => {
  const { translate } = props;
  const basename = useBasename();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("adminDashboard");

  return [
    <Resource
      name="accounts"
      options={{ label: translate("nav.rodDashboard.account") }}
      list={FinopsAccountList}
    >
      <Route
        path=":id/rds"
        element={
          <AccountShow
            entityType={"accounts.finops.SQLServer"}
            descriptionKey={"accounts.finops.SQLServer.infoText"}
            baseResource={"finops/accounts"}
            detailRessource="rds"
            panel={<RDSDetailsPanel />}
            columns={[
              <TextField
                label="DB instance"
                source="instance_name"
                sortable={false}
              />,
              <BooleanField
                label=""
                source="optimized"
                tooltipContent={Breakdown}
                sortable={false}
              />,
            ]}
          />
        }
      />
      <Route path=":id" element={<Navigate to={`/${basename}/accounts`} />} />
    </Resource>,
    <Resource
      key="owners"
      name="owners"
      options={{ label: translate("nav.rodDashboard.owner") }}
      list={FinopsOwnerList}
    />,
    <Resource
      key="history"
      name="history"
      options={{
        label: translate("nav.rodDashboard.history"),
        fetchTotal: false,
        adminRestriction: true,
      }}
      list={FinopsHistoryList}
    />,
    <CustomRoutes>
      <Route path="/faq" element={<FaqPage dashboard={ROD_DASHBOARD} />} />
    </CustomRoutes>,
    /* display History section based on user permission */
  ].filter(
    (r) =>
      r.props?.options?.adminRestriction === isAdmin ||
      r.props?.options?.adminRestriction === undefined,
  );
};
