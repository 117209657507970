import { AuthProvider, HttpError } from "react-admin";
import { urlSafeDecode, urlSafeEncode } from "@aws-amplify/core";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { API } from "aws-amplify";
import { useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

/**
 * An authProvider which handles authentication with AWS Cognito.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import { CognitoAuthProvider } from 'ra-auth-cognito';
 * import { CognitoUserPool } from 'amazon-cognito-identity-js';
 * import dataProvider from './dataProvider';
 * import posts from './posts';
 *
 * const userPool = new CognitoUserPool({
 *     UserPoolId: 'COGNITO_USERPOOL_ID',
 *     ClientId: 'COGNITO_APP_CLIENT_ID',
 * });
 *
 * const authProvider = CognitoAuthProvider(userPool);
 *
 *  const App = () => {
 *   return (
 *        <Admin
 *            authProvider={authProvider}
 *            dataProvider={dataProvider}
 *            title="Example Admin"
 *         >
 *             <Resource name="posts" {...posts} />
 *       </Admin>
 *    );
 * };
 * export default App;
 *
 * ```
 *
 * @param userPool a CognitoUserPool instance
 * @returns an authProvider ready to be used by React-Admin.
 */
export type CognitoAuthProviderOptionsPool = CognitoUserPool;

export type CognitoAuthProviderOptionsIds = {
  userPoolId: string;
  clientId: string;
  hostedUIUrl?: string;
  identityProvider?: string;
  mode: "oauth" | "username";
  redirect_uri?: string;
  scope?: string[];
};

export type CognitoAuthProviderOptions =
  | CognitoAuthProviderOptionsPool
  | CognitoAuthProviderOptionsIds;

/**
 * Set Datadog RUM user to have user id available in dashboard
 * @param user
 */
const setRUMUser = (user: CognitoUser) => {
  user.getUserAttributes(
    (
      err: Error | undefined,
      attributes: CognitoUserAttribute[] | undefined,
    ) => {
      if (err) {
        console.error(err);
        return;
      }

      const id = user.getUsername();
      const email = attributes?.find((attribute) => attribute.Name === "email")
        ?.Value;

      datadogRum.setUser({
        id,
        email,
      });
    },
  );
};

export const CognitoAuthProvider = (
  options: CognitoAuthProviderOptions,
): AuthProvider => {
  const mode = options instanceof CognitoUserPool ? "username" : options.mode;
  const userPool =
    options instanceof CognitoUserPool
      ? (options as CognitoUserPool)
      : new CognitoUserPool({
          UserPoolId: options.userPoolId,
          ClientId: options.clientId,
        });
  const [permissions, setPermissions] = useState({
    permission: null,
    ttl: 0,
    date: "",
  });

  const getUserPermissions = async (
    user_groups: any,
    cognito_username: string,
  ) => {
    const userPermissions = await API.get(
      "TrustAPI",
      "users/" + cognito_username + "/permissions",
      {
        queryStringParameters: {
          groups: JSON.stringify(user_groups),
        },
      },
    );
    setPermissions(userPermissions);
    localStorage.setItem("permissions", JSON.stringify(userPermissions));
    return userPermissions ? userPermissions["permissions"] : null;
  };

  return {
    async login() {},
    // called when the user clicks on the logout button
    async logout() {
      return new Promise((resolve) => {
        const user = userPool.getCurrentUser();
        if (!user) {
          return resolve();
        }
        user.signOut(() => {
          resolve();
        });
      });
    },
    // called when the API returns an error
    async checkError({ status }) {
      if (status === 401 || status === 403) {
        throw new Error("Unauthorized");
      }
    },
    // called when the user navigates to a new location, to check for authentication
    async checkAuth() {
      return new Promise<void>((resolve, reject) => {
        const redirectToOAuthIfNeeded = (error?: Error) => {
          if (mode === "oauth") {
            const oauthOptions = options as CognitoAuthProviderOptionsIds;
            const redirect_uri =
              oauthOptions.redirect_uri ??
              `${window.location.origin}/auth-callback`;
            const scope = [
              "openid",
              "email",
              "profile",
              "aws.cognito.signin.user.admin",
            ];
            let queryParams = {
              redirect_uri: redirect_uri,
              client_id: oauthOptions.clientId,
              scope: scope.join("+"),
            };
            if (oauthOptions.identityProvider) {
              queryParams = {
                ...queryParams,
                ...{
                  identity_provider: oauthOptions.identityProvider,
                  state: urlSafeEncode(window.location.pathname),
                  response_type: "token",
                },
              };
            }
            const queryString = Object.entries(queryParams)
              .map(([k, v]) =>
                k !== "scope"
                  ? `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
                  : `${k}=${v}`,
              )
              .join("&");
            const url = `https://${oauthOptions.hostedUIUrl}/oauth2/authorize?${queryString}`;
            window.location.href = url;
          } else {
            return reject(error);
          }
        };
        let user = userPool.getCurrentUser();

        if (!user) {
          return redirectToOAuthIfNeeded(new HttpError("No user", 401));
        }

        user.getSession((err: Error | null, session: CognitoUserSession) => {
          if (err) {
            return redirectToOAuthIfNeeded(new HttpError("No user", 401));
          }

          if (!session.isValid()) {
            return redirectToOAuthIfNeeded(new HttpError("No user", 401));
          }

          if (user) {
            setRUMUser(user);
          }
          user?.getUserAttributes((err) => {
            if (err) {
              return reject(err);
            }
            resolve();
          });
        });
      });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    async getPermissions() {
      return new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (!user) {
          return reject();
        }

        user.getSession(
          async (err: Error | null, session: CognitoUserSession) => {
            if (err) {
              return reject(err);
            }
            if (!session.isValid()) {
              return reject();
            }
            const token = session.getIdToken().decodePayload();
            const user_groups = token["cognito:groups"] ?? [];
            const username = token["cognito:username"] ?? "";
            if (permissions == null || !permissions["permission"]) {
              const userPermissions = getUserPermissions(user_groups, username);
              return resolve(userPermissions);
            } else {
              const date = new Date(permissions["date"]);
              if (new Date(Date.now() - permissions["ttl"]) > date) {
                const userPermissions = getUserPermissions(
                  user_groups,
                  username,
                );
                return resolve(userPermissions);
              }
              return resolve(permissions);
            }
          },
        );
      });
    },
    async getIdentity() {
      return new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (!user) {
          return reject();
        }
        user.getSession((err: Error | null, session: CognitoUserSession) => {
          if (err) {
            return reject(err);
          }
          if (!session.isValid()) {
            return reject();
          }
          user.getUserAttributes(
            (
              err: Error | undefined,
              attributes: CognitoUserAttribute[] | undefined,
            ) => {
              if (err) {
                return reject(err);
              }
              resolve({
                id: user.getUsername(),
                fullName: attributes?.find(
                  (attribute) => attribute.Name === "email",
                )?.Value,
                firstName:
                  attributes?.find(
                    (attribute) => attribute.Name === "given_name",
                  )?.Value || "",
                avatar: attributes?.find(
                  (attribute) => attribute.Name === "picture",
                )?.Value,
              });
            },
          );
        });
      });
    },
    async handleCallback() {
      const urlParams = new URLSearchParams(window.location.hash.substr(1));
      const state = urlParams.get("state");
      const error = urlParams.get("error");
      const errorDescription = urlParams.get("error_description");
      const idToken = urlParams.get("id_token");
      const accessToken = urlParams.get("access_token");
      if (error) {
        throw new Error(`${errorDescription}`);
      }

      if (idToken == null || accessToken == null) {
        throw new Error("Failed to handle login callback.");
      }

      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: "",
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
      });
      const user = new CognitoUser({
        Username: session.getIdToken().decodePayload()["cognito:username"],
        Pool: userPool,
        Storage: window.localStorage,
      });
      user.setSignInUserSession(session);

      if (state) {
        return urlSafeDecode(state);
      }
      return "/";
    },
  };
};
