import { Resource } from "react-admin";
import AdminUsers from "../../components/Admin/AdminUsers";
import AdminIngestions from "../../components/Admin/AdminIngestions";
import AdminFAQ from "../../components/Admin/AdminFAQ";

export const Resources = () => {
  return [
    <Resource
      name="users"
      options={{ label: "User Management", fetchTotal: false }}
      list={AdminUsers}
    />,
    <Resource
      name="ingestions"
      options={{ label: "Ingestion Monitoring", fetchTotal: false }}
      list={AdminIngestions}
    />,
    <Resource
      name="faq"
      options={{ label: "FAQ Page Edition", fetchTotal: false }}
      list={AdminFAQ}
    />,
  ];
};
