import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Field from "../Field";

const NoDataWarningField = ({
  value,
  isLink,
}: {
  value: string;
  isLink: boolean;
}) => {
  return (
    <Field
      isLink={isLink}
      value={value}
      Icon={<WarningAmberIcon color="warning" />}
    />
  );
};

export default NoDataWarningField;
