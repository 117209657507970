import { useState, useEffect } from "react";
import {
  Stack,
  Box,
  Typography,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  Alert,
  AlertTitle,
  Button,
  Card,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RiskAssessmentContent from "./RiskAssessmentContent";

interface RiskScore {
  riskScore: string;
  riskSeverity: any;
}

const useFormCalculationHook = (
  initialValue: number,
  selectValues: string[],
) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const sum = selectValues
      .map((value) => parseInt(value, 10))
      .filter((parsedValue) => !isNaN(parsedValue))
      .reduce((acc, curr) => acc + curr, 0);

    setValue(sum);
  }, [selectValues]);

  const getRiskScoreString = (): RiskScore => {
    let riskScore = "";
    let riskSeverity = "";

    if (value < 6 || value === 0) {
      riskScore = "Low";
      riskSeverity = "success";
    } else if (value < 9) {
      riskScore = "Medium";
      riskSeverity = "warning";
    } else if (value < 12) {
      riskScore = "High";
      riskSeverity = "error";
    } else {
      riskScore = "Critical";
      riskSeverity = "error";
    }

    return { riskScore, riskSeverity };
  };

  const resetForm = () => {
    setValue(initialValue);
  };

  return { value, getRiskScoreString, resetForm };
};

function RiskAssessmentForm() {
  const [selectValues, setSelectValues] = useState<string[]>(
    Array.from({ length: RiskAssessmentContent.length }, () => ""),
  );
  const {
    value: calcValue,
    getRiskScoreString,
    resetForm,
  } = useFormCalculationHook(0, selectValues);
  const { riskScore, riskSeverity } = getRiskScoreString();

  function handleValueChange(index: number, value: string) {
    setSelectValues((prevValues: string[]) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  }

  const handleResetForm = () => {
    resetForm();
    setSelectValues(Array(RiskAssessmentContent.length).fill(""));
  };

  const FormComponents = RiskAssessmentContent.map((formItem, index) => (
    <Box
      key={formItem.question}
      className="field-group"
      sx={formItem.description ? { marginTop: 2 } : { marginTop: 1 }}
    >
      {formItem.description && (
        <FormLabel htmlFor={formItem.id}>
          <Typography
            variant="caption"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            {formItem.description}
          </Typography>
        </FormLabel>
      )}
      <Typography
        variant="h5"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          paddingTop: 1,
        }}
      >
        <Tooltip title={formItem.tooltip}>
          <InfoOutlinedIcon
            sx={{
              color: "lightGrey",
              fontWeight: 600,
              fontSize: "14px",
              alignSelf: "center",
              marginRight: "5px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Tooltip>
        {formItem.question}
      </Typography>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          labelId={formItem.id}
          id={formItem.id}
          value={selectValues[index]}
          onChange={(event) =>
            handleValueChange(index, event.target.value as string)
          }
        >
          {formItem.values.map((valueItem) => (
            <MenuItem dense key={valueItem.value} value={valueItem.value}>
              {valueItem.valLabel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ));

  return (
    <Card
      sx={{ minWidth: "40%", maxWidth: "50%", padding: "25px", margin: "auto" }}
    >
      <Stack>
        <Typography
          variant="h2"
          sx={{ display: "flex", flexDirection: "row", alignContent: "center" }}
        >
          Risk Assessment Form
        </Typography>
        {FormComponents}
        <Box sx={{ marginTop: 2 }}>
          <Alert severity={riskSeverity} id="risk_score">
            <AlertTitle>{riskScore}</AlertTitle>
            Your change is scored as <strong>{calcValue}</strong> and{" "}
            <strong>{riskScore}</strong> risk
          </Alert>
          <Button
            sx={{ marginTop: 2 }}
            variant="outlined"
            startIcon={<RestartAltIcon />}
            onClick={handleResetForm}
          >
            Reset
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

export default RiskAssessmentForm;
