import { useEffect, useState } from "react";
import { useGetList, useListContext } from "react-admin";
import RadioGroupInput, {
  RadioGroupInputProps,
} from "./common/RadioGroupInput";
import { SliderInput, SliderInputProps } from "./common/SliderInput";
import { Grid } from "@mui/material";

type SliderRadioGroupInputProps = {
  slider: Pick<SliderInputProps, "ariaLabel" | "title">;
};
export type OptimizedRadioGroupInputProps = RadioGroupInputProps &
  SliderRadioGroupInputProps;

const DEFAULT_OPTION = { id: "all", name: "All accounts" };
const OptimizedRadioGroupInput = (props: OptimizedRadioGroupInputProps) => {
  const {
    source,
    minMaxResource = "",
    slider: { ariaLabel, title },
  } = props;
  const { filterValues } = useListContext();
  const [value, setValue] = useState(DEFAULT_OPTION.id);
  const choices = [
    DEFAULT_OPTION,
    { id: "optimized", name: "Optimized" },
    { id: "non-optimized", name: "Non-Optimized" },
  ];

  const { data, isLoading } = useGetList(
    minMaxResource,
    {},
    {
      /* trigger query only when resource is defined */
      enabled: minMaxResource !== "",
    },
  );

  if (!source) {
    throw new Error(
      `Props source is mandatory on <OptimizedRadioGroupInput />`,
    );
  }

  useEffect(() => {
    setValue(filterValues?.[source] || DEFAULT_OPTION.id);
  }, [filterValues]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroupInput
          options={{ value }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          label={""}
          choices={choices}
          sx={{ mt: 1 }}
          {...props}
        />
      </Grid>
      <Grid item>
        {!isLoading && (
          <SliderInput
            min={parseInt(data?.[0]?.min)}
            max={parseInt(data?.[0]?.max)}
            title={title}
            ariaLabel={ariaLabel}
            source={source}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default OptimizedRadioGroupInput;
