import { CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import AccountShow from "../../components/Lists/Accounts/COD/ComplianceAccountShow";
import ComplianceAccountList from "../../components/Lists/Accounts/COD/ComplianceAccountList";
import FaqPage from "../../components/Faq/FaqPage";
import RiskAssessmentForm from "../../components/RiskAssessment/RiskAssessmentForm";
import { COD_DASHBOARD } from "../../definition/definition";
import { ResourceProps } from "../../types/default";

export const Resources = (props: ResourceProps) => {
  const { translate } = props;
  return [
    <Resource
      key="accounts"
      name="accounts"
      options={{ label: translate("nav.codDashboard.account") }}
      list={ComplianceAccountList}
      show={AccountShow}
    />,
    <CustomRoutes>
      <Route path="/faq" element={<FaqPage dashboard={COD_DASHBOARD} />} />
      <Route path="/risk" element={<RiskAssessmentForm />} />
    </CustomRoutes>,
  ];
};
