import { useContext } from "react";
import { useTranslate } from "react-admin";
import { BreakdownProps } from "./Breakdown";
import BreakdownContent from "./BreakdownContent";
import { AppContext } from "../../context/AppContext";
import { isDefined } from "../../types/type-guards/type-guards";
import { formatCurrency } from "../../utils/currency-utils";

const SQLServerBreakdown = (props: BreakdownProps) => {
  const { record, source } = props;

  const translate = useTranslate();
  const appContext = useContext(AppContext);
  const warning = record[source]["warning"];

  const last_month_cost = isDefined(record[source]?.last_month_cost)
    ? formatCurrency(record[source].last_month_cost!)
    : "";
  const last_month_estimated_cost = isDefined(
    record[source]?.last_month_estimated_cost,
  )
    ? formatCurrency(record[source].last_month_estimated_cost!)
    : "";
  const last_month_total_savings = isDefined(
    record[source]?.last_month_total_savings,
  )
    ? formatCurrency(record[source].last_month_total_savings!)
    : "";

  return (
    <BreakdownContent
      clickable={true}
      messages={[
        ...(warning &&
        [
          record[source].last_month_total_savings,
          record[source].last_month_estimated_cost,
          record[source].last_month_cost,
        ].every((value) => value === null)
          ? [
              {
                key: translate(
                  `${appContext.entityType}.${source}.popover.noSavings`,
                ),
                styling: {
                  sx: {
                    fontWeight: "600",
                    marginBottom: "0.5em",
                    color: "red",
                  },
                },
              },
            ]
          : []),
        ...(warning &&
        ![
          record[source].last_month_total_savings,
          record[source].last_month_estimated_cost,
          record[source].last_month_cost,
        ].some((value) => value === null)
          ? [
              {
                key: translate(
                  `${appContext.entityType}.${source}.popover.partialData`,
                ),
                styling: {
                  sx: {
                    fontWeight: "600",
                    marginBottom: "0.5em",
                    color: "orange",
                  },
                },
              },
            ]
          : []),

        {
          key: translate(
            `${appContext.entityType}.${source}.popover.lastMonthRDSCost`,
          ),
          value: last_month_cost,
        },
        {
          key: translate(
            `${appContext.entityType}.${source}.popover.lastMonthRDSEstimated`,
          ),
          value: last_month_estimated_cost,
        },
        {
          key: translate(
            `${appContext.entityType}.${source}.popover.lastMonthRDSTotalSavings`,
          ),
          value: last_month_total_savings,
        },
      ].filter(isDefined)}
    />
  );
};

export default SQLServerBreakdown;
