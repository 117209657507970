import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode, useState } from "react";
import { FilterForm, useListContext, useTranslate } from "react-admin";
import { isValidElement } from "react";
import {
  getMaxFilterRange,
  getMinFilterRange,
} from "../../Datagrid/Actions/RangeFilterHelper";
import {
  format,
  translateChipValue,
} from "../../Datagrid/Actions/FilterChipRow";

const getAllFilterSources = (filters: string[]) =>
  filters.reduce(
    (acc: string[], filter: string) => [
      ...acc,
      filter,
      getMinFilterRange(filter),
      getMaxFilterRange(filter),
    ],
    [],
  );

const FilterCategoryHeader = (props: FilterCategoryHeaderProps) => {
  const { filterHeader, filterList } = props;
  const { filterValues } = useListContext();
  const translate = useTranslate();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const filterSources = Array.isArray(filterList)
    ? filterList
        .map((filter) =>
          isValidElement(filter) ? filter.props?.source : undefined,
        )
        .filter(Boolean)
    : [];

  const relevantKeys = Object.keys(filterValues).filter((key) =>
    getAllFilterSources(filterSources).includes(key),
  );

  const formattedFiltersList = relevantKeys
    .map((key: string) =>
      format(key, filterValues, translate, translateChipValue),
    )
    .filter(Boolean)
    .map(
      (filterItem: string) =>
        filterItem.charAt(0).toUpperCase() + filterItem.slice(1),
    );

  return (
    <div>
      <Accordion
        elevation={0}
        disableGutters
        square
        expanded={isAccordionExpanded}
        onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
        sx={{
          borderBottom: "1px solid lightGrey",
          padding: "0 10px 10px 10px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ marginTop: 1.5 }} />}
          sx={{ alignItems: "flex-start", padding: 0 }}
        >
          <Stack direction={"column"}>
            <Typography variant="body2">{filterHeader}</Typography>
            {!isAccordionExpanded && (
              <Typography fontWeight={600} fontSize={12} marginTop={0.5}>
                {formattedFiltersList.filter((item) => item !== "").join(", ")}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, margin: 0 }}>
          <FilterForm filters={filterList} style={{ display: "block" }} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterCategoryHeader;

export interface FilterCategoryHeaderProps {
  filterHeader: string;
  filterList?: ReactNode[];
}
