import { Tooltip, Box } from "@mui/material";
import { Translate, useTranslate } from "react-admin";
import Breakdown, { BreakdownProps } from "../../../Breakdown/Breakdown";
import ListToolbar from "../../../Datagrid/ListToolBar";
import TableField from "../../../Fields/TableField";
import NAField from "../../../Fields/kpi/NAField";
import NameField from "../../../Fields/NameField";
import PercentageField from "../../../Fields/PercentageField";
import AccountList from "../AccountList";
import ErrorField from "../../../Fields/kpi/ErrorField";
import SuccessField from "../../../Fields/kpi/SuccessField";
import BreakdownContent from "../../../Breakdown/BreakdownContent";
import PageHeader from "../../../Header/PageHeader";
import ProductInput, {
  MissingProductCheckboxInput,
} from "../../../Inputs/ProductInput";
import RegionInput from "../../../Inputs/RegionInput";
import { FilterCategoryProps } from "../../../SideBar/FilterSidebar/FilterSideBar";
import { NameTooltip } from "../../../Tooltip/NameTooltip";
import OwnerInput, {
  OwnerInactiveCheckboxInput,
} from "../../../Inputs/OwnerInput";
import {
  COST_CENTER,
  OUTDATED_OS_EC2,
  PRISMA_ALERTS_STANDARD,
  PUBLIC_S3,
  UNPATCHED_EC2_STANDARD,
  EXPOSED_ENDPOINT_STANDARD,
} from "../../../../definition/definition";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import DetailButton from "../../../Breakdown/DetailButton";
import ClickableField from "../../../Fields/ClickableField";
import OSRadioGroupInput from "../../../Inputs/OSRadioGroupInput";
import AlertField from "../../../Fields/dashboards/AlertField";

const SecurityAccountList = () => {
  const translate = useTranslate();
  const filterList: FilterCategoryProps[] = [
    {
      filterHeader: translate("commons.filterCategories.general"),
      filterList: [
        <RegionInput source="region" alwaysOn />,
        <ProductInput
          source="product"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
        <MissingProductCheckboxInput source="missing_product" alwaysOn />,
      ],
    },
    {
      filterHeader: translate("commons.filterCategories.owner"),
      filterList: [
        <OwnerInput
          source="owner"
          reference="assets/accounts/owners?distinct=true"
          alwaysOn
        />,
        <OwnerInactiveCheckboxInput source="is_active" alwaysOn />,
      ],
    },
    {
      filterHeader: translate("commons.filterCategories.outdated_os"),
      filterList: [<OSRadioGroupInput source="os" alwaysOn />],
    },
  ];
  useSetEntityType("accounts.security");
  const preferenceKey = "accountList.datagrid";

  return (
    <AccountList
      priorisedColumns={[]}
      resource="security/accounts"
      preferenceKey={preferenceKey}
      hasInfo={true}
      actions={
        <ListToolbar
          enableSearch={true}
          searchLabel="accounts.search"
          filterList={filterList}
          preferenceKey={preferenceKey}
        />
      }
      fixedColumns={[
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="name"
          sortBy="name"
          label={`accounts.displayName`}
          renderTooltip={(record, active) => (
            <NameTooltip record={record} active={active} />
          )}
        />,
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="product"
          label={`accounts.finops.product.displayName`}
          sortBy="product"
          subtitle={false}
        />,
      ]}
      header={
        <PageHeader
          resource="Accounts"
          descriptionKey="accounts.security.infoText"
          title="Global Accounts"
        />
      }
      dynamicColumn={(source) => hoverableColumns(source, translate)}
      rowClick=""
    />
  );
};

const hoverableColumns = (column: string, translate: Translate) => {
  const SecurityBreakdown = (props: BreakdownProps) => {
    return (
      <Breakdown
        {...props}
        source={column}
        detailButton={<DetailButton service="prismaId" standard="ec2" />}
      />
    );
  };

  const renderTableField = (
    column: string,
    fieldTypeProps: FieldTypeProps,
    clickURI?: string,
  ) => {
    const { record, tooltipContent: Content } = fieldTypeProps;
    const columnData = record[column];
    const percentage = columnData?.percentage;
    let valueComponent = null;

    if (columnData?.total === 0 || columnData === null) {
      valueComponent = <NAField />;
    } else if (columnData?.failed > 0) {
      valueComponent = <ErrorField isLink={false} value={`${percentage} %`} />;
    } else {
      valueComponent = (
        <SuccessField isLink={false} value={`${percentage} %`} />
      );
    }

    const commonField = <Box sx={{ padding: 1 }}>{valueComponent}</Box>;

    if (columnData === null || percentage === null) {
      return commonField;
    }

    if (percentage === 100 || !clickURI) {
      return (
        <Tooltip
          classes={{ tooltip: "kpiPopover" }}
          title={<Content {...fieldTypeProps} />}
        >
          {commonField}
        </Tooltip>
      );
    }

    return (
      <ClickableField
        label={`security.columns.${column}.title`}
        source={column}
        key={column}
        cellClick={(id) => `${encodeURIComponent(id)}/${clickURI}`}
      >
        <Tooltip
          classes={{ tooltip: "kpiPopover" }}
          title={percentage !== null ? <Content {...fieldTypeProps} /> : ""}
        >
          {commonField}
        </Tooltip>
      </ClickableField>
    );
  };

  if (column === UNPATCHED_EC2_STANDARD) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`security.columns.${column}.title`}
        key={column}
        fieldType={(props) => renderTableField(column, props)}
        tooltipContent={SecurityBreakdown}
      />
    );
  }

  if (column === OUTDATED_OS_EC2) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`security.columns.${column}.title`}
        key={column}
        fieldType={(props) => renderTableField(column, props, "os")}
        tooltipContent={SecurityBreakdown}
      />
    );
  }

  if (column === PUBLIC_S3) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`security.columns.${column}.title`}
        key={`${column}`}
        fieldType={(props) => renderTableField(column, props, "s3")}
        tooltipContent={({ record }) => (
          <BreakdownContent
            detailButton={
              <DetailButton standard="publicS3" service="prismaId" />
            }
            messages={[
              {
                key: translate(`accounts.security.${PUBLIC_S3}.popover.failed`),
                value: record[column]?.failed,
              },
              {
                key: translate(`accounts.security.${PUBLIC_S3}.popover.total`),
                value: record[column]?.total,
              },
            ]}
          />
        )}
      />
    );
  }

  if (column === PRISMA_ALERTS_STANDARD) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`security.columns.${column}.title`}
        key={`${column}`}
        fieldType={AlertField}
        tooltipContent={Breakdown}
      />
    );
  }

  if (column === EXPOSED_ENDPOINT_STANDARD) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`accounts.security.${column}.title`}
        key={`${column}`}
        fieldType={(props) => renderTableField(column, props, "endpoint")}
        tooltipContent={({ record }) => (
          <BreakdownContent
            messages={[
              {
                key: translate(
                  `accounts.security.${EXPOSED_ENDPOINT_STANDARD}.popover.failed`,
                ),
                value: record[column]?.failed,
              },
              {
                key: translate(
                  `accounts.security.${EXPOSED_ENDPOINT_STANDARD}.popover.total`,
                ),
                value: record[column]?.total,
              },
            ]}
          />
        )}
      />
    );
  }

  if (column === COST_CENTER) {
    return (
      <NameField
        sx={{ textAlign: "center", display: "inline-block", cursor: "default" }}
        source={column}
        label={`finops.columns.${column}.title`}
        sortBy={column}
        subtitle={false}
      />
    );
  }

  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block" }}
      source={column}
      label={`security.columns.${column}.title`}
      key={`${column}`}
      fieldType={PercentageField}
      tooltipContent={Breakdown}
    />
  );
};

export default SecurityAccountList;
interface FieldTypeProps {
  record: {
    [key: string]: any;
  };
  tooltipContent: React.ComponentType<any>;
}
