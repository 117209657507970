import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useTranslate } from "react-admin";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { handleFetchError } from "../../errors/ErrorHandling";
import DashboardCardCharts from "./DashboardCardCharts";

export function DashboardCard(props: CardProps) {
  const translate = useTranslate();
  const { resource } = props;
  const [kpi, setKpi] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await API.get(
          "TrustAPI",
          translate(`accounts.security.${resource}.path`),
          {},
        );
        setKpi(response.data);
      } catch (error) {
        const errorMessage = handleFetchError(error, translate);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchData();
  }, [resource, translate]);

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <Typography variant="h5">
        {translate(`accounts.security.${resource}.title`)}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : error ? (
        <Typography variant="caption" sx={{ color: "red" }}>
          {error}
        </Typography>
      ) : (
        <CardContent value={kpi} {...props} />
      )}
    </Stack>
  );
}

const CardContent = (props: CardContentProps & CardProps) => {
  const { value, onlyDisplay, chart } = props;
  return (
    <>
      {value !== null ? (
        <Stack direction="column">
          {onlyDisplay && <DashboardCardKPI {...props} />}
          {chart && <DashboardCardCharts {...props} />}
        </Stack>
      ) : (
        <Typography variant="h3">{value}</Typography>
      )}
    </>
  );
};

const DashboardCardKPI = (props: CardContentProps & CardProps) => {
  const { value, onlyDisplay } = props;
  const filteredData = value.find(
    (item: DataItem) => onlyDisplay?.includes(item.name),
  );
  return (
    <Stack direction="column" alignItems="center">
      <Typography variant="h1" color="#BC0F1F">
        {filteredData?.value}
      </Typography>
      <Typography variant="body1" color="#81B380"></Typography>
      <Typography variant="body1" fontSize="11px" color="grey">
        High and Critical Alerts
      </Typography>
    </Stack>
  );
};

export interface DataItem {
  name: string;
  value?: number | string;
  percentage?: number;
  passed?: number;
  failed?: number;
}

export interface CardProps {
  resource?: string;
  id?: string | number;
  chart?: "progress" | "pie";
  onlyDisplay?: string;
}

export interface CardContentProps extends CardProps {
  value: DataItem[];
}
