import { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import RadioGroupInput, {
  RadioGroupInputProps,
} from "./common/RadioGroupInput";

export const DEFAULT_FILTER = { id: "all", name: "All" };
export const OSRadioGroupInput = (props: RadioGroupInputProps) => {
  const [value, setValue] = useState(DEFAULT_FILTER.id);
  const { filterValues } = useListContext();
  const { source } = props;

  const choices = [
    DEFAULT_FILTER,
    { id: "windows", name: "Windows" },
    { id: "linux", name: "Linux" },
  ];

  useEffect(() => {
    if (source) {
      setValue(filterValues?.[source] || DEFAULT_FILTER.id);
    }
  }, [filterValues]);

  return (
    <RadioGroupInput
      label=""
      choices={choices}
      options={{ value }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      {...props}
    />
  );
};

export default OSRadioGroupInput;
