import { Stack, Typography, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslate, useListContext, Translate } from "react-admin";
import { formatCurrency } from "../../../utils/currency-utils";
import { isDefined } from "../../../types/type-guards/type-guards";
import {
  MAX_FILTER_SUFFIX,
  MIN_FILTER_SUFFIX,
  getMaxFilterRange,
  getMinFilterRange,
} from "./RangeFilterHelper";

/**
 * In case of range filter (Min/Max) display inférior (≤) or supérior (≥) symbol
 * in the chip value
 * @param filter - applied filter
 * @param value - filtered values
 * @returns value with inférior or supérior symbol
 */
const displayMinMaxSign = (filter: string, value: string) => {
  if (filter.endsWith(MIN_FILTER_SUFFIX)) {
    return `≥ ${value}`;
  }
  if (filter.endsWith(MAX_FILTER_SUFFIX)) {
    return `≤ ${value}`;
  }
  return value;
};

/**
 * Translate chip value
 * @param filter applied filter
 * @param filterValues - filtered values
 * @param translate - translate fnct
 * @returns values or translated values if translation is defined in `enStrings.ts` file
 */
export const translateChipValue = (
  filter: string,
  filterValues: any,
  translate: Translate,
): string => {
  let filterValue = filterValues[filter];
  if (Array.isArray(filterValue)) {
    if (filterValue.length === 2) {
      return `${filterValue[0]}, ${filterValue[1]}`;
    }
    if (filterValue.length > 2) {
      return `${filterValue[0]} and ${filterValue.length - 1} more`;
    }
    return filterValue
      .map((value) =>
        translate(`filters.${filter}.chip.${value}`, {
          _: value,
        }),
      )
      .join(", ");
  }
  const value = translate(`filters.${filter}.chip.${filterValue}`, {
    _: filterValue,
  });
  return displayMinMaxSign(filter, value);
};

/**
 * Handle currency/translation of chip values when needed.
 * To have currency formatting, adding attribute `currency: "%{smart_count}"`
 * into `enStrings.ts` file is mandatory
 * @param filter applied filter
 * @param filterValues - filtered values
 * @param translate - translate fnct
 * @param translateChipValue - translate chip fnct
 * @returns formated/translated chip if value needed
 */
export const format = (
  filter: string,
  filterValues: any,
  translate: Translate,
  translateChipValue: (
    filter: string,
    filterValues: any,
    translate: Translate,
  ) => string,
): string => {
  try {
    const value = parseFloat(filterValues[filter]);
    const currencyTranslation = translate(`filters.${filter}.chip.currency`, {
      _: "",
      smart_count: !isNaN(value) && formatCurrency(value),
    });
    if (currencyTranslation === "") {
      return translateChipValue(filter, filterValues, translate);
    }
    return displayMinMaxSign(filter, currencyTranslation);
  } catch (error) {
    console.error(
      `Error during translation of chip value using currency formatter...`,
    );
    return translateChipValue(filter, filterValues, translate);
  }
};

/**
 * When a range filter is applied (MIN & MAX) let's create a single chip
 * displaying both MIN & Max values
 */

/**
 * When a range filter is applied (MIN & MAX) let's create a single chip
 * displaying both MIN & Max values
 * @param chips
 * @returns
 */
const mergeRangeFilters = (
  chips: string[],
): {
  filter: string;
  isRange: boolean;
  min: string | undefined;
  max: string | undefined;
}[] => {
  const rangeFilters = chips.filter(
    (chip) =>
      chip.endsWith(MIN_FILTER_SUFFIX) || chip.endsWith(MAX_FILTER_SUFFIX),
  );
  const filtersToMerge = rangeFilters.reduce(
    (acc: string[], filter: string) => {
      const matched = rangeFilters.find(
        (item) =>
          item.replace(MAX_FILTER_SUFFIX, "") ===
          filter.replace(MIN_FILTER_SUFFIX, ""),
      );
      if (matched) {
        return [
          ...acc,
          matched.replace(MIN_FILTER_SUFFIX, "").replace(MAX_FILTER_SUFFIX, ""),
        ];
      }
      return acc;
    },
    [],
  );

  return [
    ...chips
      .filter(
        (chip) =>
          !filtersToMerge.some(
            (filter) =>
              chip === getMinFilterRange(filter) ||
              chip === getMaxFilterRange(filter),
          ),
      )
      .map((filter) => ({ filter, isRange: false, min: "", max: "" })),
    ...filtersToMerge.map((filter) => ({
      filter,
      isRange: true,
      min: rangeFilters.find((f) => getMinFilterRange(filter) === f),
      max: rangeFilters.find((f) => getMaxFilterRange(filter) === f),
    })),
  ];
};

const FilterChipRow = (props: FilterChipRowProps) => {
  const { omit } = props;
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const translate = useTranslate();
  const filtersToDisplay = Object.keys(filterValues).filter(
    (key) => !omit.includes(key),
  );
  const [chips, setChips] = useState<string[]>(filtersToDisplay);

  useEffect(() => {
    setChips(filtersToDisplay);
    // eslint-disable-next-line
  }, [filterValues]);

  const handleDelete = (filter: string) => {
    delete filterValues[filter];
    delete displayedFilters[filter];
    setFilters(filterValues, displayedFilters);
  };

  const handleMultipleDelete = (filters: string[]) => {
    filters.forEach((filter) => {
      delete filterValues[filter];
      delete displayedFilters[filter];
    });
    setFilters(filterValues, displayedFilters);
  };

  return (
    <>
      <Stack direction="row" marginX={2} gap={1} flexWrap={"wrap"}>
        {mergeRangeFilters(chips).map(({ filter, isRange, min, max }) => {
          if (filter === "policies" && filterValues[filter] === "false") {
            return null;
          } else if (isRange) {
            return (
              <Chip
                sx={{ borderRadius: "4px" }}
                key={filter}
                label={
                  <Stack direction={"row"}>
                    {translate(`filters.${filter}.chip.name`)}:{" "}
                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                      {isDefined(min)
                        ? format(
                            min,
                            filterValues,
                            translate,
                            translateChipValue,
                          )
                        : ""}{" "}
                      -{" "}
                      {isDefined(max)
                        ? format(
                            max,
                            filterValues,
                            translate,
                            translateChipValue,
                          )
                        : ""}
                    </Typography>
                  </Stack>
                }
                onDelete={() => {
                  handleMultipleDelete([min, max].filter(isDefined));
                }}
              />
            );
          } else if (filterValues[filter]) {
            return (
              <Chip
                sx={{ borderRadius: "4px" }}
                key={filter}
                label={
                  <span>
                    {translate(`filters.${filter}.chip.name`)}:{" "}
                    <Typography
                      sx={{ fontSize: 12, fontWeight: 600 }}
                      component="span"
                    >
                      {format(
                        filter,
                        filterValues,
                        translate,
                        translateChipValue,
                      )}
                    </Typography>
                  </span>
                }
                onDelete={() => {
                  handleDelete(filter);
                }}
              />
            );
          }
          return null;
        })}
      </Stack>
    </>
  );
};

interface FilterChipRowProps {
  omit: string[];
  preferenceKey: string;
}

export default FilterChipRow;
