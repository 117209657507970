import { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import RadioGroupInput, {
  RadioGroupInputProps,
} from "./common/RadioGroupInput";

export const DEFAULT_FILTER = { id: "all", name: "All accounts" };
export const CATRadioGroupInput = (props: RadioGroupInputProps) => {
  const { filterValues } = useListContext();
  const { source } = props;
  const [value, setValue] = useState(DEFAULT_FILTER.id);
  const choices = [
    DEFAULT_FILTER,
    { id: "installedUsed", name: "Installed and used" },
    { id: "installedUnused", name: "Installed but not used" },
    { id: "notInstalled", name: "Not installed" },
  ];

  useEffect(() => {
    if (source) {
      setValue(filterValues?.[source] || DEFAULT_FILTER.id);
    }
  }, [filterValues]);

  return (
    <RadioGroupInput
      options={{ value }}
      label=""
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      choices={choices}
      {...props}
    />
  );
};

export default CATRadioGroupInput;
