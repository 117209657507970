import { Layout as RaLayout, LayoutProps } from "react-admin";
import AppBar from "../AppBar/AppBar";
import { ResmedSidebar } from "../SideBar/SideBar";
import ErrorPage from "../../errors/ErrorPage";

const AppLayout = (props: LayoutProps) => {
  return (
    <RaLayout
      appBar={AppBar}
      sidebar={ResmedSidebar}
      error={ErrorPage}
      {...props}
    />
  );
};

export default AppLayout;
