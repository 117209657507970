import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "react-admin";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import { Services } from "../../services/faq-service";

const FaqPage = ({ dashboard }: { dashboard: string }) => {
  const location = useLocation();
  const anchorId = location.hash.substring(1);

  const { isLoading, data } = useQuery("getFaq", () =>
    Services.getFaqTextByDashboard(dashboard).then((res) => res),
  );

  useEffect(() => {
    // scroll into page to go to corresponding section
    if (anchorId) {
      const element = document.querySelector(
        `[href='/${dashboard}/faq#${anchorId}']`,
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [anchorId, data]);

  if (!data || isLoading) {
    return <Loading />;
  }

  return (
    <>
      <StyledBox
        sx={{
          width: "65%",
          display: "flex",
          flexDirection: "column",
          marginX: "auto",
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: "0.3em" }}>
          Frequently Asked Questions (FAQs)
        </Typography>
        <>
          <Typography variant="h3" sx={{ marginBottom: "0.5em" }}>
            Glossary
          </Typography>
        </>
        {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box, {
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  [`a`]: {
    color: "#1788c3",
    scrollMarginTop: parseInt(theme.spacing(6)) + 10,
  },
}));

export default FaqPage;
