import {
  Admin,
  Loading,
  localStorageStore,
  useAuthProvider,
  useTranslate,
} from "react-admin";
import { dataProvider } from "../../providers/dataProvider/dataProvider";
import { i18nProvider } from "../../providers/i18nProvider/i18nProvider";
import AppLayout from "../../components/Layouts/AppLayout";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "react-admin";
import { resmedTheme } from "../../styles/theme";

const DashboardLandingPage = (props: DashboardLandingPageProps) => {
  const { app, basename, resources, dashboard, version } = props;
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const translate = useTranslate();

  useEffect(() => {
    if (app) {
      appContext.setApp(app);
    }
    if (version) {
      appContext.setVersion(version);
    }
    return () => appContext.setApp(undefined);
  }, [app, appContext, version]);

  if (
    permissions != null &&
    permissions != null &&
    !permissions?.includes(app)
  ) {
    navigate("/access_denied");
  }

  return (
    <Admin
      basename={basename}
      theme={resmedTheme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      requireAuth={true}
      ready={Loading}
      loginPage={false}
      layout={AppLayout}
      dashboard={dashboard ? dashboard : undefined}
      store={localStorageStore(undefined, app)}
    >
      {resources({ translate })}
    </Admin>
  );
};

interface DashboardLandingPageProps {
  app: string;
  basename: string;
  version: string;
  resources: any;
  dashboard?: any;
}

export default DashboardLandingPage;
