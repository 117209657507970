import {
  TopToolbar,
  useListContext,
  SelectColumnsButton,
  FilterForm,
  TextInput,
  useTranslate,
} from "react-admin";
import FilterButton from "./Actions/FilterButton";
import FilterChipRow from "./Actions/FilterChipRow";
import { Stack, Box } from "@mui/material";
import { useState } from "react";
import FilterSidebar, {
  FilterCategoryProps,
} from "../SideBar/FilterSidebar/FilterSideBar";

const ListToolbar = (props: ListToolbarProps) => {
  const {
    searchLabel,
    enableSearch,
    filterList,
    preferenceKey,
    selectableColumns,
  } = props;

  const { filterValues } = useListContext();
  const policiesFilter = filterValues["policies"];
  const omittedChipLabels = ["search"];
  const translate = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [filterButtonRef, setFilterButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const getFilters = () =>
    enableSearch
      ? [
          <TextInput
            label={translate(searchLabel ? searchLabel : "Search")}
            source="search"
            alwaysOn
          />,
        ]
      : [];

  return (
    <>
      <TopToolbar
        sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        <Stack direction={"row"} sx={{ alignItems: "flex-start" }}>
          <Box>
            <FilterForm filters={getFilters()} />
          </Box>

          <Stack direction={"row"}>
            {filterList && filterList.length > 0 && (
              <FilterButton
                setIsOpen={setIsOpen}
                setAnchorEl={setFilterButtonRef}
              />
            )}
            {Object.keys(filterValues).length !== 0 && (
              <FilterChipRow
                omit={omittedChipLabels}
                preferenceKey={preferenceKey}
              />
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"} sx={{ alignItems: "baseline" }}>
          {(selectableColumns ||
            (policiesFilter && policiesFilter !== "false")) && (
            <SelectColumnsButton preferenceKey={preferenceKey} />
          )}
        </Stack>
      </TopToolbar>
      <FilterSidebar
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        filters={filterList}
        filterButtonRef={filterButtonRef}
        searchLabel={searchLabel}
      />
    </>
  );
};

interface ListToolbarProps {
  preferenceKey: string;
  searchLabel?: string;
  enableSearch: boolean;
  filterList?: FilterCategoryProps[];
  selectableColumns?: boolean;
}

export default ListToolbar;
