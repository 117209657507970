import React from "react";
import { Tooltip, Box } from "@mui/material";
import ErrorField from "./kpi/ErrorField";
import NAField from "./kpi/NAField";
import SuccessField from "./kpi/SuccessField";
import WarnField from "./kpi/WarnField";
import NoDataWarningField from "./kpi/NoDataWarningField";
import { formatCurrency } from "../../utils/currency-utils";

interface SavingFieldProps {
  record: Record<string, any>;
  saving: string;
  source: string;
  isLink: boolean;
  warning?: string;
  tooltipContent: React.ComponentType<any>;
}

const SavingField: React.FC<SavingFieldProps> = ({
  record,
  saving,
  source,
  isLink,
  tooltipContent: Content,
  warning,
  ...rest
}) => {
  const sourceRecord = record[source] || {};
  const estimatedSavings = sourceRecord[saving];
  const dataWarning = warning ? sourceRecord[warning] : null;

  if (
    sourceRecord === null ||
    sourceRecord.total === 0 ||
    Object.keys(sourceRecord).length === 0
  ) {
    return (
      <Box sx={{ padding: 1 }}>
        <NAField />
      </Box>
    );
  }

  if (estimatedSavings === null || isNaN(estimatedSavings)) {
    return <WarnField value="Not enough data" isLink={false} />;
  }

  if (dataWarning) {
    return (
      <Tooltip
        classes={{ tooltip: "kpiPopover" }}
        title={<Content {...rest} record={record} />}
      >
        <Box sx={{ padding: 1 }}>
          <NoDataWarningField
            value={formatCurrency(estimatedSavings)}
            isLink={isLink}
          />
        </Box>
      </Tooltip>
    );
  }

  let valueComponent: React.ReactNode = null;

  if (estimatedSavings > 1000) {
    valueComponent = (
      <ErrorField value={formatCurrency(estimatedSavings)} isLink={isLink} />
    );
  } else if (estimatedSavings >= 100 && estimatedSavings <= 1000) {
    valueComponent = (
      <WarnField value={formatCurrency(estimatedSavings)} isLink={isLink} />
    );
  } else {
    valueComponent = (
      <SuccessField value={formatCurrency(estimatedSavings)} isLink={isLink} />
    );
  }

  return (
    <Tooltip
      classes={{ tooltip: "kpiPopover" }}
      title={<Content {...rest} record={record} />}
    >
      <Box sx={{ padding: 1 }}>{valueComponent}</Box>
    </Tooltip>
  );
};

export default SavingField;
