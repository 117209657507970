import { fieldPropTypes } from "../../types/default";
import { Box, Typography, Chip } from "@mui/material";
import { useRecordContext } from "react-admin";
import { memo, FC } from "react";
import { NameFieldProps } from "./NameField";

const TagArrayField: FC<TagArrayFieldProps> = memo((props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {source &&
        record[source].map((item: string) => (
          <Chip
            key={item}
            label={item}
            sx={{
              borderRadius: 5,
              fontWeight: 600,
              textTransform: "capitalize",
              width: "fit-content",
              height: "fit-content",
              padding: 0.5,
              background: "#F3FAFF",
            }}
          />
        ))}
    </Box>
  );
});

TagArrayField.displayName = "TagArrayField";

TagArrayField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface TagArrayFieldProps extends NameFieldProps {}

export default TagArrayField;
