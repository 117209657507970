import {
  Loading,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useTranslate,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useMutation, useQuery } from "react-query";
import { Services } from "../../services/faq-service";

const EditFAQ = ({ dashboard }: { dashboard: string }) => {
  const notify = useNotify();
  const translate = useTranslate();

  const { mutate } = useMutation((content: string) =>
    Services.updateFaqTextbyDashboard(dashboard, content),
  );
  const { isLoading, data } = useQuery(`getFaq-${dashboard}`, () =>
    Services.getFaqTextByDashboard(dashboard).then((res) => res),
  );

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const element = document.querySelector<HTMLElement>("#content");
    if (element) {
      mutate(
        element.innerHTML.replace(
          '<br class="ProseMirror-trailingBreak">',
          " ",
        ),
        {
          onSuccess: () => notify(translate("notify.FAQUpdate")),
          onError: (error) => {
            console.log(error);
            notify(translate("commons.error.500"));
          },
        },
      );
    }
  };

  if (!data || isLoading) {
    return <Loading />;
  }

  return (
    <SimpleForm
      record={{ id: 1, content: data }}
      toolbar={
        <Toolbar>
          <SaveButton onClick={handleSave} />
        </Toolbar>
      }
    >
      <RichTextInput source="content" />
    </SimpleForm>
  );
};

export default EditFAQ;
