import { Typography } from "@mui/material";

const CaptionLabel = ({
  value,
  color = "grey",
}: {
  value: string;
  color?: string;
}) => {
  return (
    <Typography
      component="span"
      variant="caption"
      sx={{
        paddingTop: 0.5,
      }}
      color={color}
      noWrap
    >
      {value}
    </Typography>
  );
};

export default CaptionLabel;
