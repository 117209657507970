import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslate } from "react-admin";
import PageHeader from "../../../Header/PageHeader";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import CustomChart from "../../../Charts/CustomComponents/Chart";
import { TCustomChartProps } from "../../../../types/default";
import {
  ACCOUNT_COST_STANDARD,
  COST_ADMIN_TOOL_STANDARD,
} from "../../../../definition/definition";

interface AccordionProps extends TCustomChartProps {
  title: string;
}

const ACCOUNT_NUMBER_SERIES = "accountNumber";

const FinopsHistoryList = () => {
  useSetEntityType("accounts.finopsHistory");
  const preferenceKey = "finopsHistoryList.chart";
  const translate = useTranslate();

  const renderAccordion = ({
    title,
    resource,
    XAxisProps,
    highlightedChart,
    legendOrder,
    chartAlias,
    preferenceKey,
    customLineProps,
  }: AccordionProps) => {
    return (
      /* mountOnEnter: true props allows to load accordion content only on expand while keeping data when unmounted */
      <Accordion elevation={0} TransitionProps={{ mountOnEnter: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomChart
            resource={resource}
            XAxisProps={XAxisProps}
            customLineProps={customLineProps}
            highlightedChart={highlightedChart}
            legendOrder={legendOrder}
            chartAlias={chartAlias}
            preferenceKey={preferenceKey}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <PageHeader
        title="Financial Data"
        descriptionKey="accounts.finopsHistory.infoText"
      />
      {renderAccordion({
        title: translate("finops.charts.finops.title"),
        resource: "analytics/finops",
        XAxisProps: {
          dataKey: "date",
          type: "category",
          allowDuplicatedCategory: false,
        },
        highlightedChart: ACCOUNT_COST_STANDARD,
        legendOrder: {
          higherIsBetter: [COST_ADMIN_TOOL_STANDARD],
          neutral: [ACCOUNT_COST_STANDARD, ACCOUNT_NUMBER_SERIES],
        },
        chartAlias: "financialChartAlias",
        preferenceKey,
        customLineProps: {
          dataKey: "value",
          dot: {
            r: 4,
          },
        },
      })}
      {renderAccordion({
        title: translate("finops.charts.resource.title"),
        resource: "analytics/resources",
        XAxisProps: {
          dataKey: "month",
          allowDuplicatedCategory: false,
          type: "category",
          interval: 0,
          tick: { width: "50px" },
        },
        highlightedChart: undefined,
        legendOrder: {},
        chartAlias: "resourceChartAlias",
        preferenceKey,
        customLineProps: {
          dataKey: "ratio",
          dot: {
            r: 0,
          },
        },
      })}
    </>
  );
};

export default FinopsHistoryList;
