import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useCallback } from "react";
import {
  BooleanInputProps,
  FieldTitle,
  sanitizeInputRestProps,
  useInput,
} from "react-admin";
import { FiltersCommonInputProps } from "../../../types/default";

const CheckboxInput = (
  props: CheckboxInputProps &
    FiltersCommonInputProps & {
      valueOnCheck: string | undefined;
      valueOnUnCheck: string | undefined;
    },
) => {
  const {
    valueOnCheck,
    valueOnUnCheck,
    defaultValue = false,
    label,
    onBlur,
    onChange,
    onFocus,
    disabled,
    resource,
    source,
    validate,
    options = defaultOptions,
    size = "medium",
    sx,
    ...rest
  } = props;

  const { id, field, isRequired } = useInput({
    defaultValue,
    format: (value) => (value === valueOnCheck ? true : false),
    parse: (value) => (value === false ? valueOnUnCheck : valueOnCheck),
    resource,
    source,
    onBlur,
    onChange,
    type: "checkbox",
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event: any) => {
      let checked = event.target.checked;
      field.onChange(checked);
      // Ensure field is considered as touched
      field.onBlur();
    },
    [field],
  );

  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ pl: 0 }}
            id={id}
            name={field.name}
            color="primary"
            onChange={handleChange}
            onFocus={onFocus}
            size={size}
            checked={Boolean(field.value)}
            {...sanitizeInputRestProps(rest)}
            {...options}
            disabled={disabled}
            disableRipple
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
    </FormGroup>
  );
};

export type CheckboxInputProps = BooleanInputProps & CheckboxProps;

export default CheckboxInput;

const defaultOptions = {};
