import { Typography } from "@mui/material";
import { useTranslate } from "react-admin";

const PageHeaderDescription = (props: PageHeaderDescriptionProps) => {
  const translate = useTranslate();
  const { i18nKey } = props;

  return (
    <Typography variant="body1" sx={{ marginBottom: 3, color: "#838383" }}>
      {translate(i18nKey)}
    </Typography>
  );
};

interface PageHeaderDescriptionProps {
  i18nKey: string;
}

export default PageHeaderDescription;
