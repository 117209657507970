import { ReferenceArrayInputProps, useTranslate } from "react-admin";
import { FiltersCommonInputProps } from "../../types/default";
import AutoCompleteMultiInput from "./common/AutoCompleteMultiInput";

const FirstAdminInput = (
  props: ReferenceArrayInputProps & FiltersCommonInputProps,
) => {
  const translate = useTranslate();
  return (
    <AutoCompleteMultiInput
      {...props}
      label={translate("filters.first_admin.label")}
      key="first_admin"
      attributes_to_get="first_admin"
      search_attribute="first_admin"
    />
  );
};

export default FirstAdminInput;
