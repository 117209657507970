import { Stack, Typography } from "@mui/material";
import { Identifier, Loading, useGetOne, useTranslate } from "react-admin";
import { formatCurrency } from "../../../../utils/currency-utils";
import { isDefined } from "../../../../types/type-guards/type-guards";
import { TRODIntro } from "../../../../types/default";

interface IntroProps {
  identityId: Identifier | undefined;
  firstName: string | undefined;
}

export function Intro({ identityId, firstName }: IntroProps) {
  const translate = useTranslate();
  const { data, isLoading, isFetching } = useGetOne(
    `users/${identityId}`,
    {
      id: "costs",
    },
    { enabled: isDefined(identityId) },
  );

  if (isLoading || isFetching || !isDefined(data)) {
    return <Loading />;
  }

  const {
    metrics: {
      potential_savings,
      optimization_percentage,
      total_accounts,
      total_cost,
    },
    user_type,
  }: TRODIntro = data;

  return (
    <Stack direction="column">
      <Typography
        variant="h1"
        color="primary.main"
        dangerouslySetInnerHTML={{
          __html: translate(`accounts.finops.dashboard.${user_type}.header`, {
            name: firstName,
            potentialSavings: `<span style="font-weight:600">${formatCurrency(
              potential_savings,
            )}</span>`,
          }),
        }}
      />
      <Typography
        variant="h3"
        color="grey"
        dangerouslySetInnerHTML={{
          __html: translate(`accounts.finops.dashboard.${user_type}.subtitle`, {
            optimizationPercentage: `<span style="font-weight:600">${optimization_percentage.toFixed(
              2,
            )}</span>`,
            potentialSavings: `<span style="font-weight:600">${formatCurrency(
              potential_savings,
            )}`,
            totalCost: `<span style="font-weight:600">${formatCurrency(
              total_cost,
            )}</span>`,
            totalAccounts: `<span style="font-weight:600">${total_accounts}</span>`,
          }),
        }}
      />
      <Typography
        variant="h3"
        color="grey"
        dangerouslySetInnerHTML={{
          __html: translate(
            `accounts.finops.dashboard.${user_type}.subtitle2`,
            {
              optimizationPercentage: `<span style="font-weight:600">${optimization_percentage.toFixed(
                2,
              )}</span>`,
              _: "",
            },
          ),
        }}
      />
    </Stack>
  );
}

export default Intro;
