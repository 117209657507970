import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as TrustPortalLogo } from "./TrustPortalLogo.svg";

const ResmedLogo = () => {
  return (
    <Box
      component={Link}
      display="flex"
      flex="0"
      alignItems="center"
      justifyContent="flex-start"
      to="/"
      sx={{
        color: "inherit",
        textDecoration: "inherit",
      }}
    >
      <Box maxHeight={"40px"} component={TrustPortalLogo} />
    </Box>
  );
};

export default ResmedLogo;
