/* standards */
export const ACCOUNT_USED_STANDARD = "accountUsed";
export const COST_ADMIN_TOOL_STANDARD = "costReport";
export const UNDERUTILIZED_EC2_STANDARD = "underutilizedEc2";
export const UNDERUTILIZED_EBS_STANDARD = "underutilizedEbs";
export const SQL_SERVER_STANDARD = "SQLServer";
export const ACCOUNT_COST_STANDARD = "accountCost";
export const GP_TYPE_STANDARD = "gpType";
export const VOLUME_STATE_STANDARD = "volumeState";
export const COST_CENTER = "costCenter";
export const OUTDATED_OS_EC2 = "outdatedOSEC2";

export const UNPATCHED_EC2_STANDARD = "unpatchedEC2";
export const PUBLIC_S3 = "publicS3";
export const PRISMA_ALERTS_STANDARD = "prismaAlerts";
export const EXPOSED_ENDPOINT_STANDARD = "exposedEndpoint";

/* Dashboard code */
export const ROD_DASHBOARD = "rod";
export const COD_DASHBOARD = "cod";
export const SPD_DASHBOARD = "spd";
export const CTD_DASHBOARD = "ctd";

/* DATADOG */
export const DATADOG_LINK_MONITORING = {
  VANTAGE: "LINK_MONITORING_VANTAGE",
  CAT_REPORT: "LINK_MONITORING_CAT_REPORT",
  CAT_INSTALLATION: "LINK_MONITORING_CAT_INSTALLATION",
  PRISMACLOUD: "LINK_MONITORING_PRISMACLOUD",
  ARFII: "LINK_MONITORING_ARFII",
  APP_SEC_DASHBOARD: "LINK_MONITORING_APP_SEC_DASHBOARD",
};

export const VANTAGE_SWITCH_LINK =
  "https://console.vantage.sh/account/organizations/6339/switch";
