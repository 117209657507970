import { memo, FC, ComponentType } from "react";
import { fieldPropTypes } from "../../../types/default";
import { FieldProps, useRecordContext } from "react-admin";
import { Box, Typography, TypographyProps, Tooltip } from "@mui/material";
import ErrorField from "../kpi/ErrorField";
import NAField from "../kpi/NAField";
import SuccessField from "../kpi/SuccessField";
import WarnField from "../kpi/WarnField";

export interface AlertFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  source: string;
  tooltipContent: ComponentType<any>;
}

export const AlertField: FC<AlertFieldProps> = memo((props) => {
  const { source, tooltipContent: Content } = props;
  const record = useRecordContext(props);
  let valueComponent = null;
  const total = record[source]?.total;
  if (record[source] === null || total === null) {
    return <NAField />;
  } else if (total >= 1 && total <= 250) {
    valueComponent = <WarnField isLink={false} value={`${total}`} />;
  } else if (total > 250) {
    valueComponent = <ErrorField isLink={false} value={`${total}`} />;
  } else {
    valueComponent = <SuccessField isLink={false} value={`${total}`} />;
  }
  return (
    <Tooltip
      classes={{ tooltip: "kpiPopover" }}
      title={<Content {...props} record={record} />}
    >
      <Box sx={{ padding: 1 }}>{valueComponent}</Box>
    </Tooltip>
  );
});

AlertField.displayName = "TextField";

AlertField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export default AlertField;
