import { Box, Typography, Stack } from "@mui/material";
import { isDefined } from "../../types/type-guards/type-guards";
import { Divider } from "@mui/material";
import { BreakdownMessages } from "../../types/default";

const BreakdownContent = (props: BreakdownContentProps) => {
  const { detailButton, messages, clickable } = props;
  return (
    <Stack
      width="max-content"
      sx={{ cursor: clickable ? "pointer" : "default" }}
    >
      <Box marginBottom="1em">
        {messages.map(({ key, value, styling }) => {
          if (styling?.divider) {
            return (
              <Divider
                data-testid={`divider-${key}`}
                key={key}
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
              />
            );
          }
          return (
            <Typography
              data-testid={`message-${key}`}
              key={`message-${key}`}
              {...(styling?.variant ? { variant: styling?.variant } : {})}
              {...(styling?.spacing ? { sx: { pl: 1 } } : {})}
              {...(styling?.marginTop ? { marginTop: "1em" } : {})}
              {...(styling?.sx ? { sx: styling.sx } : {})}
            >
              {key}
              {isDefined(value) ? ":" : ""} <b>{value}</b>
            </Typography>
          );
        })}
      </Box>
      {detailButton && detailButton}
    </Stack>
  );
};
export interface BreakdownContentProps {
  detailButton?: JSX.Element;
  clickable?: boolean;
  messages: BreakdownMessages[];
}

export default BreakdownContent;
