import {
  AppBarProps as RaAppBarProps,
  useLocales,
  AppBarClasses,
  Toolbar,
  LocalesMenuButton,
  HideOnScrollProps,
  useGetIdentity,
  Link,
  useBasename,
} from "react-admin";
import { styled } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  useMediaQuery,
  Theme,
  Stack,
} from "@mui/material";
import ResmedLogo from "./Logo";
import { Fragment } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import UserMenu from "./UserMenu/UserMenu";
import { usePermissions } from "react-admin";

export const HideOnScroll = (props: HideOnScrollProps) => {
  const { children } = props;
  return <Fragment>{children}</Fragment>;
};

const ResmedAppBar = (props: AppBarProps) => {
  const {
    className,
    logo: Logo = ResmedLogo,
    container: Container = HideOnScroll,
    title: _,
    ...rest
  } = props;
  const locales = useLocales();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isUserConnected = useGetIdentity();
  const basename = useBasename();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("adminDashboard");
  return (
    <Container className={className}>
      <StyledAppBar
        className={AppBarClasses.appBar}
        position="sticky"
        sx={{ background: (theme) => theme.palette.gradient.main }}
        {...rest}
      >
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={AppBarClasses.toolbar}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Logo />
          {locales && locales.length > 1 ? <LocalesMenuButton /> : null}
          <Stack direction="row" sx={{ alignItems: "center" }}>
            {isUserConnected.identity && <UserMenu isAdmin={isAdmin} />}
            {basename && (
              <Link to={`${basename}/faq`}>
                <Tooltip title="FAQ page">
                  <IconButton
                    sx={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
};

interface AppBarProps extends RaAppBarProps {
  logo?: React.ElementType<any>;
}

const PREFIX = "RaAppBar";

// eslint-disable-next-line
const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    backgroundColor: "inherit",
    padding: `0 ${theme.spacing(1)}`,
    [theme.breakpoints.down("md")]: {
      minHeight: theme.spacing(6),
    },
  },
  [`& .${AppBarClasses.menuButton}`]: {
    marginRight: "0.2em",
  },
  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export default ResmedAppBar;
