import { useRecordContext } from "ra-core";
import { TextFieldProps as BaseTextFieldProps } from "react-admin";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export interface LocalDateFieldProps extends BaseTextFieldProps {}

export const LocalDateField = ({ source }: LocalDateFieldProps) => {
  const record = useRecordContext();
  const value = source ? record?.[source] : undefined;

  const [localTime, setLocalTime] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (value) {
      const localDate = dayjs(value).utc().local();
      setLocalTime(localDate.format("YYYY/MM/DD HH:mm:ss"));
    }
  }, [value]);

  return (
    <Typography
      variant="body2"
      sx={{
        display: "flex",
        alignItems: "center",
        margin: "auto",
        gap: 0.5,
      }}
      noWrap
    >
      {localTime}
    </Typography>
  );
};
