import { useEffect, useState } from "react";
import { useListContext, useTranslate } from "react-admin";
import RadioGroupInput, {
  RadioGroupInputProps,
} from "./common/RadioGroupInput";

export const ENV_TYPE_DEFAULT_FILTER = { id: "all", name: "All accounts" };
export const EnvTypeRadioGroupInput = (props: RadioGroupInputProps) => {
  const translate = useTranslate();
  const { source } = props;
  const { filterValues } = useListContext();
  const [value, setValue] = useState(ENV_TYPE_DEFAULT_FILTER.id);
  const choices = [
    ENV_TYPE_DEFAULT_FILTER,
    { id: "prd", name: "Production" },
    { id: "non-prd", name: "Non-Production" },
  ];

  useEffect(() => {
    setValue(filterValues?.["env_type"] || ENV_TYPE_DEFAULT_FILTER.id);
  }, [filterValues]);

  return (
    <RadioGroupInput
      options={{ value }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      label={translate(`filters.${source}.label`)}
      choices={choices}
      sx={{ mt: 1 }}
      {...props}
    />
  );
};
