import { Typography } from "@mui/material";
import { useTranslate } from "react-admin";

const SUBTITLE_STYLE = {
  display: "flex",
  alignItems: "center",
  margin: "auto",
  gap: 0.5,
  fontSize: 12,
  color: "grey",
};

export const NameTooltip = ({
  record,
  active,
}: {
  record: any;
  active: boolean;
}) => {
  const translate = useTranslate();

  return (
    <>
      <Typography sx={{ fontWeight: 600, fontSize: 12 }}>
        {active ? (
          translate("commons.tooltip.active_owner")
        ) : (
          <>
            <Typography sx={{ color: "red", fontWeight: 600, fontSize: 12 }}>
              {translate("commons.tooltip.inactive_owner")}
            </Typography>
            {translate("commons.tooltip.inactive_owner_update")}
          </>
        )}
      </Typography>
      <Typography sx={SUBTITLE_STYLE}>Account ID: {record?.id}</Typography>
      {record?.first_admin && (
        <Typography sx={SUBTITLE_STYLE}>
          Account First Admin: {record.first_admin}
        </Typography>
      )}
    </>
  );
};
