const enStrings = {
  commons: {
    search: "Search For Account Name",
    email:
      "?subject=Trust Portal feedback&body=%28please%20remove%2Fmodify%20anything%20needed%29%0A----------------------------------------------------------%0AHello%20team%2C%20%0A%0AI%27m%20using%20Trust%20Dashboard%20%2F%20Resource%20Optimization%20Dashboard%20%2F%20Both%20dashboards.%0A%0AOverall%20Rating%20%3A%20%0A-%20Awesome%21%0A-%20Good%0A-%20Meh%21%0A-%20Bad%0A-%20Horrible%21%0A%0AWhat%20do%20you%20like%3F%0A...%0A%0AWhat%20needs%20to%20be%20improved%3F%0A...%0A%0A",
    error: {
      403: "Access denied. Cannot fetch data, please contact admin.",
      500: "Server error, please contact admin.",
    },
    prismaDialog: `Prisma Cloud opened a login window in the background, please don't
    close it. This window will close automatically as soon as Prisma
    Cloud has completed the login and the Prisma Cloud detail page will
    open.`,
    filters: "Filters",
    filterCategories: {
      account_name: "Account Name",
      owner: "Account Owner",
      general: "General Filters",
      first_admin: "Account First Admin",
      outdated_os: "Unsupported OS",
    },
    total: "Total",
    day: "%{smart_count} day |||| %{smart_count} days",
    placeholder: "Placeholder Text TO REPLACE",
    resourceTotal: "Total Number of Resources",
    tooltip: {
      active_owner: "Use ARFII (link in the left menu) to update this address.",
      inactive_owner: "The email address could not be verified in Okta.",
      inactive_owner_update:
        "Please update this address using ARFII (link in the left menu).",
    },
    columnTotal: {
      estimated_savings: "Tot. estimate:",
      actual_savings: "Tot. savings:",
      last_month: "Total:",
    },
  },
  notify: {
    FAQUpdate: "FAQ has been successfully updated",
    RDSInstanceNotOptimized:
      "Your current size is not optimized for a non-production environment. Please make sure your use case justifies the current cost of %{cost} monthly.",
  },
  nav: {
    breadcrumbs: {
      cod: "Compliance Opt. Dashboard",
      rod: "Resource Opt. Dashboard",
      spd: "Security Posture Dashboard",
      admin: "Admin Panel",
      accounts: "%{region} Accounts",
      products: "%{region} Products",
      rds: "RDS Optimization Details",
      "security-gaps": "%{region} Accounts",
      s3: "Exposed S3 Details",
    },
    codDashboard: {
      appTitle: "Compliance Opt. Dashboard",
      account: "Account Compliance",
      product: "Product Compliance",
      description:
        "The Compliance Optimization Dashboard is a portal that allows AWS Account owners and TRM Team members to monitor the level of programmatic compliance of all AWS resources for any product and any standard listed, with a structured, dynamic, and daily updated evidence-based approach.",
    },
    rodDashboard: {
      appTitle: "Res. Opt. Dashboard",
      account: "Accounts Overview",
      description:
        "The Resource Optimization Dashboard is a portal that allows AWS Account owners and members of the Cost Optimization Team to monitor different Cost Optimization KPIs of related AWS resources for any product, with a structured, dynamic, and daily updated evidence-based approach.",
      owner: "Owners Overview",
      history: "Historical Overview",
    },
    adminDashboard: {
      appTitle: "Admin Dashboard",
      description:
        "Access to Trust Portal's admin panel is restricted to approved users.",
    },
    spdDashboard: {
      appTitle: "Sec. Posture Dashboard",
      dashboardTitle: "Security Posture",
      "security-gaps": "Accounts Overview",
      owner: "Owners Overview",
      description:
        "The Security Posture Dashboard is a portal that allows ITSec and leadership to monitor the level of programmatic compliance of all AWS resources according to ResMed IT policies, with a structured, dynamic, and an evidence-based approach that is updated daily.",
    },
    ctdDashboard: {
      appTitle: "Cost Trend Dashboard",
      dashboardTitle: "Cost Trend",
      description:
        "The Cost Trend Dashboard is a portal that allows AWS Account owners and members of the Cost Optimization Team to monitor different Cost Trend KPIs of related AWS resources for any product, with a structured, dynamic, and daily updated evidence-based approach.",
    },
  },
  admin: {
    ingestions: {
      header: "This view displays details of all ingestion from Trust Portal",
      sublabel: "Ingestion Failure Details",
    },
    users: {
      header:
        "This view allows administrators to update roles for all users of Trust Portal.",
    },
    faq: {
      header: "",
    },
  },
  security: {
    columns: {
      costCenter: {
        title: "Cost Center",
      },
      unpatchedEC2: {
        title: "Unpatched EC2",
        tooltip:
          "This column lists all EC2 found for each account to identify those that were deployed using older AMI (needing patching). The percentage shows ECS using up-to-date AMI. See FAQ for a detailed description",
      },
      publicS3: {
        title: "S3 Exposure",
        tooltip:
          "This column lists all S3 Buckets found for each account to identify those that are currently exposed to the internet. The percentage shows all S3 currently not exposed to the internet (secure). See FAQ for a detailed description",
      },
      prismaAlerts: {
        title: "Prisma Alerts",
        tooltip:
          "This column highlights the number of High and Critical Alerts identified by PrismaCloud for all accounts linked to the owner. High and Critical Alerts should be managed by the account owner as fast as possible as they might represent a security risk for the company. Please see the FAQ for additional information",
      },
      outdatedOSEC2: {
        title: "Unsupported OS",
      },
    },
  },
  compliance: {
    columns: {
      hds: "ISO-27001/HDS",
      gdpr: "GDPR",
      soc2: "SOC2",
      ccpa: "CCPA",
      hipaa: "HIPAA",
      hitrust: "HITRUST",
    },
  },
  finops: {
    columns: {
      gpType: {
        title: "GP2 to GP3",
        policyName: "AWS EBS volume as GP2 volumetype",
        legend: {
          financialChartAlias: "GP2 to GP3",
          resourceChartAlias: "GP3 instances",
        },
      },
      volumeState: {
        title: "Attached EBS",
        policyName: "AWS EBS volume unattached",
        legend: {
          financialChartAlias: "Underutilized Accounts",
          resourceChartAlias: "EBS unattached",
        },
      },
      SQLServer: {
        title: "RDS SQL Savings",
        legend: {
          financialChartAlias: "Potential savings RDS SQL License",
          resourceChartAlias: "Not optimized RDS",
        },
      },
      accountUsed: {
        title: "Underutilized Accounts",
        legend: {
          resourceChartAlias: "Underutilized Accounts",
        },
      },
      costReport: {
        title: "Cost Admin Tool",
        legend: {
          financialChartAlias: "Actual savings from CAT",
          resourceChartAlias: "Accounts without CAT",
        },
        finChartCostType: "Actual",
      },
      underutilizedEc2: {
        title: "Underutilized EC2",
        legend: {
          financialChartAlias: "Potential savings underutilized EC2",
          resourceChartAlias: "Underutilized EC2",
        },
      },
      accountCost: {
        title: "Total Cost",
        legend: {
          financialChartAlias: "Total Cost",
          finChartCostType: "",
        },
      },
      costCenter: {
        title: "Cost Center",
      },
      underutilizedEbs: {
        title: "Underutilized EBS",
        legend: {
          financialChartAlias: "Potential savings underutilized EBS",
          resourceChartAlias: "Underutilized EBS",
        },
      },
      accountNumber: {
        title: "Total number of accounts",
        legend: {
          financialChartAlias: "Total Accounts",
          resourceChartAlias: "Total Accounts",
        },
      },
    },
    details: {
      true: "Optimized",
      false: "Non Optimized",
    },
    charts: {
      finops: {
        title: "Financial Historical Overview",
      },
      resource: {
        title: "Resource Historical Overview",
      },
      tooltip: {
        accountCost: {
          value: "Total cost",
        },
        accountNumber: {
          value: "Total number of accounts",
        },
        costReport: {
          value: "Accounts with Cost Admin Tool",
          total: "Count of non production accounts",
          ratio: "Ratio",
        },
        gpType: {
          value: "GP2 to GP3",
          total: "Count of volumes",
          ratio: "Ratio",
        },
        SQLServer: {
          value: "Not optimized RDS instances",
          total: "Count of RDS instances",
          ratio: "Ratio",
        },
        underutilizedEc2: {
          value: "Underutilized EC2",
          total: "Count of EC2 instances",
          ratio: "Ratio",
        },
        underutilizedEbs: {
          value: "Underutilized EBS",
          total: "Count of EBS volumes",
          ratio: "Ratio",
        },
      },
    },
  },
  accounts: {
    displayName: "Account Name",
    search: "Search Account",
    security: {
      unpatchedEC2: {
        tooltip:
          "This column lists all EC2 found for each account to identify those that were deployed using older AMI (needing patching). The percentage shows ECS using up-to-date AMI. See FAQ for a detailed description",
        popover: {
          total: "Total N° of EC2",
          failed: "N° of unpatched EC2",
          passed: "N° of patched EC2",
        },
        title: "Unpatched EC2",
        path: "security/unpatched-ec2",
        chart: {
          passed: "Patched",
          failed: "Unpatched",
        },
      },
      outdatedOSEC2: {
        path: "security/unsupported-os-ec2",
        title: "OS Support",
        tooltip:
          "This column highlights the percentage of Servers that are currently running a supported OS (still receiving security updates, non-security updates, bug fixes and technical support) compared to the overall number of Server identified for the Account. Unsupported OS should be upgraded to the newest available version. Please see the FAQ for additional information.",
        popover: {
          failed: "Total N° EC2 running unsupported OS",
          total: "Total N° of EC2",
          els: "Extended Lifecycle Support (ELS) cost",
        },
        chart: {
          passed: "Supported",
          failed: "Unsupported",
        },
        infoText:
          "List of EC2 instances running an outdated OS and details of the instance with the recommended OS version.",
      },
      outdatedWinEC2: {
        path: "security/unsupported-os-win",
        title: "Windows Servers",
        chart: {
          passed: "Supported",
          failed: "Unsupported",
        },
      },
      outdatedRhelEC2: {
        path: "security/unsupported-os-rhel",
        title: "RHEL Servers",
        chart: {
          passed: "Supported",
          failed: "Unsupported",
        },
      },
      exposedEndpoint: {
        path: "security/exposed-endpoint",
        title: "Endpoints Exposure",
        tooltip:
          "This column highlights the number of all endpoints found for each account that are publicly reachable from the internet. See FAQ for a detailed description",
        popover: {
          failed: "Number of Public Endpoints",
          total: "Total Number of Endpoints",
        },
        chart: {
          passed: "Private",
          failed: "Public",
        },
        infoText:
          "List of Public endpoints for the specified account with additional information. To access additional detailed information, please select the link and log into PrismaCloud.",
      },
      publicS3: {
        infoText:
          "List of Public S3 Buckets instances for the specified account with their additional information.",
        tooltip:
          "This column lists all S3 Buckets found for each account to identify those that are currently exposed to the internet. The percentage shows all S3 currently not exposed to the internet (secure). See FAQ for a detailed description",
        popover: {
          total: "Total N° of S3",
          failed: "N° of exposed S3",
        },
        path: "security/public-s3",
        title: "S3 Exposure",
        chart: {
          passed: "Private",
          failed: "Exposed",
        },
      },
      prismaAlerts: {
        tooltip:
          "This column highlights the number of High and Critical Alerts identified by PrismaCloud for the account. High and Critical Alerts should be managed by the account owner as fast as possible as they might represent a security risk for the company. Please see the FAQ for additional information",
        popover: {
          highAlertsTotal: "High Alerts",
          highAlertsOver1Month: "High Alerts (> 1 Month)",
          highAlertsOver6Months: "High Alerts (> 6 Months)",
          criticalAlertsTotal: "Critical Alerts",
          criticalAlertsOver1Month: "Critical Alerts (> 1 Month)",
          criticalAlertsOver6Months: "Critical Alerts (> 6 Months)",
        },
        path: "security/prisma-alerts",
        title: "Prisma Alerts",
        chart: {
          failed: "Total Alerts",
        },
      },
      infoText:
        "List of AWS accounts with the level of programmatic compliance of all AWS resources.",
    },
    compliance: {
      popover: {
        total: "N° total of services used",
        passed: "N° of compliant services",
        failed: "N° of non-compliant services",
      },
      infoText:
        "List of AWS accounts with the level of compliant AWS services per standard. The result is displayed as a percentage of services compliant over the total number of services used in the account",
    },
    securityOwners: {
      displayName: "Owner Name",
      infoText:
        "List of AWS Account Owners with the level of programmatic compliance of all AWS resources.",
      email: {
        popover: {
          title: "Click to show the list of accounts linked to this owner",
        },
      },
      prismaAlerts: {
        title: "Prisma Alerts",
        tooltip:
          "This column highlights the number of High and Critical Alerts identified by PrismaCloud for all accounts linked to the owner. High and Critical Alerts should be managed by the account owner as fast as possible as they might represent a security risk for the company. Please see the FAQ for additional information",
      },
    },
    finopsOwners: {
      displayName: "Owner Name",
      popover: {
        total: "Total number of accounts owned",
        passed: "Optimized accounts %{optimizedValues}",
        failed: "Accounts needing optimization %{optimizedValues}",
      },
      infoText:
        "List of AWS Account Owners with the optimization level per cost optimization workstream. The resulting table displays the achievable KPI for each use case expressed either as a percentage of optimized resources over total resources or as potential savings for all accounts aggregated by owner.",
      email: {
        popover: {
          title: "Click to show the list of accounts linked to this owner",
        },
      },
      accountCost: {
        tooltip:
          "Total cost for all accounts for the specified Account Owner for the previous month.",
      },
    },
    finops: {
      product: {
        displayName: "Product",
        tooltip:
          "This column shows the Product Tag currently assigned to this Account in ARFII. The Product link will be used to manage to group all resources for different use cases (e.g. Budgets).",
      },
      gpType: {
        tooltip:
          "This KPI is based on the number of GP3 volumes over the total number of GP volumes (for the AWS account) displayed as a percentage. Click for more details.",
        popover: {
          title: "Number of EC2 Volumes: ",
          total: "Total Volumes Deployed",
          passed: "GP3 Volumes (Higher is Better)",
          failed: "GP2 Volumes",
        },
      },
      volumeState: {
        tooltip:
          "This KPI is based on the number of EBS volumes unattached to EC2 instance over the total number of EBS volumes (for the AWS account), displayed as percentage.  Click for more details",
        popover: {
          title: "Number of EBS volumes:",
          total: "Total volumes",
          passed: "Attached to EC2",
          failed: "Unattached to EC2",
        },
      },
      SQLServer: {
        tooltip:
          "The RDS SQL value shows the recommended setup and the estimated achievable savings if the recommendations are implemented. The estimated value is the sum of all potential savings that could be calculated for this use case for each account",
        popover: {
          lastMonthRDSTotalSavings: "Resulting estimated savings",
          lastMonthRDSEstimated:
            "Estimated cost of RDS instances with recommended setup",
          lastMonthRDSCost: "Last month's actual cost of RDS instances",
          partialData:
            "Some instance settings could not be assessed. Please check the details.",
          noData:
            "The total savings cannot be calculated. Please check the details",
        },
        infoText:
          "List of RDS with SQL engine instances for the specified account with their current and recommended values of provisioned storage, instance type and SQL license.",
      },
      accountUsed: {
        tooltip:
          "This column highlights all the AWS Accounts that have not been accessed for more than 60 days. The estimated value is the sum of all potential savings that could be calculated for this use case for all accounts displayed. More details on the control are in the FAQ.",
        popover: {
          title: "AWS Accounts (within 2 months)",
          timestamp: "Last activity on",
          no_timestamp: "No activity in 2 months",
          userAccess: "User Access in last 60 Days",
          provisionedResources: "Provisioned Resources",
          CICDDeployments: "CI/CD Deployments in last 60 Days",
        },
      },
      costReport: {
        tooltip:
          "This column shows if the Cost Admin Tool is installed on the account or not. The actual value is the sum of all actual savings that could be calculated for each account using CAT.",
      },
      underutilizedEc2: {
        tooltip:
          "This columns highlights the saving on underutilized EC2 instances found for each account. The estimated value is the sum of all potential savings that could be calculated for this use case for each account. See FAQ for a detailed description",
        popover: {
          title: "N° of EC2 instances:",
          total: "Total",
          none: "No underutilized EC2 found",
          failed: "Underutilized in the last 14 days",
          monthly_estimated_savings: "Estimated savings",
        },
      },
      accountCost: {
        tooltip: "Total cost from specified account during the previous month.",
        popover: {
          title:
            "This is the total unblended cost for the previous month for this AWS account.",
        },
      },
      costCenter: {
        tooltip:
          "This column shows the 3-letter Cost Center ID provided by Finance when an account is vended.",
      },
      underutilizedEbs: {
        tooltip:
          "This columns highlights all the underutilized EBS instances found for each account. The estimated value is the sum of all potential savings that could be calculated for this use case for each account. See FAQ for a detailed description",
      },
      infoText:
        "List of AWS Accounts with the optimization level per cost optimization workstream. The resulting table displays the achievable KPI for each use case expressed as a percentage of optimized resources over total resources or as potential savings for each account.",
      dashboard: {
        gpType: "GP2 to GP3",
        SQLServer: "SQL Server",
        underutilizedEc2: "Underutilized EC2",
        underutilizedEbs: "Underutilized EBS",
        default: {
          header: "Welcome %{name} !",
          subtitle:
            "you can save %{potentialSavings} out of %{totalAccounts} accounts. ",
          progressionChart: {
            title: "Top 5 account raw cost progression",
            tooltip:
              'This chart illustrates the trend in the " raw usage " cost of the 5 most expensive accounts over the previous month.',
          },
          topOptimization: {
            title: "Top 5 accounts to optimize",
            tooltip:
              "This table highlights the 5 accounts with the highest potential savings. Use the button on the right for more details.",
          },
          topUsecases: {
            title: "Top 5 accounts with highest potential savings per use case",
            tooltip:
              "This highlights the 5 accounts with the highest potential savings per use case.",
          },
        },
        businessOwner: {
          header: "Welcome %{name}, you can save %{potentialSavings} ",
          subtitle:
            "You have %{totalAccounts} accounts for a total cost of %{totalCost}",
          subtitle2:
            "This is a %{optimizationPercentage}% optimization of all your accounts",
          progressionChart: {
            title: "Your top 5 account raw cost progression",
            tooltip:
              'This chart illustrates the trend in the " raw usage " cost of the 5 most expensive accounts over the previous month for which you are the Business Owner.',
          },
          topOptimization: {
            title: "Your top 5 accounts to optimize",
            tooltip:
              "This table highlights the 5 accounts where you, as a Business Owner, have the highest potential savings. Use the button on the right for more details.",
          },
          topUsecases: {
            title:
              "Your Top 5 accounts with highest potential savings per use case",
            tooltip:
              "This highlights the 5 accounts where you, as a Business Owner, have the highest potential savings per use case.",
          },
        },
      },
    },
    finopsHistory: {
      infoText:
        "This page shows all available historical data for the Resource Optimization Dashboard over the past 4 months. Please select a filter to refine the results (per account or per owner).",
    },
  },
  products: {
    displayName: "Product Name",
    compliance: {
      switchLabel: "Hide columns without policies",
      popover: {
        total: "Applicable requirements",
        passed: "Compliant requirements",
        failed: "Non compliant requirements",
        notApplicable: "Not Applicable requirements",
      },
      infoText:
        "List of Business products with level of compliance per standard. The result is displayed as percentage of compliant requirements over total number of requirement applicable to the standard and the product",
    },
  },
  services: {
    displayName: "Service Name",
    popover: {
      total: "N° total of resources used",
      passed: "N° of compliant resources",
      failed: "N° of non-compliant resources",
    },
    infoText:
      "List of AWS Services with the level of compliant area per standard. The result is displayed as a percentage of resources compliant over the total number of resources used for each service.",
  },
  adminDashboard: {
    userManagement: {
      infoText: "Manage access and roles of Trust Portal users.",
    },
    ingestionMonitoring: {
      infoText: "Details of latest ingestions' failures.",
    },
  },
  faq: {
    rodDashboard: {
      headers: {
        volumeState: {
          title: "% attached EBS",
          description:
            "This KPI is based on the number of EBS volumes unattached to EC2 instance over the total number of EBS volumes (for the AWS account), displayed as percentage. Why ? Charges begin when a volume is created. If a volume remains unattached or has very low write activity (excluding boot volumes) for a period of time, the volume is probably not being used. The lifecycle of EBS volumes can be independent of EC2 instances. Therefore, even if the EC2 instance associated with the EBS volumes is terminated, the EBS volumes tend to persist unless you select the Delete on Termination option at launch.",
        },
        gpType: {
          title: "GP2 to GP3",
          description:
            "This KPI is based on the number of GP3 volumes over the total number of all EC2 volumes (for the AWS account), displayed as percentage. Why ? With comparable size, migrating your EBS volumes from gp2 to gp3 saves up to 20% on EBS costs because GP2 and GP3 volume types don't have the same pricing model, as the IOPS and throughput of GP2 volume is related to the size of it, while the IOPS and throughput of GP3 volume are independent from the size of the volume.",
        },
        SQLServer: {
          title: "RDS SQL Savings",
          description:
            "The “RDS (instance) Optimization - SQL Licensing” value refers to the percent of oversized and/or overprovisioned  RDS instances in the accounts compared to the calculated recommended optimized value. The recommendation is based on an analysis involving the size of the volume provisioned for RDS and other parameters. Hovering the percentage displays the recommendation aggregated by recommended instances. Clicking on the percentage displays current and recommended values of each instances of the AWS account. In addition, AWS normally recommends not to configure the same instance type and SQL server license normally used on production environments, for non production environments. Please take into consideration that these recommendations have only an informative scope, and if there is an actual need to keep the current RDS instance type and SQL server license, feel free to do so.",
        },
      },
    },
    codDashboard: {},
  },
  filters: {
    accountCost: {
      min: {
        chip: {
          name: "Account cost",
          currency: "%{smart_count}",
        },
      },
      max: {
        chip: {
          name: "Account cost",
          currency: "%{smart_count}",
        },
      },
      chip: {
        name: "Account cost",
      },
    },
    cost_center: {
      label: "Cost Center",
      chip: {
        name: "Cost Center",
      },
    },
    missing_cost_center: {
      label: "Show missing Cost Center",
      chip: {
        name: "Displaying",
        false: "Missing Cost Center",
      },
    },
    missing_product: {
      label: "Show missing product",
      chip: {
        name: "Displaying",
        false: "Missing product",
      },
    },
    compliance: {
      label: "Compliance",
      false: "< 100%",
      true: "100%",
      chip: {
        name: "Compliance",
        true: "100%",
        false: "< 100%",
      },
    },
    first_admin: {
      label: "First Admin",
      chip: {
        name: "First Admin",
      },
    },
    owner: {
      label: "Owner",
      chip: {
        name: "Owner",
      },
    },
    is_active: {
      label: {
        false: "Inactive owner",
        null: "No email available",
      },
      chip: {
        name: "Displaying",
        false: "Acounts with inactive owner",
        null: "Accounts with no owner email",
      },
    },
    env_type: {
      label: "Environment type",
      chip: {
        name: "Environment",
      },
    },
    accountUsed: {
      chip: {
        name: "Underutilized Accounts",
      },
    },
    underutilizedEc2: {
      min: {
        chip: {
          name: "Underutilized EC2",
          currency: "%{smart_count}",
        },
      },
      max: {
        chip: {
          name: "Underutilized EC2",
          currency: "%{smart_count}",
        },
      },
      chip: {
        name: "Underutilized EC2",
      },
    },
    gpType: {
      min: {
        chip: {
          name: "GP2 To GP3",
        },
      },
      max: {
        chip: {
          name: "GP2 To GP3",
        },
      },
      chip: {
        name: "GP2 To GP3",
      },
    },
    region: {
      label: "AWS Region",
      chip: {
        name: "AWS Region",
        AMR: "America",
        APAC: "Asia Pacific",
        EU: "Europe",
        CN: "China",
      },
    },
    policies: {
      label: "Hide Columns Without Policies",
      chip: {
        name: "Columns without policies",
        true: "Hide",
        false: "Show",
      },
    },
    product: {
      label: "Product",
      chip: {
        name: "Product",
      },
    },
    os: {
      label: "OS",
      chip: {
        name: "OS",
      },
    },
    account_name: {
      label: "Account name",
      chip: {
        name: "Account name",
      },
    },
    prisma_name: {
      label: "Prisma name",
      chip: {
        name: "Prisma name",
      },
    },
    costReport: {
      label: "Cost Admin Tool",
      chip: {
        name: "Cost Admin Tool",
        installedUsed: "Installed and used",
        installedUnused: "Installed but not used",
        notInstalled: "Not installed",
      },
    },
  },
};

/**
 * reference the tooltips of finops accounts columns, to the finops Owners columns
 */
for (const key in enStrings.accounts.finops) {
  const tooltip = (enStrings.accounts.finops as any)[key].tooltip;
  if (tooltip && !(enStrings.accounts.finopsOwners as any)[key]?.tooltip) {
    (enStrings.accounts.finopsOwners as any)[key] = { tooltip: tooltip };
  }
}

export default enStrings;
