import { Button, DialogActions, Toolbar } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useUpdate, UpdateButton, useNotify } from "react-admin";
import { TUserGroup } from "../../types/default";

function calculateGroupChanges(
  recordGroups: TUserGroup[],
  newRoles: TUserGroup[],
) {
  const addedGroups = newRoles.filter(
    (role: TUserGroup) => !recordGroups.includes(role),
  );
  const removedGroups = recordGroups.filter(
    (group: TUserGroup) => !newRoles.includes(group),
  );

  return {
    added_groups: addedGroups,
    removed_groups: removedGroups,
  };
}

function AdminUsersEditBottomToolbar({
  id,
  onClose,
  newRoles,
  record,
}: UserEditBottomToolbarProps) {
  const { added_groups, removed_groups } = calculateGroupChanges(
    record.groups,
    newRoles,
  );

  const newRequestBody = {
    added_groups: added_groups,
    removed_groups: removed_groups,
  };

  const notify = useNotify();
  const [update] = useUpdate("users", {
    id: id,
    data: newRequestBody,
    previousData: record,
    meta: {
      updateType: "permissions",
      groups: JSON.stringify(newRoles),
    },
  });

  const onSuccess = () => {
    update();
    notify(`Successfully modified roles!`);
    onClose();
  };

  return (
    <Toolbar
      sx={{ justifyContent: "space-between", paddingX: "16px" }}
      disableGutters
    >
      <UpdateButton
        disableElevation
        variant="contained"
        size="medium"
        label="Edit roles"
        color="primary"
        sx={{ color: "white" }}
        data={{ newRequestBody }}
        mutationOptions={{
          meta: {
            queryStringParameters: {
              groups: JSON.stringify(newRoles),
            },
            updateType: "permissions",
          },
          onSuccess,
        }}
        icon={<DoneIcon />}
      />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Toolbar>
  );
}

export default AdminUsersEditBottomToolbar;

type UserEditBottomToolbarProps = {
  id: string | number;
  onClose: () => void;
  newRoles: TUserGroup[];
  record: any;
};
