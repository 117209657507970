import { useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography, Tooltip } from "@mui/material";
import { ReactNode } from "react";

function SideBarHeader(props: {
  title: string;
  description?: string;
  children?: ReactNode;
}) {
  const translate = useTranslate();
  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.2em",
        justifyContent: "space-between",
      }}
    >
      <>
        <Typography variant="h5">{translate(`${props.title}`)}</Typography>
        {props.description && (
          <Tooltip
            title={translate(`${props.description}`)}
            placement="bottom-start"
            arrow
          >
            <InfoOutlinedIcon
              sx={{
                color: "#424243",
                fontSize: 14,
                fontWeight: 600,
                alignItems: "end",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Tooltip>
        )}
      </>
      {props.children}
    </Box>
  );
}

export default SideBarHeader;
