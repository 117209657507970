import {
  EmbeddingContext,
  createEmbeddingContext,
} from "amazon-quicksight-embedding-sdk";
import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Loading } from "react-admin";

const EmbeddedDashboard = ({ dashboardName }: { dashboardName: string }) => {
  const dashboardRef = useRef<HTMLDivElement | null>(null);
  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null);
  const [embeddingContext, setEmbeddingContext] =
    useState<EmbeddingContext | null>(null);

  useEffect(() => {
    const fetchUrl = async (dashboard: string) => {
      setDashboardUrl(null);
      const response = await API.get(
        "TrustAPI",
        `quicksight/dashboards/${dashboard}`,
        {},
      );
      setDashboardUrl(response);
    };
    fetchUrl(dashboardName);
  }, []);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  };

  useEffect(() => {
    if (dashboardUrl) {
      createContext();
    }
  }, [dashboardUrl]);

  useEffect(() => {
    if (embeddingContext) {
      embed();
    }
  }, [embeddingContext]);

  const embed = async () => {
    const options = {
      url: dashboardUrl as string,
      container: dashboardRef.current as HTMLDivElement,
      height: "100%",
      width: "100%",
    };

    await embeddingContext?.embedDashboard(options);
  };

  return (
    <div style={{ minHeight: "80vh" }}>
      {!dashboardUrl ? (
        <Loading />
      ) : (
        <div style={{ height: "80vh" }} ref={dashboardRef} />
      )}
    </div>
  );
};

export default EmbeddedDashboard;
