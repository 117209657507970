import {
  useBasename,
  useTranslate,
  Link,
  useRecordContext,
  FunctionField,
} from "react-admin";
import { Typography, Grid, Box, Tooltip, Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { get } from "lodash";
import { formatCurrency } from "../../../../utils/currency-utils";
import SectionHeaders from "../../../Header/SectionHeader";
import { TFieldInfo } from "../../../../types/default";
import { WHITE_SPACE, generateKey } from "../AccountShow";

const SECTION_HEADERS = [
  "Instance Class",
  "SQL engine",
  "SQL engine Version",
  "Total Allocated Storage",
  "Monthly Cost",
];

const currentFields: TFieldInfo[] = [
  { label: SECTION_HEADERS[0], source: "instance_class" },
  { label: SECTION_HEADERS[1], source: "engine" },
  { label: SECTION_HEADERS[2], source: "engine_version" },
  { label: SECTION_HEADERS[3], source: "allocated_storage" },
  { label: SECTION_HEADERS[4], source: "last_month_cost" },
];

const recommendedFields: TFieldInfo[] = [
  { label: SECTION_HEADERS[0], source: "recommended.instance_class" },
  { label: SECTION_HEADERS[1], source: "recommended.engine" },
  { label: SECTION_HEADERS[2], source: "none" },
  { label: SECTION_HEADERS[3], source: "none" },
  { label: SECTION_HEADERS[4], source: "last_month_estimated_cost" },
];

const FieldColumn = ({
  label,
  fields,
  hasInfo,
}: {
  label: string;
  fields: TFieldInfo[];
  hasInfo: boolean;
}) => {
  const basename = useBasename();
  const translate = useTranslate();
  const appContext = useContext(AppContext);
  const record = useRecordContext();
  return (
    <Grid xs={2} item container direction="column" sx={{ paddingRight: "2%" }}>
      <Box height="2em" display="flex" alignItems="start">
        <Typography variant="body2" fontWeight={600} noWrap>
          {label}:
        </Typography>
        {hasInfo && (
          <Link to={`${basename}/faq#SQLServer`}>
            <Tooltip
              title={translate(`${appContext.entityType}.tooltip`, { _: "" })}
              placement="bottom-start"
              arrow
            >
              <InfoOutlinedIcon
                sx={{
                  margin: "3px",
                  color: "#424243",
                  fontSize: 14,
                  fontWeight: 600,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Tooltip>
          </Link>
        )}
      </Box>
      {fields.map((field, index) => (
        <FunctionField<any>
          key={generateKey("field", index)}
          label={field.label}
          source={field.source}
          sx={{
            color:
              record?.[field.source] !==
                record?.["recommended"]?.[field.source] &&
              record?.["recommended"]?.[field.source] !== undefined
                ? "red"
                : "",
          }}
          render={(record) => {
            const value = get(record, field.source);
            let renderedValue;

            if (field.source === "allocated_storage") {
              renderedValue = `${value} GB`;
            } else if (
              field.source === "last_month_estimated_cost" ||
              field.source === "last_month_cost"
            ) {
              renderedValue = formatCurrency(value);
            } else {
              renderedValue = value !== undefined ? value : "No data";
            }

            if (!value) return WHITE_SPACE;
            return renderedValue;
          }}
        />
      ))}
    </Grid>
  );
};

const RDSAlert = (props: any) => {
  const { record } = props;
  const translate = useTranslate();
  if (!record?.recommended?.instance_class) {
    return (
      <Alert sx={{ marginTop: "1em" }} severity="error">
        {translate(`notify.RDSInstanceNotOptimized`, {
          cost: formatCurrency(record["last_month_cost"]),
        })}
      </Alert>
    );
  }
  return null;
};

export const RDSDetailsPanel = () => {
  const record = useRecordContext();

  return (
    <>
      <Grid container spacing={0}>
        <SectionHeaders sectionHeaders={SECTION_HEADERS} />
        <FieldColumn
          label="Current Settings"
          fields={currentFields}
          hasInfo={false}
        />
        <FieldColumn
          label="Recommended Settings"
          fields={recommendedFields}
          hasInfo={true}
        />
      </Grid>
      <RDSAlert record={record} />
    </>
  );
};
