/**
 * Page Header Component
 *
 * This component renders a header for a page, including a title and optional description.
 * It utilizes context from the AppContext and react-admin's useListContext for dynamic rendering.
 *
 * @param resource - [optional] The resource name for the page.
 * @param title -  [optional] The title to display in the header. If not provided, it constructs the title based on other props.
 * @param descriptionKey -  [optional] The i18n key for the description text, used in conjunction with PageHeaderDescription component.
 */

import { useMemo } from "react";
import { useListContext, Loading } from "react-admin";
import { Typography } from "@mui/material";
import PageHeaderDescription from "./PageHeaderDescription";
import { useLocation } from "react-router-dom";

interface PageHeaderProps {
  resource?: string;
  title?: string;
  descriptionKey?: string;
}

const PageHeader = ({
  resource,
  descriptionKey,
  title = "Placeholder Title",
}: PageHeaderProps) => {
  const { isLoading } = useListContext();
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const uniquePathParts: string[] = Array.from(new Set(pathParts));

  if (
    ["show", "standards"].includes(uniquePathParts[uniquePathParts.length - 1])
  ) {
    uniquePathParts.pop();
  }

  const headerTitle = useMemo(() => {
    if (title) return title;
    return `${resource}`;
  }, [title, resource]);

  if (isLoading) return <Loading />;

  return (
    <div>
      <Typography variant="h1" gutterBottom>
        {headerTitle}
      </Typography>
      {descriptionKey && <PageHeaderDescription i18nKey={descriptionKey} />}
    </div>
  );
};

export default PageHeader;
