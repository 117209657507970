import {
  UserMenu as RaUserMenu,
  Logout,
  UserMenuProps as RaUserMenuProps,
} from "react-admin";
import { MenuList, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

const UserMenu = (props: UserMenuProps) => (
  <>
    <RaUserMenu {...props}>
      <MenuList>
        {props.isAdmin && (
          <MenuItem component={Link} to="/admin">
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        )}
        <Logout redirectTo="/" />
      </MenuList>
    </RaUserMenu>
    <KeyboardArrowDownIcon />
  </>
);

export default UserMenu;

type UserMenuProps = RaUserMenuProps & {
  isAdmin: boolean;
};
