import PageHeader from "../../../Header/PageHeader";
import EmbeddedDashboard from "../../../Quicksight/EmbeddedDashboard";

function AWSMonthlyCostReport() {
  return (
    <div>
      <PageHeader title="AWS Accounts Cost and usage reports" />
      <EmbeddedDashboard dashboardName="QuicksightAWSMonthlyCostReport" />
    </div>
  );
}

export default AWSMonthlyCostReport;
