import { FunctionField } from "react-admin";
import { Typography, Grid, Box, Link } from "@mui/material";
import { get } from "lodash";
import SectionHeaders from "../../../Header/SectionHeader";
import { TFieldInfo } from "../../../../types/default";
import { WHITE_SPACE, generateKey } from "../AccountShow";
import { SxProps } from "@mui/system";

const generateFields = (
  sectionHeaders: string[],
  sources: string[],
  styles: { [key: string]: SxProps }[] = [],
): { label: string; source: string; styles: SxProps | undefined }[] => {
  return sectionHeaders.map((header, index) => {
    const source = sources[index];
    return {
      label: header,
      source,
      styles: styles.find((style) => Object.keys(style)[0] === source)?.[
        source
      ],
    };
  });
};

const FieldColumn = ({
  label,
  fields,
}: {
  label: string;
  fields: TFieldInfo[];
}) => {
  return (
    <Grid
      maxWidth={"fit-content"}
      item
      container
      direction="column"
      sx={{ paddingRight: "2%" }}
      display="flex"
      flex="1"
    >
      <Box height="2em" display="flex" alignItems="start">
        <Typography variant="body2" fontWeight={600} noWrap>
          {label}:
        </Typography>
      </Box>
      {fields.map((field, index) => (
        <FunctionField<any>
          key={generateKey("field", index)}
          label={field.label}
          source={field.source}
          sx={field.styles}
          render={(record) => {
            const value = get(record, field.source);
            let renderedValue = value;
            if (field.source === "resource_url") {
              renderedValue = (
                <Link target="_blank" rel="noreferrer" href={`http://${value}`}>
                  {value}
                </Link>
              );
            }
            if (field.source === "region") {
              const linkConstructor = `https://${value}.console.aws.amazon.com/ec2/home?region=${value}#InstanceDetails:instanceId=${record.id}`;
              renderedValue = (
                <Link target="_blank" rel="noreferrer" href={linkConstructor}>
                  <Typography variant="subtitle1">
                    Link to AWS console
                  </Typography>
                </Link>
              );
            }
            if (!value) return WHITE_SPACE;
            return renderedValue;
          }}
        />
      ))}
    </Grid>
  );
};

export const DetailsPanel = ({
  sectionHeaders,
  fields,
  label,
}: {
  sectionHeaders: string[];
  fields: TFieldInfo[];
  label: string;
}) => {
  return (
    <Grid container spacing={0} display={"flex"} flex={1}>
      <SectionHeaders sectionHeaders={sectionHeaders} />
      <FieldColumn label={label} fields={fields} />
    </Grid>
  );
};

export const S3DetailsPanel = () => {
  const sectionHeaders = [
    "AWS Resource Name",
    "AWS Region",
    "AWS Resource URL",
  ];
  const sources = ["bucket_name", "region_name", "resource_url"];
  const fields = generateFields(sectionHeaders, sources);

  return (
    <DetailsPanel
      sectionHeaders={sectionHeaders}
      fields={fields}
      label="Current Settings"
    />
  );
};

export const EndpointDetailsPanel = () => {
  const sectionHeaders = [
    "Public IP",
    "AWS Resource Name",
    "AWS Region",
    "AWS Resources Linked",
  ];
  const sources = [
    "ip",
    "aws_resource_name",
    "aws_region",
    "aws_resources_linked",
  ];
  const fields = generateFields(sectionHeaders, sources);

  return (
    <DetailsPanel
      sectionHeaders={sectionHeaders}
      fields={fields}
      label="Current Settings"
    />
  );
};

export const EC2DetailsPanel = () => {
  const sectionHeaders = [
    "Instance Name",
    "OS Type",
    "OS Version",
    "Recommended OS Version",
    "",
  ];
  const sources = ["name", "os", "version", "recommended_os", "region"];
  const styles = [{ version: { color: "red" } }];
  const fields = generateFields(sectionHeaders, sources, styles);

  return (
    <DetailsPanel
      sectionHeaders={sectionHeaders}
      fields={fields}
      label="Current Settings"
    />
  );
};
