import { Translate, useTranslate } from "react-admin";
import PageHeader from "../../../Header/PageHeader";
import AccountList from "../AccountList";
import TableField from "../../../Fields/TableField";
import NameField from "../../../Fields/NameField";
import BreakdownContent from "../../../Breakdown/BreakdownContent";
import PercentageField from "../../../Fields/PercentageField";
import {
  ACCOUNT_COST_STANDARD,
  SQL_SERVER_STANDARD,
  UNDERUTILIZED_EBS_STANDARD,
  UNDERUTILIZED_EC2_STANDARD,
} from "../../../../definition/definition";
import SavingField from "../../../Fields/SavingField";
import ListToolbar from "../../../Datagrid/ListToolBar";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import { Typography } from "@mui/material";
import ClickableField from "../../../Fields/ClickableField";
import CostField from "../../../Fields/CostField";

const FinopsOwnerList = () => {
  const translate = useTranslate();
  useSetEntityType("accounts.finopsOwners");
  const preferenceKey = "finopsOwnerList.datagrid";

  return (
    <AccountList
      hasInfo
      resource="finops/owners"
      preferenceKey={preferenceKey}
      storeKey={"finopsOwnerList"}
      sort={{ field: "id", order: "ASC" }}
      rowClick=""
      actions={
        <ListToolbar
          enableSearch={true}
          preferenceKey={preferenceKey}
          searchLabel="Search Owners"
          selectableColumns
        />
      }
      fixedColumns={[
        <ClickableField
          source="id"
          key="source"
          label={`accounts.finopsOwners.displayName`}
          cellClick={(id) =>
            `/rod/accounts?filter=${encodeURIComponent(`{"owner":"${id}"}`)}`
          }
        >
          <NameField
            sx={{ textAlign: "center", display: "inline-block" }}
            source="id"
            label={`accounts.finopsOwners.displayName`}
            sortBy="id"
            subtitle={false}
            tooltipTitle={
              <Typography>
                {translate("accounts.finopsOwners.email.popover.title")}
              </Typography>
            }
          />
        </ClickableField>,
      ]}
      dynamicColumn={(source) => hoverableColumns(source, translate)}
      header={
        <PageHeader
          title="Owners Overview"
          resource="Accounts"
          descriptionKey="accounts.finopsOwners.infoText"
        />
      }
    />
  );
};

const hoverableColumns = (column: string, translate: Translate) => {
  const OwnerBreakdown = (props: any) => {
    const { record } = props;
    let isDollarColumn =
      column === UNDERUTILIZED_EC2_STANDARD ||
      column === UNDERUTILIZED_EBS_STANDARD ||
      column === SQL_SERVER_STANDARD;
    let optimizationThreshold: {
      passed: string;
      failed: string;
    } = {
      passed: "",
      failed: "",
    };

    if (isDollarColumn) {
      optimizationThreshold = {
        passed: "(< $100)",
        failed: "(> $100)",
      };
    }

    const messages = [
      {
        key: translate(`accounts.finopsOwners.popover.passed`, {
          optimizedValues: optimizationThreshold.passed,
        }),
        value: record[column]?.passed,
      },
      {
        key: translate(`accounts.finopsOwners.popover.failed`, {
          optimizedValues: optimizationThreshold.failed,
        }),
        value: record[column]?.failed,
      },
      {
        key: translate(`accounts.finopsOwners.popover.total`),
        value: record[column]?.total,
      },
    ];
    return <BreakdownContent messages={messages} />;
  };
  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block", cursor: "default" }}
      source={column}
      label={`finops.columns.${column}.title`}
      key={column}
      fieldType={(props) => {
        const { record, source, tooltipContent: Content } = props;
        if (column === ACCOUNT_COST_STANDARD) {
          return (
            <TableField
              isLink={false}
              sx={{
                textAlign: "center",
                display: "inline-block",
                cursor: "default",
              }}
              source={column}
              label={`finops.columns.${column}.title`}
              key={column}
              fieldType={CostField}
            />
          );
        }
        if (
          [
            UNDERUTILIZED_EC2_STANDARD,
            UNDERUTILIZED_EBS_STANDARD,
            SQL_SERVER_STANDARD,
          ].includes(source)
        ) {
          return (
            <SavingField
              record={record[source]}
              source={source}
              saving={
                source === SQL_SERVER_STANDARD
                  ? "last_month_total_savings"
                  : "cost_saving_total"
              }
              warning={"warning"}
              {...props}
            />
          );
        }
        return (
          <PercentageField
            source={source}
            tooltipContent={Content}
            isLink={false}
            maxError={32}
            minWarn={33}
            maxWarn={99}
            minSuccess={100}
            record={record}
          />
        );
      }}
      tooltipContent={OwnerBreakdown}
    />
  );
};

export default FinopsOwnerList;
