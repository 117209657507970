import ResmedAppBar from "../../components/AppBar/AppBar";
import { Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import TitleCard from "../../components/Cards/TitleCard";
import dashboards from "../../config/config";
import { Loading, usePermissions } from "react-admin";
import { ReactComponent as SpreadsheetIllustration } from "./spreadsheetIllustration.svg";

const generateCards: any = (permissions: any) =>
  Object.entries(dashboards).map(
    ([_, value]) =>
      permissions.includes(value.app) && (
        <Box sx={{ maxWidth: 350, flexGrow: 1, height: "auto" }}>
          <TitleCard
            title={value.title}
            path={value.basename}
            description={value.description}
            panel={value.app}
          />
        </Box>
      ),
  );

const PlatformLandingPage = () => {
  const { isLoading, permissions } = usePermissions();
  if (isLoading) return <Loading />;
  return (
    <StyledLayout className="layout">
      <div className={LayoutClasses.appFrame}>
        <ResmedAppBar />
        <main className={LayoutClasses.contentWithSidebar}>
          <Box
            sx={{
              minWidth: "fit-content",
              margin: "5em auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"column"}
              alignItems={"center"}
              gap={2}
              marginBottom={3}
            >
              <Box flex={1}>
                <SpreadsheetIllustration height={"20vh"} />
              </Box>
              <Typography variant="h1" textAlign="center">
                Welcome to Trust Portal, <br />
                <b style={{ fontWeight: 500 }}>please pick a dashboard</b>
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                maxWidth: "80vw",
                gap: 3,
                margin: "1em 0 0 0",
              }}
            >
              {generateCards(permissions)}
            </Stack>
          </Box>
        </main>
      </div>
    </StyledLayout>
  );
};

const PREFIX = "AppLayout";
export const LayoutClasses = {
  appFrame: `${PREFIX}-appFrame`,
  contentWithSidebar: `${PREFIX}-contentWithSidebar`,
  content: `${PREFIX}-content`,
};
const StyledLayout = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  position: "relative",
  minWidth: "fit-content",
  width: "100%",
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: "flex",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    [theme.breakpoints.up("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
}));

export default PlatformLandingPage;
