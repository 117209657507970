import { Typography, Stack } from "@mui/material";
import { Translate, useTranslate } from "react-admin";
import { formatCurrency } from "../../../../utils/currency-utils";
import { TChartPoint, TCustomTooltipProps } from "../../../../types/default";
import "./Tooltip.scss";

const TooltipText = ({
  label,
  value,
}: {
  label: string;
  value: string | number | null | undefined;
}) => {
  if (value === undefined) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      <Typography className="label">{`${label}:`}</Typography>
      <Typography fontWeight={600}>{value}</Typography>
    </Stack>
  );
};

const renderTooltip = (
  payload: TChartPoint,
  valueType: string | undefined,
  label: string,
  translate: Translate,
) => {
  if (payload) {
    const { value, date, ratio, total } = payload;
    const formattedValue =
      valueType === "currency" && value !== null
        ? formatCurrency(value)
        : value;

    const ratioRound = ratio ? ratio.toFixed(2) : undefined;

    return (
      <div className="custom-chart-tooltip">
        <Typography variant="subtitle1">{date}</Typography>
        <TooltipText
          label={translate(`finops.charts.tooltip.${label}.value`)}
          value={formattedValue}
        />

        <TooltipText
          label={translate(`finops.charts.tooltip.${label}.total`)}
          value={total}
        />

        <TooltipText
          label={translate(`finops.charts.tooltip.${label}.ratio`)}
          value={ratioRound}
        />
      </div>
    );
  }
  return null;
};

const CustomTooltip = ({
  active,
  payload,
  hoveredDot,
  valueType,
}: TCustomTooltipProps) => {
  const translate = useTranslate();

  if (active && payload && payload.length) {
    const series = payload.find((series) => series.name === hoveredDot);
    if (series?.payload) {
      return renderTooltip(
        series?.payload,
        valueType,
        `${series.name}`,
        translate,
      );
    }
  }
  return null;
};

CustomTooltip.displayName = "CustomTooltip";

export default CustomTooltip;
