import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";

const useSetEntityType = (entity: string) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (entity) {
      appContext.setEntityType(entity);
    }
    return () => appContext.setEntityType(undefined);
  }, [appContext, entity]);
};

export default useSetEntityType;
