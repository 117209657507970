export const isDefined = <T>(val: T | undefined | null): val is T => {
  return val !== undefined && val !== null;
};

export const isString = (val: any): val is string => {
  return typeof val == "string";
};

export const isNumber = (val: any): val is number => {
  return typeof val == "number";
};
