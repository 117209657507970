import { Translate, useTranslate } from "react-admin";
import dayjs from "dayjs";
import { Tooltip, Box, Link } from "@mui/material";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import Breakdown, { BreakdownProps } from "../../../Breakdown/Breakdown";
import DetailButton from "../../../Breakdown/DetailButton";
import ListToolbar from "../../../Datagrid/ListToolBar";
import PageHeader from "../../../Header/PageHeader";
import AccountList from "../AccountList";
import {
  ACCOUNT_USED_STANDARD,
  COST_ADMIN_TOOL_STANDARD,
  SQL_SERVER_STANDARD,
  UNDERUTILIZED_EBS_STANDARD,
  UNDERUTILIZED_EC2_STANDARD,
  GP_TYPE_STANDARD,
  VOLUME_STATE_STANDARD,
  COST_CENTER,
  DATADOG_LINK_MONITORING,
  VANTAGE_SWITCH_LINK,
  ACCOUNT_COST_STANDARD,
} from "../../../../definition/definition";
import TableField from "../../../Fields/TableField";
import SuccessField from "../../../Fields/kpi/SuccessField";
import ErrorField from "../../../Fields/kpi/ErrorField";
import NameField from "../../../Fields/NameField";
import PercentageField from "../../../Fields/PercentageField";
import NAField from "../../../Fields/kpi/NAField";
import ClickableField from "../../../Fields/ClickableField";
import BooleanField from "../../../Fields/BooleanField";
import BreakdownContent from "../../../Breakdown/BreakdownContent";
import SavingField from "../../../Fields/SavingField";
import RegionInput from "../../../Inputs/RegionInput";
import ProductInput, {
  MissingProductCheckboxInput,
} from "../../../Inputs/ProductInput";
import CostField from "../../../Fields/CostField";
import OwnerInput, {
  OwnerInactiveCheckboxInput,
} from "../../../Inputs/OwnerInput";
import WarnField from "../../../Fields/kpi/WarnField";
import { formatCurrency } from "../../../../utils/currency-utils";
import { FilterCategoryProps } from "../../../SideBar/FilterSidebar/FilterSideBar";
import { EnvTypeRadioGroupInput } from "../../../Inputs/EnvTypeRadioGroupInput";
import OptimizedRadioGroupInput from "../../../Inputs/OptimizedRadioGroupInput";
import { NameTooltip } from "../../../Tooltip/NameTooltip";
import CostCenterInput, {
  CostCenterMissingCheckboxInput,
} from "../../../Inputs/CostCenterInput";
import { isDefined } from "../../../../types/type-guards/type-guards";
import AccountUsedRadioGroupInput from "../../../Inputs/AccountUsedRadioGroupInput";
import FirstAdminInput from "../../../Inputs/FirstAdminInput";
import CATRadioGroupInput from "../../../Inputs/CATRadioGroupInput";
import TotalCostSliderInput from "../../../Inputs/TotalCostSliderInput";

const MONTHLY_ESTIMATED_SAVINGS_FIELD = "monthly_estimated_savings";
const MONTHLY_SAVINGS_FIELD = "monthly_savings";
const PERCENTAGE_FIELD = "percentage";

const FinopsAccountList = () => {
  const translate = useTranslate();
  useSetEntityType("accounts.finops");
  const preferenceKey = "accountList.datagrid";
  const filterList: FilterCategoryProps[] = [
    {
      filterHeader: translate("commons.filterCategories.general"),
      filterList: [
        <RegionInput source="region" alwaysOn />,
        <ProductInput
          source="product"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
        <MissingProductCheckboxInput source="missing_product" alwaysOn />,
        <EnvTypeRadioGroupInput source="env_type" alwaysOn />,
      ],
    },
    {
      filterHeader: translate("commons.filterCategories.owner"),
      filterList: [
        <OwnerInput
          source="owner"
          reference="assets/accounts/owners?distinct=true"
          alwaysOn
        />,
        <OwnerInactiveCheckboxInput source="is_active" alwaysOn />,
      ],
    },
    {
      filterHeader: translate("commons.filterCategories.first_admin"),
      filterList: [
        <FirstAdminInput
          source="first_admin"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
      ],
    },
    {
      filterHeader: "Cost Center",
      filterList: [
        <CostCenterInput
          source="cost_center"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
        <CostCenterMissingCheckboxInput
          source="missing_cost_center"
          alwaysOn
        />,
      ],
    },
    {
      filterHeader: "Total Cost",
      filterList: [
        <TotalCostSliderInput
          minMaxResource={`finops/aggregations?aggregation=min,max&attributes=${MONTHLY_SAVINGS_FIELD}&standard=${ACCOUNT_COST_STANDARD}`}
          source={ACCOUNT_COST_STANDARD}
          alwaysOn
          slider={{ ariaLabel: "Total Cost", title: "Total Cost" }}
        />,
      ],
    },
    {
      filterHeader: "Underutilized Accounts",
      filterList: [
        <AccountUsedRadioGroupInput source={ACCOUNT_USED_STANDARD} alwaysOn />,
      ],
    },
    {
      filterHeader: "Underutilized EC2",
      filterList: [
        <OptimizedRadioGroupInput
          minMaxResource={`finops/aggregations?aggregation=min,max&attributes=${MONTHLY_ESTIMATED_SAVINGS_FIELD}&standard=${UNDERUTILIZED_EC2_STANDARD}`}
          source={UNDERUTILIZED_EC2_STANDARD}
          slider={{ ariaLabel: "Underutilized EC2", title: "" }}
          alwaysOn
        />,
      ],
    },
    {
      filterHeader: "GP2 to GP3",
      filterList: [
        <OptimizedRadioGroupInput
          minMaxResource={`finops/aggregations?aggregation=min,max&attributes=${PERCENTAGE_FIELD}&standard=${GP_TYPE_STANDARD}`}
          source={GP_TYPE_STANDARD}
          slider={{ ariaLabel: "GP2 to GP3", title: "" }}
          alwaysOn
        />,
      ],
    },
    {
      filterHeader: "Cost Admin Tool",
      filterList: [
        <CATRadioGroupInput source={COST_ADMIN_TOOL_STANDARD} alwaysOn />,
      ],
    },
  ];

  return (
    <AccountList
      hasInfo
      resource="finops/accounts"
      preferenceKey={preferenceKey}
      actions={
        <ListToolbar
          enableSearch={true}
          searchLabel="accounts.search"
          filterList={filterList}
          preferenceKey={preferenceKey}
          selectableColumns
        />
      }
      fixedColumns={[
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="name"
          label={`accounts.displayName`}
          sortBy="name"
          renderTooltip={(record, active) => (
            <NameTooltip record={record} active={active} />
          )}
        />,
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="product"
          label={`accounts.finops.product.displayName`}
          sortBy="product"
          subtitle={false}
        />,
      ]}
      dynamicColumn={(source) => dynamicColumns(source, translate)}
      rowClick=""
      header={
        <PageHeader
          title="Accounts Overview"
          resource="Accounts"
          descriptionKey="accounts.finops.infoText"
        />
      }
    />
  );
};

const dynamicColumns = (column: string, translate: Translate) => {
  const FinopsBreakdown = (props: BreakdownProps) => {
    const { record, source } = props;
    const translate = useTranslate();
    var policyName: string;
    policyName = translate(`finops.columns.${column}.policyName`);

    if (source == ACCOUNT_COST_STANDARD) {
      return (
        <BreakdownContent
          messages={[
            {
              key: translate(`accounts.finops.${source}.popover.title`),
            },
            {
              key: `Please make sure you are logged into the ResMed workspace in Vantage to visualize this report`,
              value: <Link href={VANTAGE_SWITCH_LINK}>Switch here</Link>,
              styling: { variant: "subtitle1" },
            },
          ]}
          detailButton={
            <Link
              href={`https://console.vantage.sh/reports/rprt_7148668de5157b62?adhoc_filters[aws][account_id][]=${record.id}&report[include_discounts]=false&report[include_tax]=false&report[amortize]=false&report[include_credits]=false&date_interval=last_month&report[include_refunds]=false`}
              rel="noreferrer"
              target="_blank"
              data-dd-action-name={DATADOG_LINK_MONITORING.VANTAGE}
            >
              Open Vantage for more details.
            </Link>
          }
        />
      );
    }

    if (column === GP_TYPE_STANDARD || column === VOLUME_STATE_STANDARD) {
      return (
        <Breakdown
          {...props}
          source={column}
          detailButton={
            <DetailButton
              policy={policyName}
              service="prismaId"
              standard={column}
            />
          }
        />
      );
    }

    return (
      <Breakdown
        {...props}
        source={column}
        detailButton={<DetailButton policy={policyName} service="prismaId" />}
      />
    );
  };

  if (column === ACCOUNT_USED_STANDARD) {
    return (
      <TableField
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`finops.columns.${column}.title`}
        key={column}
        isLink={false}
        fieldType={(props) => {
          //TODO: Extract field type
          const { record, tooltipContent: Content } = props;
          const accountUsed = record[ACCOUNT_USED_STANDARD];
          const timestamp = accountUsed?.source_timestamp;
          let valueComponent = null;

          if (!accountUsed) {
            return (
              <Box sx={{ padding: 1 }}>
                <NAField />
              </Box>
            );
          }

          if (accountUsed?.provisioned_resources === false) {
            valueComponent = (
              <ErrorField isLink={false} value={"No resources in account"} />
            );
          } else if (
            (!isDefined(accountUsed?.cicd_access) &&
              accountUsed.human_access === false) ||
            (!isDefined(accountUsed?.human_access) &&
              accountUsed.cicd_access === false) ||
            !timestamp
          ) {
            valueComponent = (
              <WarnField value="Not enough data" isLink={false} />
            );
          }

          if (timestamp) {
            const diff = dayjs(dayjs(), "YYYY-MM-DDTHH:mm:ss").diff(
              timestamp,
              "day",
            );
            if (diff > 60) {
              valueComponent = (
                <ErrorField
                  isLink={false}
                  value={`> ${translate("commons.day", { smart_count: 60 })}`}
                />
              );
            } else {
              valueComponent = (
                <SuccessField
                  isLink={false}
                  value={translate("commons.day", { smart_count: diff })}
                />
              );
            }
          }
          return (
            <Tooltip
              classes={{ tooltip: "kpiPopover" }}
              title={<Content {...props} record={record} />}
            >
              <Box sx={{ padding: 1 }}>{valueComponent}</Box>
            </Tooltip>
          );
        }}
        tooltipContent={FinopsBreakdown}
      />
    );
  }

  if (column === COST_ADMIN_TOOL_STANDARD) {
    return (
      <TableField
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`finops.columns.${column}.title`}
        key={column}
        isLink={false}
        fieldType={(props) => {
          //TODO: Extract field type
          const { record, tooltipContent: Content } = props;
          const costReport = props.record[COST_ADMIN_TOOL_STANDARD] || {};

          if (Object.keys(costReport).length === 0) {
            return (
              <Box sx={{ padding: 1 }}>
                <NAField />
              </Box>
            );
          }

          if (
            costReport?.percentage > 0 &&
            (costReport.monthly_savings === 0 ||
              costReport.monthly_savings === null)
          ) {
            return (
              <Tooltip
                classes={{ tooltip: "kpiPopover" }}
                title={<Content {...props} record={record} />}
              >
                <Box sx={{ padding: 1 }}>
                  <WarnField isLink={false} value={"No savings"} />
                </Box>
              </Tooltip>
            );
          }

          return (
            <Tooltip
              classes={{ tooltip: "kpiPopover" }}
              title={<Content {...props} record={record} />}
            >
              <Box>
                <BooleanField
                  {...props}
                  source={"installed"}
                  record={{
                    ...costReport,
                    installed: costReport?.percentage > 0,
                  }}
                  successLabel={formatCurrency(costReport.monthly_savings)}
                  errorLabel="Not installed"
                />
              </Box>
            </Tooltip>
          );
        }}
        tooltipContent={FinopsBreakdown}
      />
    );
  }

  if (
    column === UNDERUTILIZED_EC2_STANDARD ||
    column === UNDERUTILIZED_EBS_STANDARD
  ) {
    return (
      <TableField
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`finops.columns.${column}.title`}
        key={column}
        isLink={false}
        fieldType={(props) => (
          <SavingField saving={"monthly_estimated_savings"} {...props} />
        )}
        tooltipContent={({ record }) => {
          const message = [];
          const resource_type =
            column === UNDERUTILIZED_EC2_STANDARD ? "EC2" : "EBS";
          if (record[column].total === 0) {
            message.push({
              key: `No underutilized ${resource_type} found`,
              styling: { marginTop: true },
            });
          } else {
            message.push({
              key: `N° of ${resource_type} instances:`,
              styling: { sx: { fontWeight: "600" }, marginTop: true },
            });
            message.push({
              key: "Underutilized in the last 14 days",
              value: record[column].failed,
            });
            message.push({
              key: "Total",
              value: record[column].total,
            });
            message.push({
              key: "Estimated savings",
              value: formatCurrency(record[column].monthly_estimated_savings),
            });
          }
          return (
            <BreakdownContent
              messages={message}
              detailButton={
                <>
                  <Link
                    href="https://us-east-1.console.aws.amazon.com/trustedadvisor/home?region=eu-central-1#/category/cost-optimizing"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Open the AWS Trust advisor for the account
                  </Link>
                </>
              }
            />
          );
        }}
      />
    );
  }

  if (column == SQL_SERVER_STANDARD) {
    return (
      <ClickableField
        label={`finops.columns.${column}.title`}
        source={column}
        key={column}
        cellClick={(id) => `${encodeURIComponent(id)}/rds`}
      >
        <TableField
          sx={{ textAlign: "center", display: "inline-block" }}
          source={column}
          label={`finops.columns.${column}.title`}
          key={column}
          isLink={true}
          fieldType={(props) => (
            <SavingField
              {...props}
              saving={"last_month_total_savings"}
              warning={"warning"}
            />
          )}
          tooltipContent={FinopsBreakdown}
        />
      </ClickableField>
    );
  }

  if (column === ACCOUNT_COST_STANDARD) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block", cursor: "default" }}
        source={column}
        label={`finops.columns.${column}.title`}
        key={column}
        fieldType={(props) => <CostField {...props} field="monthly_savings" />}
        tooltipContent={FinopsBreakdown}
      />
    );
  }

  if (column === COST_CENTER) {
    return (
      <NameField
        sx={{ textAlign: "center", display: "inline-block", cursor: "default" }}
        source={column}
        label={`finops.columns.${column}.title`}
        sortBy={column}
        subtitle={false}
      />
    );
  }
  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block", cursor: "default" }}
      source={column}
      label={`finops.columns.${column}.title`}
      key={column}
      fieldType={PercentageField}
      tooltipContent={FinopsBreakdown}
    />
  );
};
export default FinopsAccountList;
