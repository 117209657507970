import { RadioButtonGroupInput, RadioButtonGroupInputProps } from "react-admin";
import { FiltersCommonInputProps } from "../../../types/default";

interface CustomRadioGroupInputProps {
  minMaxResource?: string;
}

export type RadioGroupInputProps = RadioButtonGroupInputProps &
  FiltersCommonInputProps &
  CustomRadioGroupInputProps;

const RadioGroupInput = (props: RadioGroupInputProps) => {
  return (
    <RadioButtonGroupInput
      sx={{
        // flex div containing the radios
        [`& .MuiFormGroup-root`]: {
          rowGap: 0.6,
        },
        // legend
        [`& .RaRadioButtonGroupInput-label`]: {
          fontSize: (theme) => theme.typography.h3,
          marginBottom: 1,
        },
        // button of each radio
        [`& .MuiRadio-root`]: {
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        // ripples when clicking on button
        [`& .MuiTouchRipple-root`]: {
          display: "none",
        },
      }}
      row={false}
      alwaysOn={true}
      {...props}
    />
  );
};

export default RadioGroupInput;
