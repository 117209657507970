import { Link } from "react-admin";
import ErrorIcon from "@mui/icons-material/Report";
import History from "@mui/icons-material/History";
import HomeIcon from "@mui/icons-material/Home";
import { Typography, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Stack
      direction={"column"}
      sx={{ textAlign: "center", marginTop: "5%" }}
      gap={2}
    >
      <Typography variant="h1" sx={{ gap: 2 }}>
        <ErrorIcon />
        Error
      </Typography>
      <Typography variant="body1">
        Unable to render page, please send feedback to Trust Engineering team.
        <p></p>
        If the issue persists, consider clearing cache.
      </Typography>
      <Stack direction={"row"} sx={{ marginX: "auto", gap: 2 }}>
        <Button startIcon={<History />} onClick={handleGoBack}>
          Go Back
        </Button>
        <Link to="/">
          <Button startIcon={<HomeIcon />}>Go Home</Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;
