const LegendIcon = ({ fillColor }: { fillColor: string | undefined }) => {
  return (
    <svg
      width="36"
      height="12"
      viewBox="0 0 36 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.083 5H0V6H11C11 9.31371 13.6863 12 17 12C20.3137 12 23 9.31371 23 6H36V5H22.917C22.441 2.16229 19.973 0 17 0C14.027 0 11.559 2.16229 11.083 5ZM15.2676 5C15.6134 4.4022 16.2597 4 17 4C17.7403 4 18.3866 4.4022 18.7324 5C18.9026 5.29417 19 5.63571 19 6C19 7.10457 18.1046 8 17 8C15.8954 8 15 7.10457 15 6C15 5.63571 15.0974 5.29417 15.2676 5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default LegendIcon;
