import { useEffect, useState } from "react";
import { SelectInput, SelectInputProps, useTranslate } from "react-admin";
import { FiltersCommonInputProps, TRegion } from "../../types/default";
import { dataProvider } from "../../providers/dataProvider/dataProvider";

const RegionInput = (props: SelectInputProps & FiltersCommonInputProps) => {
  const translate = useTranslate();
  const [region, setRegion] = useState<TRegion[]>();

  useEffect(() => {
    dataProvider
      .getList("assets/regions", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "id", order: "DESC" },
        filter: {},
      })
      .then((regionResponse) => {
        setRegion(regionResponse.data);
      });
  }, []);

  if (region === undefined) {
    return null;
  }

  return (
    <SelectInput
      {...props}
      key="region"
      label={translate(`filters.region.label`)}
      emptyText="Global"
      choices={region.filter((x: TRegion) => x.id !== "")}
      fullWidth={true}
    />
  );
};

export default RegionInput;
