import { CardContentProps, CardProps } from "./DashboardCard";
import CardContentProgressChart from "../Charts/CustomComponents/SPD/LandingPageProgressChart";
import CardContentPieChart from "../Charts/CustomComponents/SPD/LandingPagePieChart";

function DashboardCardCharts(props: CardContentProps & CardProps) {
  const { value, chart, resource } = props;
  return (
    <div>
      {chart === "pie" && (
        <CardContentPieChart data={value} resource={resource} />
      )}
      {chart === "progress" && (
        <CardContentProgressChart
          data={value}
          yKey={"percentage"}
          xKey={"name"}
        />
      )}
    </div>
  );
}

export default DashboardCardCharts;
