import ErrorIcon from "@mui/icons-material/Error";
import Field from "../Field";

const WarnField = ({ value, isLink }: { value: string; isLink: boolean }) => {
  return (
    <Field isLink={isLink} value={value} Icon={<ErrorIcon color="warning" />} />
  );
};

export default WarnField;
