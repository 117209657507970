import { Fragment, useState } from "react";
import {
  ListBase,
  Loading,
  WithListContext,
  useNotify,
  useTranslate,
} from "react-admin";
import {
  LineChart,
  XAxis,
  Legend,
  Tooltip,
  YAxis,
  Line,
  ResponsiveContainer,
} from "recharts";
import CustomLegend from "./Legend";
import CustomTooltip from "./Tooltip/Tooltip";
import {
  TCustomChartProps,
  TChartSeries,
  TChartPoint,
} from "../../../types/default";
import ListToolbar from "../../Datagrid/ListToolBar";
import { FilterCategoryProps } from "../../SideBar/FilterSidebar/FilterSideBar";
import AccountNameInput from "../../Inputs/AccountNameInput";
import OwnerInput from "../../Inputs/OwnerInput";
import { handleFetchError } from "../../../errors/ErrorHandling";
import { formatCurrency } from "../../../utils/currency-utils";

const COLOR_PALETTE = [
  "#003790",
  "#4063A4",
  "#6593AF",
  "#83CD28",
  "#FFA59E",
  "#DD4C65",
  "#93003A",
];

/**
 * Fill missing chart points with null values to have coherent chart behavior
 * @param data
 * @returns {TChartSeries[]} data with missing points set
 */
export const fillMissingData = (data: TChartSeries[]) => {
  const max_series: TChartSeries = data.reduce((acc, current) =>
    current.data.length > acc.data.length ? current : acc,
  );
  const dates: string[] = max_series?.data.map(
    (item: TChartPoint) => item?.date,
  );
  data.map((series) => {
    const uniqueDates = new Set([
      ...dates,
      ...series.data.map((point) => point.date),
    ]);
    const uniqueDatesArr: string[] = Array.from(uniqueDates);
    const datesSorted = uniqueDatesArr.sort(
      (a, b) => Date.parse(a) - Date.parse(b),
    );
    const points = datesSorted.map((date) => {
      return (
        series.data.find((point: TChartPoint) => point.date === date) || {
          date,
          value: null,
        }
      );
    });
    series.data = points;
    return series;
  });
  return data;
};

const CustomChart = (props: TCustomChartProps) => {
  const {
    resource,
    XAxisProps,
    customLineProps,
    highlightedChart,
    legendOrder,
    chartAlias,
    preferenceKey,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const [hoveredDot, setHoveredDot] = useState<string | null>(null);
  const [clickedLegendItems, setClickedLegendItems] = useState<string[] | null>(
    null,
  );

  const filterList: FilterCategoryProps[] = [
    {
      filterHeader: translate("commons.filterCategories.account_name"),
      filterList: [
        <AccountNameInput
          source="prisma_name"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
      ],
    },
    {
      filterHeader: translate("commons.filterCategories.owner"),
      filterList: [
        <OwnerInput
          source="owner"
          reference="assets/accounts/owners?distinct=true"
          alwaysOn
        />,
      ],
    },
  ];

  return (
    <ListBase resource={resource} disableSyncWithLocation>
      <ListToolbar
        enableSearch={false}
        preferenceKey={preferenceKey}
        filterList={filterList}
      />
      <WithListContext
        render={({ data, isLoading, isFetching, error }) => {
          if (isLoading || isFetching) {
            return <Loading />;
          }

          if (error) {
            notify(handleFetchError(error, translate));
            return null;
          }

          return (
            <ResponsiveContainer width="100%" height={600}>
              <LineChart margin={{ left: 100, right: 100, top: 50 }}>
                <XAxis {...XAxisProps} />
                <Legend
                  content={
                    <CustomLegend
                      payload={data}
                      clickedLegendItems={clickedLegendItems}
                      setClickedLegendItems={setClickedLegendItems}
                      order={legendOrder}
                      chartAlias={chartAlias}
                    />
                  }
                />
                <Tooltip
                  content={({ payload, active }) => (
                    <CustomTooltip
                      active={active}
                      payload={payload}
                      hoveredDot={hoveredDot}
                      valueType={
                        data.find((dataset) => dataset?.name === hoveredDot)
                          ?.valueType
                      }
                    />
                  )}
                />
                {data.map((dataset, i) => {
                  return (
                    <Fragment key={dataset.id}>
                      <YAxis
                        yAxisId={dataset.id}
                        orientation="left"
                        hide={
                          !(
                            clickedLegendItems?.length === 1 &&
                            clickedLegendItems?.includes(dataset.id.toString())
                          )
                        }
                        tickFormatter={(value) => formatCurrency(value, "")}
                      />
                      <Line
                        {...customLineProps}
                        connectNulls
                        name={dataset.name}
                        data={dataset.data}
                        type="monotone"
                        stroke={COLOR_PALETTE[i]}
                        yAxisId={dataset.id}
                        hide={
                          clickedLegendItems?.includes(dataset.id.toString()) ||
                          clickedLegendItems === null
                            ? false
                            : true
                        }
                        dot={{
                          ...(typeof customLineProps.dot === "object"
                            ? { ...customLineProps.dot }
                            : {}),
                          fill: COLOR_PALETTE[i],
                        }}
                        activeDot={{
                          r: hoveredDot === dataset.name ? 9 : 6,
                          onMouseOver: () => {
                            setHoveredDot(dataset.name);
                          },
                          onMouseOut: () => {
                            setHoveredDot(null);
                          },
                        }}
                        strokeWidth={dataset.id === highlightedChart ? 3 : 1}
                      />
                    </Fragment>
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          );
        }}
      />
    </ListBase>
  );
};

export default CustomChart;
