// Stores next token of each query for pagination
import { isString } from "../../types/type-guards/type-guards";

// This is refreshed on each reload
interface Tokens {
  // Query signature
  [index: string]: Array<string>; // Array of next tokens by page
}

type JSONValue = string | number | boolean | JSONObject | Array<JSONValue>;

interface JSONObject {
  [x: string]: JSONValue;
}

export class Pagination {
  static tokens: Tokens = {};

  static getNextToken(
    querySignature: string,
    page: number,
  ): string | undefined {
    // Initialize the array of tokens
    if (!this.tokens[querySignature]) {
      this.tokens[querySignature] = [];
    }

    return this.tokens[querySignature][page - 1];
  }

  static saveNextToken(
    nextToken: JSONObject | string | null | undefined,
    querySignature: string,
    page: number,
  ): void {
    // Initialize the array of tokens
    if (!this.tokens[querySignature]) {
      this.tokens[querySignature] = [];
    }

    if (nextToken === undefined) {
      /* when nextToken attribute is not in response (RDS storage) 
      'false' value is saved */
      this.tokens[querySignature][page] = "false";
      return;
    }

    /* nextToken can be either an object {"pk": {"S": "9970ab78-2b6f-4864-b7af-752ddd745e3f"}},
    a boolean or a string "9970ab78-2b6f-4864-b7af-752ddd745e3f" depending on the caller */
    this.tokens[querySignature][page] = isString(nextToken)
      ? nextToken
      : JSON.stringify(nextToken);
  }
}
