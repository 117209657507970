import { PieChart, Pie, Cell, Tooltip, Label, TooltipProps } from "recharts";
import { Stack, Typography, Box } from "@mui/material";
import { useTranslate } from "react-admin";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { TChartPoint, TCustomTooltipProps } from "../../../../types/default";
import { DataItem } from "../../../Cards/DashboardCard";

const color = (dataKey: string) => {
  if (dataKey === "passed") {
    return "#4F9C3A";
  }
  if (dataKey === "failed") {
    return "#BC0F1F";
  }
};

export const CardContentPieChart = ({
  data,
  resource,
}: {
  data: DataItem[];
  resource: string | undefined;
}) => {
  const dataNoTotal = data.filter((item: DataItem) => item.name !== "total");
  const failedValue = data.find((item: DataItem) => item.name === "failed");
  function CustomLabel({
    topValue,
    trendValue,
  }: {
    topValue: string | number | undefined;
    trendValue: string;
  }) {
    return (
      <>
        <text
          x="75"
          y="75"
          fill="#3d405c"
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan alignmentBaseline="middle" fontSize="15" fontWeight="600">
            {topValue}%
          </tspan>
        </text>
        <text
          x="75"
          y="95"
          fill="green"
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize="12">{trendValue}</tspan>
        </text>
      </>
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PieChart width={150} height={150}>
        <Pie
          data={dataNoTotal}
          innerRadius={50}
          outerRadius={70}
          fill="#CFCFCF"
          paddingAngle={1}
          dataKey="value"
        >
          {dataNoTotal?.map((entry: DataItem) => (
            <Cell key={entry.name} fill={color(entry.name)} />
          ))}
          <Label
            content={
              <CustomLabel topValue={failedValue?.percentage} trendValue="" />
            }
          />
        </Pie>
        <Tooltip
          content={<PieChartTooltip payload={data} resource={resource} />}
        />
      </PieChart>
    </div>
  );
};

const PieChartTooltip = ({
  payload,
  resource,
}: TCustomTooltipProps & TooltipProps<ValueType, NameType>) => {
  const payloadData = payload ? payload[0] : "";
  const translate = useTranslate();
  const renderPayload = (
    payload:
      | {
          name?: string | number | undefined;
          payload?: TChartPoint;
          value?: string | number;
        }[]
      | undefined,
  ) => {
    if (payload && payload.length > 0) {
      return (
        <Stack direction="column">
          <Typography fontSize="11px" fontWeight="700" color="#00243D">
            {translate(`accounts.security.${resource}.title`)}:
          </Typography>
          <Stack direction="column">
            {payloadData && (
              <Stack direction="row" gap={0.5}>
                <Typography fontSize="11px" color="#00243D" fontWeight="600">
                  {translate(
                    `accounts.security.${resource}.chart.${payloadData.name}`,
                  )}
                  :
                </Typography>
                <Typography fontSize="11px" color="#00243D">
                  {payloadData?.value}{" "}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    }
  };

  return (
    <Box className="custom-chart-tooltip" sx={{ borderRadius: "5px" }}>
      <Stack direction="row" spacing={1}>
        {renderPayload(payload)}
      </Stack>
    </Box>
  );
};

export default CardContentPieChart;
