import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box, Divider } from "@mui/material";
import { DashboardCard } from "../Cards/DashboardCard";
import AdminIngestionOverview from "../Admin/AdminIngestionOverview";
import TitleCard from "../Cards/TitleCard";
import { Link, useTranslate } from "react-admin";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  OUTDATED_OS_EC2,
  PRISMA_ALERTS_STANDARD,
  PUBLIC_S3,
  UNPATCHED_EC2_STANDARD,
  EXPOSED_ENDPOINT_STANDARD,
} from "../../definition/definition";
import RODLandingPage from "./ROD/RODLandingPage";

export const rodDashboard = () => {
  return <RODLandingPage />;
};

export const adminDashboard = () => {
  return (
    <Box>
      <Typography variant="h1">Admin Dashboard</Typography>
      <Stack direction="row">
        <AdminIngestionOverview />
      </Stack>
      <Stack direction="row" gap="1em" marginTop="1em">
        <TitleCard title="User Management" path="users" />
        <TitleCard title="Ingestion Monitoring" path="ingestions" />
        <TitleCard title="FAQ Management" path="faq" />
      </Stack>
    </Box>
  );
};

export const SPDDashboard = () => {
  const translate = useTranslate();
  return (
    <Box marginRight={"1.5em"}>
      <Typography variant="h1" marginBottom={"1em"}>
        {translate("nav.spdDashboard.dashboardTitle")}
      </Typography>
      <Stack direction="column" gap={1} sx={{}}>
        <Link
          to={"security-gaps"}
          sx={{ display: "flex", alignSelf: "flex-start" }}
        >
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 600,
              color: "primary.main",
              display: "flex",
              alignItems: "center",
            }}
            variant="link"
          >
            Go to Account Overview
            <ArrowForwardIcon />
          </Typography>
        </Link>
        <Stack
          direction="row"
          gap={4}
          sx={{
            padding: "2em",
            borderRadius: "25px",
            justifyContent: "space-around",
            flexWrap: "wrap",
            width: "100%",
            boxShadow: "0px 2px 32.6px 4px rgba(0, 0, 0, 0.07)",
          }}
        >
          <DashboardCard resource={OUTDATED_OS_EC2} chart="progress" />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DashboardCard
            resource={PRISMA_ALERTS_STANDARD}
            onlyDisplay={"failed"}
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DashboardCard resource={EXPOSED_ENDPOINT_STANDARD} chart="pie" />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DashboardCard resource={PUBLIC_S3} chart="pie" />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DashboardCard resource={UNPATCHED_EC2_STANDARD} chart="pie" />
        </Stack>
      </Stack>
    </Box>
  );
};
