import {
  TAccount,
  TService,
  TProduct,
  BreakdownMessages,
} from "../../types/default";
import {
  COST_ADMIN_TOOL_STANDARD,
  OUTDATED_OS_EC2,
  PRISMA_ALERTS_STANDARD,
  SQL_SERVER_STANDARD,
} from "../../definition/definition";
import CATBreakdown from "./CATBreakdown";
import SQLServerBreakdown from "./SQLServerBreakdown";
import DefaultBreakdown from "./DefaultBreakdown";
import { ACCOUNT_USED_STANDARD } from "../../definition/definition";
import UsedAccountBreakdown from "./UsedAccountBreakdown";
import BreakdownContent from "./BreakdownContent";
import { useTranslate } from "react-admin";
import { isDefined, isNumber } from "../../types/type-guards/type-guards";
import { formatCurrency } from "../../utils/currency-utils";

const Breakdown = (props: BreakdownProps) => {
  const translate = useTranslate();
  const { record, source, detailButton } = props;

  const timestamp = record[source].source_timestamp!;
  const timestampFormatted = timestamp
    ? new Date(timestamp).toDateString()
    : "More than 60 days";

  if (source === COST_ADMIN_TOOL_STANDARD) {
    return <CATBreakdown record={record} source={source} />;
  }

  if (source === SQL_SERVER_STANDARD) {
    return (
      <SQLServerBreakdown
        record={record}
        source={source}
        detailButton={detailButton}
      />
    );
  }

  if (source === ACCOUNT_USED_STANDARD) {
    return (
      <UsedAccountBreakdown
        record={record}
        source={ACCOUNT_USED_STANDARD}
        timestamp={timestampFormatted}
      />
    );
  }

  if (source === OUTDATED_OS_EC2) {
    return (
      <BreakdownContent
        messages={[
          isNumber(record?.els_cost)
            ? {
                key: translate(`accounts.security.${source}.popover.els`),
                value: formatCurrency(record.els_cost),
              }
            : undefined,
          {
            key: translate(`accounts.security.${source}.popover.failed`),
            value: record[source].failed,
          },
          {
            key: translate(`accounts.security.${source}.popover.total`),
            value: record[source].total,
          },
        ].filter(isDefined)}
      />
    );
  }

  if (source === PRISMA_ALERTS_STANDARD) {
    const sourceRecord = record?.[source];
    if (sourceRecord != null) {
      return (
        <BreakdownContent
          messages={[
            {
              key: translate(
                `accounts.security.${source}.popover.highAlertsTotal`,
              ),
              value: sourceRecord?.highAlertsTotal,
            },
            {
              key: translate(
                `accounts.security.${source}.popover.highAlertsOver1Month`,
              ),
              value: sourceRecord?.highAlertsOver1Month,
            },
            {
              key: translate(
                `accounts.security.${source}.popover.highAlertsOver6Months`,
              ),
              value: sourceRecord?.highAlertsOver6Months,
            },
            {
              key: translate(
                `accounts.security.${source}.popover.criticalAlertsTotal`,
              ),
              value: sourceRecord?.criticalAlertsTotal,
            },
            {
              key: translate(
                `accounts.security.${source}.popover.criticalAlertsOver1Month`,
              ),
              value: sourceRecord?.criticalAlertsOver1Month,
            },
            {
              key: translate(
                `accounts.security.${source}.popover.criticalAlertsOver6Months`,
              ),
              value: sourceRecord?.criticalAlertsOver6Months,
            },
          ].filter((item) => isDefined(item?.value))}
        />
      );
    }
  }

  return (
    <DefaultBreakdown
      record={record}
      detailButton={detailButton}
      source={source}
    />
  );
};

export interface BreakdownProps {
  record: TAccount | TService | TProduct;
  source: string;
  detailButton?: JSX.Element;
  additionalMessages?: BreakdownMessages[];
}

export default Breakdown;
