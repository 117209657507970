import React from "react";
import { Box, Button } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const FilterButton = (props: FilterButtonProps) => {
  const { setIsOpen, setAnchorEl: setFilterButtonRef } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterButtonRef(event.currentTarget);
    setIsOpen((previousState) => {
      return !previousState;
    });
  };

  return (
    <Box>
      <Button
        startIcon={<FilterListIcon />}
        onClick={handleClick}
        variant="outlined"
        sx={{ height: "40px" }}
      >
        Filter
      </Button>
    </Box>
  );
};

interface FilterButtonProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: (anchorEl: EventTarget & HTMLButtonElement) => void;
}

export default FilterButton;
