import CancelIcon from "@mui/icons-material/Cancel";
import Field from "../Field";

const ErrorField = ({ value, isLink }: { value?: string; isLink: boolean }) => {
  return (
    <Field isLink={isLink} value={value} Icon={<CancelIcon color="error" />} />
  );
};

export default ErrorField;
