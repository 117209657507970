import React, { useEffect } from "react";
import { useListContext } from "react-admin";
import { Grid, Slider, TextField, Typography } from "@mui/material";
import { isDefined } from "../../../types/type-guards/type-guards";
import {
  getMaxFilterRange,
  getMinFilterRange,
} from "../../Datagrid/Actions/RangeFilterHelper";

const SIZE = "small";
const STEP = 1;

export interface SliderInputProps {
  ariaLabel: string;
  /* an optional props to be ARIA compliant */
  ariaValueLabelDisplay?: (value: number) => string;
  max: number;
  min: number;
  source: string;
  step?: number;
  title: string;
}

const isValidInput = (
  value: string | undefined,
  min: number,
  max: number,
): boolean =>
  value && parseFloat(value) >= min && parseFloat(value) <= max ? true : false;

export const SliderInput = ({
  ariaLabel,
  ariaValueLabelDisplay,
  max,
  min,
  source,
  step,
  title,
}: SliderInputProps) => {
  const { setFilters, filterValues, displayedFilters } = useListContext();
  const defaultValues = [
    isDefined(filterValues[getMinFilterRange(source)])
      ? parseFloat(filterValues[getMinFilterRange(source)])
      : min,
    isDefined(filterValues[getMaxFilterRange(source)])
      ? parseFloat(filterValues[getMaxFilterRange(source)])
      : max,
  ];
  const [value, setValue] = React.useState<number[]>([
    defaultValues[0],
    defaultValues[1],
  ]);

  const handleInputChange = (currentMin?: string, currentMax?: string) => {
    if (
      isValidInput(currentMin, min, max) ||
      isValidInput(currentMax, min, max)
    ) {
      setValue((previousState) => {
        if (isDefined(currentMin) && currentMin !== `${previousState[0]}`) {
          setFilters(
            { ...filterValues, [getMinFilterRange(source)]: currentMin },
            displayedFilters,
          );
          return [parseFloat(currentMin), previousState[1]];
        }
        if (isDefined(currentMax) && currentMax !== `${previousState[1]}`) {
          setFilters(
            { ...filterValues, [getMaxFilterRange(source)]: currentMax },
            displayedFilters,
          );
          return [previousState[0], parseFloat(currentMax)];
        }
        return previousState;
      });
    }
  };

  useEffect(() => {
    setValue(defaultValues);
  }, [defaultValues[0], defaultValues[1]]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          id={`slider-input-min-${source}`}
          inputProps={{ "data-testid": `slider-input-min-${source}` }}
          type="number"
          size={SIZE}
          value={value[0]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event.target.value)
          }
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 12,
            color: "#c4c4c4",
            marginTop: "1em",
          }}
        >
          {" "}
          —{" "}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          id={`slider-input-max-${source}`}
          inputProps={{ "data-testid": `slider-input-max-${source}` }}
          type="number"
          size={SIZE}
          value={value[1]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(undefined, event.target.value)
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
      >
        <Slider
          id={`slider-progress-${source}`}
          data-testid={`slider-progress-${source}`}
          {...(ariaValueLabelDisplay
            ? { getAriaValueText: ariaValueLabelDisplay }
            : {})}
          getAriaLabel={() => ariaLabel}
          max={max}
          min={min}
          onChangeCommitted={(_, value) => {
            value = value as number[];
            value = value.sort((a, b) => a - b);
            handleInputChange(`${value[0]}`, `${value[1]}`);
          }}
          step={step || STEP}
          value={value}
          valueLabelDisplay="auto"
        />
      </Grid>
    </Grid>
  );
};
