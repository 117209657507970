import { useContext } from "react";
import { useTranslate } from "react-admin";
import { AppContext } from "../../context/AppContext";
import BreakdownContent from "./BreakdownContent";
import { BreakdownProps } from "./Breakdown";
import { isDefined } from "../../types/type-guards/type-guards";

const DefaultBreakdown = (props: BreakdownProps) => {
  const { record, source, detailButton } = props;

  const translate = useTranslate();
  const appContext = useContext(AppContext);
  const defaultMessages = [
    {
      key: translate(`${appContext.entityType}.${source}.popover.title`, {
        _: "Breakdown",
      }),
      styling: { sx: { fontWeight: "600" } },
    },
    {
      key: translate(`${appContext.entityType}.popover.passed`, {
        _: translate(`${appContext.entityType}.${source}.popover.passed`),
      }),
      value: record[source].passed,
    },
    {
      key: translate(`${appContext.entityType}.popover.failed`, {
        _: translate(`${appContext.entityType}.${source}.popover.failed`),
      }),
      value: record[source].failed,
    },
    {
      key: translate(`${appContext.entityType}.popover.total`, {
        _: translate(`${appContext.entityType}.${source}.popover.total`),
      }),
      value: record[source].total,
    },
    record[source].notApplicable
      ? {
          key: translate(`${appContext.entityType}.popover.notApplicable`),
          value: record[source].notApplicable,
        }
      : undefined,
  ].filter(isDefined);

  return (
    <BreakdownContent messages={defaultMessages} detailButton={detailButton} />
  );
};

export default DefaultBreakdown;
