import {
  ListContextProvider,
  Loading,
  Pagination,
  useListController,
  useResourceContext,
  useResourceDefinitions,
  useTranslate,
} from "react-admin";
import { TAccount, TProduct } from "../../types/default";
import Datagrid, { DatagridProps } from "../Datagrid/Datagrid";
import EmptyGrid from "../Datagrid/EmptyGrid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ListLayout = (props: ListLayoutProps) => {
  const {
    resource,
    storeKey,
    params,
    actions,
    filterDefaultValues,
    sort = { field: "name", order: "ASC" },
    ...rest
  } = props;
  const mergedFilterDefaultValues = { ...params, ...filterDefaultValues };
  const controllerProps = useListController<TAccount | TProduct>({
    resource: resource,
    disableAuthentication: true,
    storeKey: storeKey,
    filterDefaultValues: mergedFilterDefaultValues,
    queryOptions: { refetchOnWindowFocus: false },
    sort,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const resourceDefinitions = useResourceDefinitions();
  const resourceContext = useResourceContext();
  const maintenanceMode =
    resourceDefinitions[resourceContext]?.options?.maintenanceMode;

  useEffect(() => {
    maintenanceMode && navigate("/maintenance");
  }, [resourceContext]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setOpen(queryParams.get("showAlert") === "true");
  }, [location.search]);

  const handleClose = () => {
    setOpen(false);
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("showAlert");
    navigate(`?${queryParams.toString()}`);
  };
  return (
    <ListContextProvider value={controllerProps}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please wait!"}</DialogTitle>
        <DialogContent sx={{ padding: "2em" }}>
          <DialogContentText id="alert-dialog-description">
            {translate(`commons.prismaDialog`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Got it</Button>
        </DialogActions>
      </Dialog>
      {actions && actions}
      {controllerProps.isLoading || controllerProps.isFetching ? (
        <Loading />
      ) : (
        <>
          <Datagrid {...rest} />
          <Pagination limit={<EmptyGrid message="No data available" />} />
        </>
      )}
    </ListContextProvider>
  );
};

export interface ListLayoutProps extends DatagridProps {
  resource: string;
  storeKey?: string;
  params?: { [key: string]: string };
  actions?: JSX.Element;
  hideColumns?: boolean;
  filterDefaultValues?: { [key: string]: string };
  queryOptions?: any;
  sort?: { field: string; order: "ASC" | "DESC" };
}

export default ListLayout;
