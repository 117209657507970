import { Route } from "react-router-dom";
import PlatformLandingPage from "./pages/Landing/PlatformLandingPage";
import dashboards from "./config/config";
import ErrorPage from "./errors/ErrorPage";
import MaintenancePage from "./errors/MaintenancePage";

const AccessDeniedSite = () => {
  window.location.href = "/access_denied.html";
  return <></>;
};

export const Routes = () => {
  let routes = [
    <Route key="dashboard" path="/" element={<PlatformLandingPage />} />,
    <Route key="error" path="/error" element={<ErrorPage />} />,
    <Route
      key="maintenance"
      path="/maintenance"
      element={<MaintenancePage />}
    />,
  ].concat(Object.entries(dashboards).map(([_, value]) => value.baseRoute));
  routes.push(
    <Route key="denied" path="/access_denied" element={<AccessDeniedSite />} />,
  );
  return routes;
};
