import { Stack } from "@mui/material";
import { Identifier, Loading, useGetIdentity } from "react-admin";
import { TypographyProps } from "@mui/material/Typography";
import TopList from "./LandingPageComponents/TopList";
import ProgressionChart from "./LandingPageComponents/ProgressionChart";
import Intro from "./LandingPageComponents/Intro";
import { isDefined } from "../../../types/type-guards/type-guards";
import { LaunchLinkField } from "../../Fields/LaunchLinkField";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const cardHeaderStyle: TypographyProps = {
  variant: "h3",
  marginBottom: 3,
  color: "primary.main",
};

function RODLandingPage() {
  // prevent identity rerender with useState
  const [identityId, setIdentityId] = useState<Identifier | undefined>();
  const { identity } = useGetIdentity();
  const id = identity?.id;
  const firstName = identity?.firstName;
  // allow to override landing page user based on a search parameter
  const location = useLocation();
  const user = new URLSearchParams(location.search).get("user");

  useEffect(() => {
    if (id) setIdentityId(user || id);
  }, [id, user]);

  if (!isDefined(id)) {
    return <Loading />;
  }

  return (
    <div>
      <Stack direction="column" gap={3}>
        <Intro identityId={identityId} firstName={firstName} />
        <Stack direction="row" gap={3}>
          <ProgressionChart
            resource={`users/${identityId}`}
            id={"trends"}
            description={true}
          />
          <TopList
            description={true}
            identifier={"topOptimization"}
            resource={`users/${identityId}/accounts/savings`}
            renderFields={() => (
              <LaunchLinkField
                source="account_id"
                format="filteredOnID"
                sortable={false}
              />
            )}
          />
        </Stack>
        <TopList
          identifier={"topUsecases"}
          resource={`users/${identityId}/savings`}
          tabs={true}
          description={true}
          hiddenColumns={["total_cost"]}
        />
      </Stack>
    </div>
  );
}

export default RODLandingPage;
