import {
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
  LabelList,
} from "recharts";
import { Stack, Typography, Box } from "@mui/material";
import { useTranslate } from "react-admin";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { OUTDATED_OS_EC2 } from "../../../../definition/definition";
import { Props } from "recharts/types/component/Label";

type DataItem = {
  name: string;
  [key: string]: any;
};

type DataItemKey = keyof DataItem;

interface ProgressBarProps {
  data: DataItem[];
  yKey: DataItemKey;
  xKey: DataItemKey;
}
const BLUES: string[][] = [
  ["#457AA6"],
  ["#457AA6", "#E3EBF2"],
  ["#264F73", "#457AA6", "#E3EBF2"],
  ["#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"],
  ["#1A334A", "#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"],
];
const ProgressChart = ({ data, yKey, xKey }: ProgressBarProps) => {
  const translate = useTranslate();

  const getColor = (length: number, index: number): string => {
    return length <= BLUES.length
      ? BLUES[length - 1][index]
      : BLUES[BLUES.length - 1][index % BLUES.length];
  };

  const ProgressChartTooltip = ({
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    const payloadData = payload?.[0]?.payload || "";
    if (!payload || payload.length === 0) return null;

    return (
      <Box className="custom-chart-tooltip" sx={{ borderRadius: "5px" }}>
        <Stack direction="row" spacing={1}>
          <Stack direction="column">
            <Typography fontSize="11px" fontWeight="700" color="#00243D">
              {payloadData?.name === OUTDATED_OS_EC2
                ? "All"
                : translate(`accounts.security.${payloadData?.name}.title`)}
            </Typography>

            <Stack direction="column">
              <Stack direction="row" gap={0.5}>
                <Typography fontSize="11px" color="#00243D" fontWeight="600">
                  {translate(
                    `accounts.security.${payloadData?.name}.chart.passed`,
                  )}
                </Typography>
                <Typography fontSize="11px" color="#00243D">
                  {payloadData?.passed}{" "}
                  {payloadData?.percentage
                    ? `(${payloadData?.percentage}%)`
                    : ""}
                </Typography>
              </Stack>
              <Stack direction="row" gap={0.5}>
                <Typography fontSize="11px" color="#BC0F1F" fontWeight="600">
                  {translate(
                    `accounts.security.${payloadData?.name}.chart.failed`,
                  )}
                </Typography>
                <Typography fontSize="11px" color="#BC0F1F">
                  {payloadData?.failed}{" "}
                  {payloadData?.percentage
                    ? `(${100 - payloadData?.percentage}%)`
                    : ""}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  };
  const LabelContent = (props: Props) => {
    const { name, y, offset } = props;
    const newY = y && offset ? Number.parseFloat(y.toString()) - offset : y;
    return (
      <>
        <text
          x={395}
          y={newY}
          textAnchor="end"
          fontSize="12px"
          fontWeight="600"
        >
          {name === OUTDATED_OS_EC2
            ? "All"
            : translate(`accounts.security.${name}.title`)}
        </text>
      </>
    );
  };
  return (
    <ResponsiveContainer width={400} height={60 * data.length} debounce={50}>
      <BarChart data={data} layout="vertical" barGap={20}>
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          yAxisId={0}
          hide
          axisLine={false}
          tickLine={false}
          dataKey={xKey}
          type="category"
        />
        <Tooltip cursor={false} content={<ProgressChartTooltip />} />
        <Bar
          dataKey={yKey}
          minPointSize={2}
          barSize={20}
          background={{ fill: "#eee", radius: 5 }}
          radius={[5, 0, 0, 5]}
        >
          <LabelList dataKey={xKey} content={LabelContent} />
          {data.map((d, idx) => (
            <>
              <Cell key={d[xKey]} fill={getColor(data.length, idx)} />
            </>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ProgressChart;
