import polyglotI18nProvider from "ra-i18n-polyglot";
import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";
import enStrings from "./CustomStrings/enStrings";

const en = {
  ...englishMessages,
  ...enStrings,
};

const translations: { [key: string]: TranslationMessages } = { en };
export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "en", // default locale
  [{ locale: "en", name: "English" }],
  { allowMissing: true },
);
