import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Field from "../Field";

const SuccessField = ({
  value,
  isLink,
}: {
  value?: string;
  isLink: boolean;
}) => {
  return (
    <Field
      isLink={isLink}
      value={value}
      Icon={<CheckCircleIcon color="success" />}
    />
  );
};

export default SuccessField;
