import { styled } from "@mui/material/styles";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useTranslate } from "ra-core";
import { useSidebarState } from "react-admin";
import { useEffect } from "react";

/**
 * A button that toggles the <Sidebar /> or the <FilterSideBar /> .
 * Used by default in the <AppBar>.
 * @param props The component props
 * @param {String} props.className An optional class name to apply to the button
 * @param {Boolean} props.isFilterSidebarOpen sidebar state (open/closed)
 * @param {function} props.filterSidebarClose function to close the <FilterSideBar />
 */
export const ResmedSidebarToggleButton = (props: SidebarToggleButtonProps) => {
  const { className, isFilterSidebarOpen, filterSidebarClose } = props;

  const translate = useTranslate();
  const [isOpenMenuSidebar, setIsOpenMenuSidebar] = useSidebarState();

  useEffect(() => {
    if (isFilterSidebarOpen) setIsOpenMenuSidebar(true);
  }, [isFilterSidebarOpen]);

  const toggleSidebar = () => {
    /**
     * when the filter sidebar is open, the menu sidebar will be open too (behind the filterSidebar).
     * but the inverse is not necessarely true.
     * when clicking on close button and the filterSidebar is open, it will close, and menu sidebar
     * will stay open.
     */
    if (isFilterSidebarOpen === true) {
      if (filterSidebarClose) filterSidebarClose();
      return;
    }
    setIsOpenMenuSidebar(!isOpenMenuSidebar);
  };

  return (
    <Tooltip
      className={className}
      title={translate(
        isOpenMenuSidebar ? "ra.action.close_menu" : "ra.action.open_menu",
        {
          _: "Open/Close menu",
        },
      )}
      enterDelay={500}
    >
      <StyledIconButton color="primary" onClick={toggleSidebar}>
        {!isOpenMenuSidebar && (
          <ArrowForwardIos
            fontSize="small"
            classes={{
              root: isOpenMenuSidebar
                ? SidebarToggleButtonClasses.menuButtonIconOpen
                : SidebarToggleButtonClasses.menuButtonIconClosed,
            }}
          />
        )}
        {isOpenMenuSidebar && (
          <ArrowBackIos
            sx={{ paddingLeft: "5px" }}
            fontSize="medium"
            classes={{
              root: isOpenMenuSidebar
                ? SidebarToggleButtonClasses.menuButtonIconClosed
                : SidebarToggleButtonClasses.menuButtonIconOpen,
            }}
          />
        )}
      </StyledIconButton>
    </Tooltip>
  );
};

export type SidebarToggleButtonProps = {
  className?: string;
  isFilterSidebarOpen?: boolean;
  filterSidebarClose?: () => void;
};

const PREFIX = "RaSidebarToggleButton";

export const SidebarToggleButtonClasses = {
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
};

const StyledIconButton = styled(IconButton, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${SidebarToggleButtonClasses.menuButtonIconClosed}`]: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },

  [`& .${SidebarToggleButtonClasses.menuButtonIconOpen}`]: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
}));
