import { useContext, useEffect, useState } from "react";
import { AuthContext, Loading, ThemeProvider } from "react-admin";
import { Routes as RRDRoutes, useNavigate } from "react-router-dom";
import "./App.css";
import { CognitoAuthProvider } from "./providers/authProvider/AuthProvider";
import { Routes } from "./routes";
import { QueryClientProvider, QueryClient } from "react-query";
import { AppContext } from "./context/AppContext";
import { API } from "aws-amplify";
import { resmedTheme } from "./styles/theme";

const App = () => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const authProvider = CognitoAuthProvider({
    clientId: `${process.env.REACT_APP_WEB_CLIENT_ID}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
    hostedUIUrl: `${process.env.REACT_APP_USER_POOL_DOMAIN}`,
    identityProvider: "okta-oidc",
    mode: "oauth",
  });

  useEffect(() => {
    if ("/auth-callback" !== window.location.pathname) {
      authProvider
        .checkAuth({})
        .then(() => {
          setIsLogged(true);
        })
        .catch(() => {});
    }
    if (
      "/auth-callback" === window.location.pathname &&
      !isLogged &&
      authProvider.handleCallback
    ) {
      authProvider.handleCallback().then((url: string) => navigate(url));
    }
  }, [authProvider, isLogged, navigate]);

  useEffect(() => {
    async function fetchBaseData() {
      try {
        const latestResponse = await API.get(
          "TrustAPI",
          "analytics/ingestions/latest",
          {
            queryStringParameters: {
              attributes: "timestamp", // OPTIONAL
            },
          },
        );
        appContext.setLastIngestionTimestamp(latestResponse.timestamp);
      } catch (error) {
        navigate("/error");
      } finally {
        appContext.setReady(true);
      }
    }
    if (isLogged && !appContext.ready) {
      fetchBaseData();
    }
  }, [isLogged, appContext]);

  if (!isLogged || !appContext.ready) return <Loading />;

  return (
    <AuthContext.Provider value={authProvider}>
      <QueryClientProvider client={new QueryClient()}>
        <ThemeProvider theme={resmedTheme}>
          <RRDRoutes>{Routes()}</RRDRoutes>
        </ThemeProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};

export default App;
