import { CustomRoutes, Resource, TextField, useBasename } from "react-admin";
import { ResourceProps } from "../../types/default";
import { Navigate, Route } from "react-router-dom";
import FaqPage from "../../components/Faq/FaqPage";
import { SPD_DASHBOARD } from "../../definition/definition";
import SecurityAccountList from "../../components/Lists/Accounts/SPD/SecurityAccountList";
import {
  S3DetailsPanel,
  EC2DetailsPanel,
  EndpointDetailsPanel,
} from "../../components/Lists/Accounts/SPD/SecurityAccountShow";
import AccountShow from "../../components/Lists/Accounts/AccountShow";
import SecurityOwnerList from "../../components/Lists/Accounts/SPD/SecurityOwnerList";

export const Resources = (props: ResourceProps) => {
  const { translate } = props;
  const basename = useBasename();

  return [
    <Resource
      name="security-gaps"
      options={{
        label: translate("nav.spdDashboard.security-gaps"),
      }}
      list={<SecurityAccountList />}
    >
      <Route
        path=":id/s3"
        element={
          <AccountShow
            entityType={"accounts.security.publicS3"}
            descriptionKey={"accounts.security.publicS3.infoText"}
            baseResource={"security/accounts"}
            detailRessource="s3"
            panel={<S3DetailsPanel />}
            columns={[
              <TextField
                label="Resource Name"
                source="bucket_name"
                sortable={false}
              />,
            ]}
          />
        }
      />
      <Route
        path=":id/endpoint"
        element={
          <AccountShow
            entityType={"accounts.security.exposedEndpoint"}
            descriptionKey={"accounts.security.exposedEndpoint.infoText"}
            baseResource={"security/accounts"}
            detailRessource="endpoint"
            panel={<EndpointDetailsPanel />}
            columns={[
              <TextField
                label="Resource Name"
                source="aws_resource_name"
                sortable={false}
              />,
            ]}
          />
        }
      />
      <Route
        path=":id/os"
        element={
          <AccountShow
            entityType={"accounts.security.outdatedOSEC2"}
            descriptionKey={"accounts.security.outdatedOSEC2.infoText"}
            baseResource={"security/accounts"}
            detailRessource="ec2"
            panel={<EC2DetailsPanel />}
            columns={[
              <TextField
                label="Instance Name"
                source="name"
                sortable={false}
              />,
            ]}
          />
        }
      />
      <Route path=":id" element={<Navigate to={`/${basename}/accounts`} />} />
    </Resource>,
    <Resource
      key="owners"
      name="owners"
      options={{
        label: translate("nav.spdDashboard.owner"),
        fetchTotal: false,
      }}
      list={SecurityOwnerList}
    />,
    <CustomRoutes>
      <Route path="/faq" element={<FaqPage dashboard={SPD_DASHBOARD} />} />
    </CustomRoutes>,
  ];
};
