import { memo, useMemo } from "react";
import { Typography, Stack, ListItem } from "@mui/material";
import { useTranslate } from "react-admin";
import LegendIcon from "./LegendIcon";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { TCustomLegendItem, TCustomLegendProps } from "../../../types/default";

const LegendItem = ({
  entry,
  setClickedLegendItems,
  clickedLegendItems,
  chartAlias,
}: TCustomLegendItem) => {
  const translate = useTranslate();
  const legendText = translate(
    `finops.columns.${entry.value}.legend.${chartAlias}`,
    {
      _: translate(`finops.columns.${entry.value}.title`),
    },
  );
  return (
    <ListItem
      key={entry.value}
      onClick={() => {
        const updatedClickedLegendItems = clickedLegendItems?.includes(
          entry.value,
        )
          ? clickedLegendItems.filter((item) => item !== entry.value)
          : [...(clickedLegendItems || []), entry.value.toString()];
        setClickedLegendItems(
          updatedClickedLegendItems.length > 0
            ? updatedClickedLegendItems
            : null,
        );
      }}
      sx={{ cursor: "pointer" }}
    >
      <LegendIcon
        fillColor={
          clickedLegendItems === null ||
          clickedLegendItems?.includes(entry.value)
            ? entry.color
            : "#C5C5C5"
        }
      />
      <Typography
        paddingLeft={1}
        variant="subtitle1"
        color={
          clickedLegendItems === null ||
          clickedLegendItems?.includes(entry.value)
            ? "black"
            : "#C5C5C5"
        }
      >
        {legendText}
      </Typography>
    </ListItem>
  );
};

const CustomLegend = memo(
  ({
    payload,
    clickedLegendItems,
    setClickedLegendItems,
    order,
    chartAlias,
  }: TCustomLegendProps) => {
    const filteredPayload = payload as Payload[];
    const neutralItems = useMemo(
      () =>
        filteredPayload.filter(
          (entry) =>
            entry.value &&
            (order.neutral?.includes(entry.value) || order.neutral === "all"),
        ),
      [filteredPayload, order.neutral],
    );

    const higherIsBetterItems = useMemo(
      () =>
        filteredPayload.filter(
          (entry) =>
            entry.value &&
            (order.higherIsBetter?.includes(entry.value) ||
              order.higherIsBetter === "all"),
        ),
      [filteredPayload, order.higherIsBetter],
    );

    const filteredItems = useMemo(
      () =>
        filteredPayload.filter(
          (entry) =>
            entry.value &&
            !order.neutral?.includes(entry.value) &&
            !order.higherIsBetter?.includes(entry.value),
        ),
      [filteredPayload, order.neutral, order.higherIsBetter],
    );

    return (
      <>
        <Typography variant="h3" paddingY={1}>
          Legend
        </Typography>
        <Stack direction="row" spacing={2}>
          <Stack direction="column">
            <Typography variant="subtitle1">
              Click on legend to highlight a line.
              {neutralItems.map((entry) => (
                <LegendItem
                  key={entry.value}
                  entry={entry}
                  setClickedLegendItems={setClickedLegendItems}
                  clickedLegendItems={clickedLegendItems}
                  chartAlias={chartAlias}
                />
              ))}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle1">Higher is better</Typography>
            {higherIsBetterItems.map((entry) => (
              <LegendItem
                key={entry.value}
                entry={entry}
                setClickedLegendItems={setClickedLegendItems}
                clickedLegendItems={clickedLegendItems}
                chartAlias={chartAlias}
              />
            ))}
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle1">Lower is better</Typography>
            {filteredItems.map((entry) => (
              <LegendItem
                key={entry.value}
                entry={entry}
                setClickedLegendItems={setClickedLegendItems}
                clickedLegendItems={clickedLegendItems}
                chartAlias={chartAlias}
              />
            ))}
          </Stack>
        </Stack>
      </>
    );
  },
);

CustomLegend.displayName = "CustomLegend";

export default CustomLegend;
