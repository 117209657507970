import {
  ShowContextProvider,
  useShowController,
  DatagridHeader,
  useTranslate,
  Loading,
} from "react-admin";
import { redirect, useParams } from "react-router-dom";
import useSetEntityType from "../../../hooks/useSetEntityType";
import PageHeader from "../../Header/PageHeader";
import { ListBase, Datagrid } from "react-admin";

interface AccountShowProps {
  entityType: string;
  descriptionKey: string;
  baseResource: string;
  detailRessource: string;
  panel: JSX.Element;
  columns: JSX.Element[];
}

export const WHITE_SPACE = "\u00a0";
export const generateKey = (prefix: string, index: number) =>
  `${prefix}-${index}`;

const AccountShow = (props: AccountShowProps) => {
  const {
    entityType,
    descriptionKey,
    baseResource,
    panel,
    columns,
    detailRessource,
  } = props;
  const { id } = useParams();
  const translate = useTranslate();
  const { record, isLoading } = useShowController({
    resource: `assets/accounts`,
    id: id,
    disableAuthentication: true,
  });
  useSetEntityType(entityType);

  if (isLoading) return <Loading />;
  if (id) redirect("/accounts");
  return (
    <ShowContextProvider value={record}>
      <PageHeader
        resource={"Accounts"}
        title={translate(`${record.name}`)}
        descriptionKey={descriptionKey}
      />
      <ListBase
        resource={`${baseResource}/${encodeURIComponent(
          id as string,
        )}/${detailRessource}`}
      >
        <Datagrid
          header={<DatagridHeader />}
          bulkActionButtons={false}
          rowClick="expand"
          expand={panel}
          sx={{
            "& .column-instance_name": { width: "20%" },
          }}
        >
          {columns.map((column) => column)}
        </Datagrid>
      </ListBase>
    </ShowContextProvider>
  );
};

export default AccountShow;
