export interface RadioValuesType {
  value: string;
  valLabel: string;
}

export type FormType = FormObjectType[];

export interface FormObjectType {
  id: string;
  description?: string;
  question: string;
  values: RadioValuesType[];
  tooltip: React.ReactNode | string;
}

const RiskAssessmentContent: FormType = [
  {
    id: "availability",
    description: "Customer Experience and Security of the Change",
    question:
      "Does the change have customer facing visibility or is it a component on which the availability rely on ?",
    values: [
      { value: "6", valLabel: "Yes" },
      { value: "4", valLabel: "No" },
    ],
    tooltip:
      "Answer Yes if the change affects Customer Experience because of a visual change of a feature or product interface or if it's implemented on a component that is mandatory for the product to be available and functional",
  },
  {
    id: "scope",
    description: "Customer Experience",
    question:
      "What is the number of customer in the scope the change ? (For Airview application change only) Or How many users would be impacted by unsuccessful change ?",
    values: [
      { value: "-1", valLabel: "Impact on few customer" },
      { value: "+1", valLabel: "Impact on many of customer" },
    ],
    tooltip:
      "Only select 'impact on few customer' if the change affects (positively or negatively) a handful of customer, during the change and after the change",
  },
  {
    id: "difficulty",
    description: "Complexity and Control of the Change",
    question: "What is the level of difficulty of the change ?",
    values: [
      { value: "-1", valLabel: "Easy" },
      { value: "0", valLabel: "Medium" },
      { value: "+2", valLabel: "Complex" },
    ],
    tooltip:
      "The level of difficulty is the result of several factors: the need of coordination with other teams or people, the number of steps to implement the change and the way it is automated or not, the time/date of the change",
  },
  {
    id: "architecture",
    description: "Architecture impacted?",
    question: "Does the change affect the architecture ?",
    values: [
      { value: "0", valLabel: "No" },
      { value: "+2", valLabel: "Yes" },
    ],
    tooltip:
      '"Architecture" is heard as network/infrastructure/components architecture. I.e. when PCF or MongoDB was added, architecture was changed',
  },
  {
    id: "security_state",
    description: "Security of the Change",
    question: "Does the change lower the state of security ?",
    values: [
      { value: "0", valLabel: "No" },
      { value: "+3", valLabel: "Yes" },
    ],
    tooltip:
      "State of security is heard as the state guaranteed by any security means like firewall, network segregation, access rights. I.e. when the F5 has been given a network interface to PT, we bypassed firewall, so state of security has been affected",
  },
  {
    id: "test_protocol",
    description: "Control of the Change",
    question:
      "Does the test protocol defined in the change matches the requirement of change management process ?",
    values: [
      { value: "-1", valLabel: "Yes" },
      { value: "+1", valLabel: "No" },
    ],
    tooltip: (
      <div>
        Test protocol requirement is defined{" "}
        <a
          href="https://confluence.ec2.local/display/IT/EU+-+Change+management#EUChangemanagement-UsageofTestenvironments"
          style={{ color: "white" }}
        >
          here
        </a>
      </div>
    ),
  },
  {
    id: "rollback",
    question:
      "Is there a clear, tested and validated Rollback protocol for the change ?",
    values: [
      { value: "-1", valLabel: "Yes" },
      { value: "+1", valLabel: "No" },
    ],
    tooltip: (
      <div>
        <strong>"Clear":</strong> are steps defined to execute it without
        re-think it?
        <br />
        <strong>"Tested"</strong>: was the rollback process tested in another
        environment or for a previous similar change?
        <br />
        <strong>"Validated"</strong>: when tested, was the rollback successfully
        applied?
      </div>
    ),
  },
];

export default RiskAssessmentContent;
