import { ReferenceInputProps, useTranslate } from "react-admin";
import { FiltersCommonInputProps } from "../../types/default";
import AutoCompleteInput from "./common/AutoCompleteInput";
import CheckboxInput, { CheckboxInputProps } from "./common/CheckboxInput";

const PRODUCT_FILTER_KEY = "PRODUCT_FILTER";
const ProductInput = (props: ReferenceInputProps & FiltersCommonInputProps) => {
  const translate = useTranslate();

  return (
    <AutoCompleteInput
      {...props}
      label={translate("filters.product.label")}
      key={PRODUCT_FILTER_KEY}
      attributes_to_get="product"
      search_attribute="product"
    />
  );
};

export const MissingProductCheckboxInput = (props: CheckboxInputProps) => {
  const translate = useTranslate();
  const { label = translate("filters.missing_product.label"), ...rest } = props;
  return (
    <CheckboxInput
      label={label}
      sx={{ mt: 1 }}
      valueOnCheck="false"
      valueOnUnCheck={undefined}
      {...rest}
    />
  );
};

export default ProductInput;
