import { Stack, Typography, Box, Tab, Tabs, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Datagrid,
  FunctionField,
  List,
  Loading,
  TextField,
  useGetList,
  useNotify,
  useTranslate,
} from "react-admin";
import { useState, SyntheticEvent, useEffect } from "react";
import { cardHeaderStyle } from "../RODLandingPage";
import { RODTopListProps, TRODTopList } from "../../../../types/default";
import { formatCurrency } from "../../../../utils/currency-utils";
import { handleFetchError } from "../../../../errors/ErrorHandling";
import { isDefined } from "../../../../types/type-guards/type-guards";
import { NO_VALUE } from "../../../Fields/kpi/NAField";

const DEFAULT_TAB_INDEX = 0;
const TopList = ({
  resource,
  tabs,
  hiddenColumns,
  identifier,
  description,
  renderFields,
}: RODTopListProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [tabIndex, setTabIndex] = useState<number>(DEFAULT_TAB_INDEX);
  const [tabData, setTabData] = useState<TRODTopList[]>([]);
  const { data, error, isLoading } = useGetList(resource, {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "id", order: "ASC" },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setTabData(data[DEFAULT_TAB_INDEX].data);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Box className="customBoxShadow" minWidth="50%" maxWidth="100%">
        <Loading />
      </Box>
    );
  }

  if (error) {
    notify(handleFetchError(error, translate));
    return null;
  }

  const userType = data?.[0].user_type;

  const handleChange = (_: SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex);
    setTabData(data ? data[tabIndex].data : []);
  };

  return (
    <Box className="customBoxShadow" minWidth="50%" maxWidth="100%">
      <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
        <Typography {...cardHeaderStyle}>
          {translate(
            `accounts.finops.dashboard.${userType}.${identifier}.title`,
          )}
        </Typography>
        {description && (
          <Tooltip
            title={translate(
              `accounts.finops.dashboard.${userType}.${identifier}.tooltip`,
              { _: "" },
            )}
            placement="bottom-start"
            arrow
          >
            <InfoOutlinedIcon
              sx={{
                fontSize: 20,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Stack
        direction="row"
        display={tabs ? "flex" : "block"}
        style={{ flex: 1 }}
      >
        {tabs && (
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", display: "block" }}
          >
            {data && (
              <Tabs
                orientation="vertical"
                value={tabIndex}
                onChange={handleChange}
              >
                {data.map((item, index) => {
                  return (
                    <Tab
                      label={
                        <div style={{ textAlign: "left" }}>
                          <Typography variant="tableHeader" color="inherit">
                            {translate(
                              `accounts.finops.dashboard.${item.code}`,
                              { _: item.code },
                            )}
                          </Typography>
                          <Typography variant="subtitle1">
                            {isDefined(item.total_savings)
                              ? formatCurrency(item.total_savings)
                              : NO_VALUE}
                          </Typography>
                        </div>
                      }
                      key={index}
                      sx={{
                        alignItems: "start",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                      }}
                    />
                  );
                })}
              </Tabs>
            )}
          </Box>
        )}
        <List
          resource={resource}
          actions={false}
          pagination={false}
          className={`landing-page-list ${tabs ? "tabs" : ""}`}
          sx={{ width: tabs ? "100%" : "auto" }}
        >
          <Datagrid
            data={tabData}
            bulkActionButtons={false}
            sx={{
              "& .RaDatagrid-rowOdd": { backgroundColor: "white" },
              "& .RaDatagrid-headerCell:first-of-type": {
                borderTopLeftRadius: "10px",
              },
              "& .RaDatagrid-headerCell:last-of-type": {
                borderTopRightRadius: "10px",
              },
            }}
            size="medium"
          >
            <TextField
              source="account_name"
              sortable={false}
              label="Account Name"
            />
            <FunctionField
              sortable={false}
              label="Potential savings"
              render={({
                potential_savings,
              }: {
                potential_savings?: number;
              }) =>
                isDefined(potential_savings)
                  ? formatCurrency(potential_savings)
                  : NO_VALUE
              }
            />
            {!hiddenColumns?.includes("total_cost") && (
              <FunctionField
                sortable={false}
                label="Total cost"
                render={({ total_cost }: { total_cost?: number }) =>
                  isDefined(total_cost) ? formatCurrency(total_cost) : NO_VALUE
                }
              />
            )}
            {renderFields && renderFields()}
          </Datagrid>
        </List>
      </Stack>
    </Box>
  );
};

export default TopList;
