import type { PropsWithChildren } from "react";
import { createContext, useMemo, useState } from "react";

const defaultAppContext = {
  version: undefined,
  setVersion: () => undefined,
  ready: false,
  setReady: () => false,
  entityType: undefined,
  setEntityType: () => undefined,
  app: undefined,
  setApp: () => undefined,
  lastIngestionTimestamp: undefined,
  setLastIngestionTimestamp: () => undefined,
  ingestionId: undefined,
  setIngestionId: () => undefined,
};

function useProviderValue() {
  const [app, setApp] = useState<string | undefined>(undefined);
  const [version, setVersion] = useState<string | undefined>(undefined);
  const [ready, setReady] = useState<boolean>(false);
  const [entityType, setEntityType] = useState<string | undefined>(undefined);
  const [lastIngestionTimestamp, setLastIngestionTimestamp] = useState<
    string | undefined
  >(undefined);
  const [ingestionId, setIngestionId] = useState<string | undefined>(undefined);
  return useMemo(
    () => ({
      app,
      setApp,
      ready,
      setReady,
      version,
      setVersion,
      lastIngestionTimestamp,
      setLastIngestionTimestamp,
      entityType,
      setEntityType,
      ingestionId,
      setIngestionId,
    }),
    [
      lastIngestionTimestamp,
      setLastIngestionTimestamp,
      ready,
      setReady,
      version,
      setVersion,
      entityType,
      setEntityType,
      app,
      setApp,
      ingestionId,
      setIngestionId,
    ],
  );
}

type Context = ReturnType<typeof useProviderValue>;

export const AppContext = createContext<Context>(defaultAppContext);
AppContext.displayName = "AppProvider";

export const AppProvider = (props: PropsWithChildren) => {
  const value = useProviderValue();
  return <AppContext.Provider value={value} {...props} />;
};
