import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceInputProps,
} from "react-admin";
import { Box } from "@mui/material";
import { FiltersCommonInputProps } from "../../../types/default";

const AutoCompleteMultiInput = (
  props: ReferenceInputProps &
    FiltersCommonInputProps & {
      attributes_to_get: string;
      search_attribute: string;
    },
) => {
  const {
    attributes_to_get: attributes,
    search_attribute,
    sort,
    ...rest
  } = props;
  return (
    <Box width={"100%"}>
      <ReferenceArrayInput
        {...rest}
        queryOptions={{
          meta: { attributes, search_attribute },
        }}
        sort={sort ?? { field: search_attribute, order: "ASC" }}
      >
        <AutocompleteArrayInput
          filterToQuery={(search) => ({ search })}
          optionText={search_attribute}
          optionValue={search_attribute}
          size="small"
        />
      </ReferenceArrayInput>
    </Box>
  );
};

export default AutoCompleteMultiInput;
