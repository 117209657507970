import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  useNotify,
  useEditController,
  SimpleForm,
  Edit,
  Loading,
  useTranslate,
} from "react-admin";
import { API } from "aws-amplify";
import { handleFetchError } from "../../errors/ErrorHandling";
import { isString } from "../../types/type-guards/type-guards";
import { TUserGroup } from "../../types/default";
import AdminUsersEditBottomToolbar from "./AdminUsersEditBottomToolbar";

type ResponseType = {
  data: TUserGroup[];
  total: number;
};
const AdminUsersEdit = (props: {
  id: string | number;
  onClose: () => void;
  userGroups: TUserGroup[];
}) => {
  const { id, onClose, userGroups } = props;
  const [groups, setGroups] = useState<TUserGroup[] | null>(null);
  const [newRoles, setNewRoles] = useState<string[]>([]);
  const translate = useTranslate();
  const notify = useNotify();

  useEffect(() => {
    async function fetchGroupList() {
      try {
        const response: ResponseType = await API.get(
          "TrustAPI",
          "assets/groups",
          {},
        );
        setGroups(response?.data);
      } catch (error: any) {
        notify(handleFetchError(error, translate));
      }
    }

    fetchGroupList();
  }, [translate, notify]);

  const handleChange = (event: SelectChangeEvent<typeof newRoles>) => {
    const selectedRoles = isString(event.target.value)
      ? event.target.value.split(",")
      : event.target.value;
    const updatedRoles = Array.from(new Set(["user", ...selectedRoles]));
    setNewRoles(updatedRoles);
  };

  const { record, isLoading } = useEditController({
    resource: "users",
    id,
    queryOptions: {
      meta: {
        groups: JSON.stringify([]),
      },
    },
  });

  useEffect(() => {
    if (userGroups && groups) {
      const filteredRoles = userGroups.filter((group: string) =>
        groups.includes(group),
      );
      setNewRoles(filteredRoles);
    }
  }, [userGroups, groups]);
  if (isLoading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <Edit
      {...props}
      queryOptions={{ meta: { groups: JSON.stringify(userGroups) } }}
      component="div"
      title="Edit User Role"
      sx={{
        marginTop: 0,
      }}
    >
      <SimpleForm
        toolbar={
          <AdminUsersEditBottomToolbar
            id={id}
            onClose={onClose}
            newRoles={newRoles}
            record={record}
          />
        }
      >
        <Stack direction="column" gap={2} width={"100%"}>
          <Box>
            <InputLabel variant="standard">User: </InputLabel>
            <Stack direction="row" alignItems="center" gap={1}>
              <PersonIcon />
              <Typography variant="body2">{record?.email || ""}</Typography>
            </Stack>
          </Box>
          <Box>
            <InputLabel variant="standard">Role: </InputLabel>
            <Select
              fullWidth
              multiple
              value={newRoles || []}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected: string[]) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected
                    .filter((value: string) => value !== "user")
                    .map((value: string) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          borderRadius: 5,
                          fontWeight: 600,
                          textTransform: "capitalize",
                          width: "fit-content",
                          height: "fit-content",
                          padding: 0.5,
                          background: "#F3FAFF",
                        }}
                      />
                    ))}
                </Box>
              )}
            >
              {groups
                ?.filter((role: string) => role !== "user")
                .map((role) => (
                  <MenuItem
                    key={role}
                    value={role}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {role}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Stack>
      </SimpleForm>
    </Edit>
  );
};

export default AdminUsersEdit;
