import { Route } from "react-router-dom";
import {
  adminDashboard,
  rodDashboard,
  SPDDashboard,
} from "../components/Dashboard/DashboardLandingContent";
import DashboardLandingPage from "../pages/Landing/DashboardLandingPage";
import { Resources as CoDResources } from "./CODashboard/routes";
import { Resources as RoDResources } from "./RoDDashboard/routes";
import { Resources as SpDResources } from "./SPDDashboard/routes";
import { Resources as CtDResources } from "./CTDashboard/routes";
import { Resources as adminResources } from "./AdminDashboard/routes";
import enStrings from "../providers/i18nProvider/CustomStrings/enStrings";
const version = process.env.REACT_APP_VERSION ?? "0.0.0";

const dashboards = {
  codDashboard: {
    app: "codDashboard",
    title: "Compliance Optimization",
    description:
      "The Compliance Optimization Dashboard is a portal that allows AWS Account owners and TRM Team members to monitor the level of programmatic compliance of all AWS resources for any product and any standard listed, with a structured, dynamic, and daily updated evidence-based approach.",
    basename: "/cod",
    baseRoute: (
      <Route
        key="codDashboard"
        path="/cod/*"
        element={
          <DashboardLandingPage
            app="codDashboard"
            version={version}
            basename="/cod"
            resources={CoDResources}
          />
        }
      />
    ),
  },
  rodDashboard: {
    app: "rodDashboard",
    description:
      "The Resource Optimization Dashboard is a portal that allows AWS Account owners and members of the Cost Optimization Team to monitor different Cost Optimization KPIs of related AWS resources for any product, with a structured, dynamic, and daily updated evidence-based approach.",
    title: "Resource Optimization",
    basename: "/rod",
    baseRoute: (
      <Route
        key="rodDashboard"
        path="/rod/*"
        element={
          <DashboardLandingPage
            app="rodDashboard"
            version={version}
            basename="/rod"
            resources={RoDResources}
            dashboard={rodDashboard}
          />
        }
      />
    ),
  },
  spdDashboard: {
    app: "spdDashboard",
    description: enStrings.nav.spdDashboard.description,
    title: enStrings.nav.spdDashboard.dashboardTitle,
    basename: "/spd",
    baseRoute: (
      <Route
        key="spdDashboard"
        path="/spd/*"
        element={
          <DashboardLandingPage
            app="spdDashboard"
            version={version}
            dashboard={SPDDashboard}
            basename="/spd"
            resources={SpDResources}
          />
        }
      />
    ),
  },
  ctdDashboard: {
    app: "ctdDashboard",
    description: enStrings.nav.ctdDashboard.description,
    title: enStrings.nav.ctdDashboard.dashboardTitle,
    basename: "/ctd",
    baseRoute: (
      <Route
        key="ctdDashboard"
        path="/ctd/*"
        element={
          <DashboardLandingPage
            app="ctdDashboard"
            version={version}
            basename="/ctd"
            resources={CtDResources}
          />
        }
      />
    ),
  },
  adminDashboard: {
    app: "adminDashboard",
    title: "Admin Panel",
    description: "Monitor ingestions, user roles and the FAQ page.",
    basename: "/admin",
    baseRoute: (
      <Route
        key="adminDashboard"
        path="/admin/*"
        element={
          <DashboardLandingPage
            app="adminDashboard"
            version={version}
            dashboard={adminDashboard}
            basename="/admin"
            resources={adminResources}
          />
        }
      />
    ),
  },
};

export default dashboards;
