import { useListPaginationContext } from "react-admin";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button } from "@mui/material";

/**
 * <SimplePagination /> is a component that display Left / Right chevron
 * without any total number of pages nor per page
 */
export const SimplePagination = () => {
  const { hasNextPage, hasPreviousPage, page, setPage } =
    useListPaginationContext();

  return hasNextPage || hasPreviousPage ? (
    <Box mt={1} textAlign="end">
      <Button disabled={!hasPreviousPage} onClick={() => setPage(page - 1)}>
        <ChevronLeftIcon />
      </Button>
      <Button disabled={!hasNextPage} onClick={() => setPage(page + 1)}>
        <ChevronRightIcon />
      </Button>
    </Box>
  ) : null;
};
