import PageHeader from "../../../Header/PageHeader";
import EmbeddedDashboard from "../../../Quicksight/EmbeddedDashboard";

function ContainerMonthlyCostReport() {
  return (
    <div>
      <PageHeader title="Containers Cost and usage reports" />
      <EmbeddedDashboard dashboardName="QuicksightContainerMonthlyCostReport" />
    </div>
  );
}

export default ContainerMonthlyCostReport;
