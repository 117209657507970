import { Typography } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";

export const NO_VALUE = "N/A";
const NAField = () => {
  return (
    <Typography
      sx={{
        display: "flex",
        alignItems: "center",
        margin: "auto",
        gap: 0.5,
        fontSize: 14,
        color: "grey",
      }}
    >
      <AdjustIcon color="disabled" /> {NO_VALUE}
    </Typography>
  );
};

export default NAField;
