import {
  TAccount,
  TProduct,
  TService,
  fieldPropTypes,
} from "../../types/default";
import { Typography, TypographyProps } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { memo, FC, ComponentType, ReactNode } from "react";

const TableField: FC<TableFieldProps> = memo((props) => {
  const { source, fieldType: Field, tooltipContent, isLink } = props;
  const record = useRecordContext(props);

  return (
    <Field
      {...props}
      source={source}
      record={record}
      isLink={isLink}
      tooltipContent={tooltipContent}
    />
  );
});

TableField.displayName = "TableField";

TableField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface TableFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  source: string;
  fieldType: ComponentType<any>;
  tooltipContent?: ComponentType<any> | ReactNode;
  displayable?: (
    record: TAccount | TProduct | TService,
    source: string,
  ) => boolean;
  isLink: boolean;
}

export default TableField;
