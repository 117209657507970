import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link } from "react-admin";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState, SyntheticEvent } from "react";

const TitleCard = ({ path, title, description, panel }: TitleCardProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panelId: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panelId : false);
    };

  return (
    <Box sx={{ maxWidth: 350, flexGrow: 1, height: "auto" }}>
      <Card
        elevation={3}
        sx={{
          textAlign: "center",
          paddingX: 2,
          margin: 0,
          height: "100%",
          borderRadius: "5px",
        }}
      >
        <CardContent>
          <Link to={path}>
            <Tooltip title="Enter dashboard">
              <Typography
                variant="h2"
                component="div"
                sx={{
                  fontSize: "1.2em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {title}
                <IconButton aria-label="enter" color="primary">
                  <ArrowForwardIcon />
                </IconButton>
              </Typography>
            </Tooltip>
          </Link>

          {description && panel && (
            <Accordion
              expanded={expanded === panel}
              onChange={handleChange(panel)}
              elevation={0}
              disableGutters
              sx={{ "&:before": { display: "none" } }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                sx={{
                  padding: 0,
                  alignItems: "flex-start",
                  minHeight: "auto",
                  "& .MuiAccordionSummary-content": { margin: 0 },
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                  Read more
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    margin: "auto",
                    fontSize: "13px",
                    textAlign: "justify",
                  }}
                >
                  {description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

interface TitleCardProps {
  title: string;
  path: string;
  description?: string;
  panel?: string;
}

export default TitleCard;
