import { Box, Drawer } from "@mui/material";
import { Button, ButtonGroup } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { useListContext } from "react-admin";
import { ResmedSidebarToggleButton } from "../SidebarToggleMenu";
import SideBarHeader from "../SideBarHeader";
import { ReactElement, ReactNode, useCallback } from "react";
import FilterCategoryHeader from "./FilterCategoryHeader";

const clickAway = (
  event: MouseEvent | TouchEvent,
  setIsOpen: (isOpen: boolean) => void,
  filterButtonRef: (EventTarget & HTMLButtonElement) | null,
): void => {
  // fix for ClickAwayListener closing on clicking on SelectInput component
  // https://github.com/mui/material-ui/issues/25578
  const $node = event.target as Node | null;
  if ($node?.nodeName === "BODY" && event.type === "click") {
    return;
  }
  // when clicking on FilterButton, do not close the sidebar
  if (event.target == filterButtonRef || filterButtonRef?.contains($node)) {
    // checking that button or icon has been clicked
    return;
  }
  setIsOpen(false);
};

const FilterSidebar = (props: FilterSideBarProps) => {
  const { isOpen, setIsOpen, filters, filterButtonRef } = props;

  const { setFilters } = useListContext();

  const handleClearFilter = useCallback(() => {
    /**
     * https://github.com/marmelab/react-admin/pull/1745
     * this is a workaround to set default values for filter inputs,
     * as we cannot use defaultValue on an alwaysOn react-admin input.
     */
    const defaultValues: { [key: string]: string } = {};

    filters?.forEach((filterCategory: FilterCategoryProps) => {
      filterCategory.filterList.forEach((filterItem: ReactNode) => {
        filterItem = filterItem as ReactElement;
        defaultValues[filterItem?.props?.source] =
          filterItem?.props?.defaultVal || "";
      });
    });

    setFilters(defaultValues, {});
  }, [filters, setFilters]);
  return (
    <ClickAwayListener
      onClickAway={(e) => clickAway(e, setIsOpen, filterButtonRef)}
    >
      <Drawer
        variant="persistent"
        open={isOpen}
        PaperProps={{
          elevation: 10,
          sx: {
            width: 450,
          },
        }}
      >
        <Box sx={{ marginTop: "4em", padding: 1 }}>
          <SideBarHeader title={`commons.filters`}>
            {/**
             * Logic for resizing the grid when opening the filter sidebar:
             * As we cannot easily resize the data-grid when the filter sidebar is open, we take advantage
             * of the {ResmedSidebarToggleButton} to have access to the Menu sidebar, which will be open
             * when we open our filter sidebar, (note that the z-index of the menu sidebar is less than the z-index
             * of the filter sidebar).
             * */}
            <ResmedSidebarToggleButton
              isFilterSidebarOpen={isOpen}
              filterSidebarClose={() => setIsOpen(false)}
            />
          </SideBarHeader>
        </Box>
        <Box>
          {filters &&
            filters.map((filter: FilterCategoryProps) => (
              <FilterCategoryHeader
                filterHeader={filter.filterHeader}
                filterList={filter.filterList}
              />
            ))}
          <ButtonGroup
            sx={{
              margin: "1em 1em 0 0",
              display: "flex",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{ marginLeft: "auto" }}
              onClick={handleClearFilter}
            >
              Clear
            </Button>
          </ButtonGroup>
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};

interface FilterSideBarProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filters?: FilterCategoryProps[];
  filterButtonRef: (EventTarget & HTMLButtonElement) | null;
  searchLabel?: string;
}
export interface FilterCategoryProps {
  filterHeader: string;
  filterList: ReactNode[];
}
export default FilterSidebar;
