import { fieldPropTypes } from "../../types/default";
import { Typography, TypographyProps, Box } from "@mui/material";
import {
  FieldProps,
  RowClickFunction,
  useRecordContext,
  useRedirect,
  useResourceContext,
} from "react-admin";
import { memo, FC, useCallback } from "react";

const ClickableField: FC<ClickableFieldProps> = memo((props) => {
  const { children, cellClick } = props;
  const record = useRecordContext();
  const resource = useResourceContext();
  const navigate = useRedirect();

  const handleClick = useCallback(
    async (event: any) => {
      event.stopPropagation();
      event.persist();
      const type =
        typeof cellClick === "function"
          ? await cellClick(record.id, resource, record)
          : cellClick;
      if (type === false || type == null) {
        return;
      }
      navigate(type);
    },
    [cellClick, resource, record, navigate],
  );

  return (
    <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
      {children}
    </Box>
  );
});

ClickableField.displayName = "ClickableField";

ClickableField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface ClickableFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  source: string;
  cellClick: string | RowClickFunction;
}

export default ClickableField;
