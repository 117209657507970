import { SelectInput, SelectInputProps, useTranslate } from "react-admin";
import { FiltersCommonInputProps } from "../../types/default";

const ComplianceInput = (props: SelectInputProps & FiltersCommonInputProps) => {
  const translate = useTranslate();
  const choices = [
    { id: "true", name: "100%" },
    { id: "false", name: "< 100%" },
  ];

  return (
    <SelectInput
      {...props}
      key="compliance"
      label={translate(`filters.compliance.label`)}
      emptyText="Compliance"
      choices={choices}
      sx={{ width: "100%" }}
    />
  );
};

export default ComplianceInput;
