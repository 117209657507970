export function handleFetchError(
  error: any,
  translate: (key: string) => string,
): string {
  if (error.response) {
    switch (error.response.status) {
      case 404:
        return error.response.data;
      case 500:
        return translate(`commons.error.500`);
      case 403:
        return translate(`commons.error.403`);
      default:
        return error.message;
    }
  } else {
    return error.message;
  }
}
