import useSetEntityType from "../../../../hooks/useSetEntityType";
import Breakdown, { BreakdownProps } from "../../../Breakdown/Breakdown";
import DetailButton from "../../../Breakdown/DetailButton";
import ListToolbar from "../../../Datagrid/ListToolBar";
import TableField from "../../../Fields/TableField";
import NameField from "../../../Fields/NameField";
import PercentageField from "../../../Fields/PercentageField";
import PageHeader from "../../../Header/PageHeader";
import AccountList from "../AccountList";
import RegionInput from "../../../Inputs/RegionInput";
import ComplianceInput from "../../../Inputs/ComplianceInput";
import ProductInput, {
  MissingProductCheckboxInput,
} from "../../../Inputs/ProductInput";
import { FilterCategoryProps } from "../../../SideBar/FilterSidebar/FilterSideBar";

const ComplianceAccountList = () => {
  useSetEntityType("accounts.compliance");
  const preferenceKey = "accountList.datagrid";

  const filterList: FilterCategoryProps[] = [
    {
      filterHeader: "General Filters",
      filterList: [
        <RegionInput source="region" alwaysOn />,
        <ProductInput
          source="product"
          reference="assets/accounts?distinct=true"
          alwaysOn
        />,
        <MissingProductCheckboxInput source="missing_product" alwaysOn />,
        <ComplianceInput source="compliance" alwaysOn />,
      ],
    },
  ];

  return (
    <AccountList
      priorisedColumns={["hds", "gdpr", "soc2"]}
      resource="compliance/accounts"
      preferenceKey={preferenceKey}
      actions={
        <ListToolbar
          enableSearch={true}
          searchLabel="commons.search"
          filterList={filterList}
          preferenceKey={preferenceKey}
        />
      }
      fixedColumns={[
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="name"
          sortBy="name"
          label={`accounts.displayName`}
        />,
        <NameField
          sx={{ textAlign: "center", display: "inline-block" }}
          source="product"
          label={`accounts.finops.product.displayName`}
          sortBy="product"
          subtitle={false}
        />,
      ]}
      dynamicColumn={hoverableColumns}
      header={
        <PageHeader
          resource="Accounts"
          descriptionKey="services.infoText"
          title="Global Accounts"
        />
      }
    />
  );
};

export const hoverableColumns = (column: string) => {
  const AccountListBreakdown = (props: BreakdownProps) => {
    return (
      <Breakdown
        {...props}
        detailButton={<DetailButton standard={`${column}.standard_name`} />}
      />
    );
  };

  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block" }}
      source={column}
      label={`compliance.columns.${column}`}
      key={`${column}`}
      fieldType={PercentageField}
      tooltipContent={AccountListBreakdown}
    />
  );
};

export default ComplianceAccountList;
