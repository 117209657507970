import { useContext } from "react";
import { useTranslate } from "react-admin";
import { AppContext } from "../../context/AppContext";
import BreakdownContent from "./BreakdownContent";
import { BreakdownProps } from "./Breakdown";
import { Variant } from "@mui/material/styles/createTypography";
import { BreakdownMessages } from "../../types/default";

interface DateUsedBreakdownProps extends Omit<BreakdownProps, "record"> {
  timestamp?: string;
  additionalMessages?: BreakdownMessages[];
}

const DateUsedBreakdown = (props: DateUsedBreakdownProps) => {
  const { detailButton, timestamp, source, additionalMessages } = props;

  const translate = useTranslate();
  const appContext = useContext(AppContext);
  const defaultMessages = [
    {
      key: translate(`${appContext.entityType}.${source}.popover.timestamp`, {
        _: "Timestamp",
      }),
      value: timestamp,
      styling: {
        marginTop: true,
        variant: "h5" as Variant,
      },
    },
  ];

  const messages = additionalMessages
    ? [...additionalMessages, ...defaultMessages]
    : defaultMessages;

  return <BreakdownContent messages={messages} detailButton={detailButton} />;
};

export default DateUsedBreakdown;
