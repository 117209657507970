import {
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { API } from "aws-amplify";
import { TQuery, TRequestPayload } from "../../types/default";
import { omit } from "../../utils/omit";
import { Pagination } from "./pagination";

// React admin will redirect to page 1
const redirectToFirstPage = () => ({
  data: [],
  total: 0,
});

export const dataProvider = {
  getList: async (
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult> => {
    try {
      const meta = omit("attributes", params?.meta);
      const { page, perPage } = params.pagination;
      const queryVariables = meta || {};
      const queryFilters = params?.filter || {};
      const querySignature = JSON.stringify({
        resource,
        perPage,
        queryVariables,
        queryFilters,
      });

      const nextToken = Pagination.getNextToken(querySignature, page);

      const { field, order } = params.sort;
      const query: TQuery = {
        sort: JSON.stringify([field, order]),
        pageSize: perPage,
        page: page,
        filter: JSON.stringify({ ...queryVariables, ...queryFilters }),
        ...(nextToken && nextToken !== "false" ? { nextToken } : {}),
      };

      if (params?.meta?.attributes) {
        query.attributes = params.meta.attributes;
      }

      if (params?.meta?.search_attribute) {
        query.search_attribute = params.meta.search_attribute;
      }

      const response = await API.get("TrustAPI", resource, {
        queryStringParameters: query,
      });

      const data = response.data || [];

      Pagination.saveNextToken(response?.nextToken, querySignature, page);

      return {
        data: data,
        ...(response?.total ? { total: response?.total } : {}),
        ...(response?.nextToken !== undefined
          ? {
              pageInfo: {
                hasNextPage: !!response?.nextToken,
                hasPreviousPage:
                  nextToken !== "false" && nextToken !== undefined,
              },
            }
          : {}),
      };
    } catch (error: any) {
      if (error.response) {
        return redirectToFirstPage();
      }
      throw error;
    }
  },

  getOne: async (
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult> => {
    const id = encodeURIComponent(params.id);
    const queryVariables = params?.meta || {};

    const response = await API.get("TrustAPI", `${resource}/${id}`, {
      queryStringParameters: queryVariables,
    });

    const responseData = { id: params.id, ...response };

    return { data: responseData };
  },

  getMany: (): Promise<GetManyResult> => Promise.resolve({ data: [] }), // avoids adding a context in tests
  getManyReference: (): Promise<GetManyReferenceResult> => // avoids adding a context in tests
    Promise.resolve({ data: [], total: 0 }),
  create: (): Promise<CreateResult> => Promise.resolve({ data: null }), // avoids adding a context in tests
  delete: (): Promise<DeleteResult> => Promise.resolve({ data: null }), // avoids adding a context in tests
  update: async (
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult> => {
    try {
      const id = encodeURIComponent(params.id);
      const meta = params?.meta || {};
      const queryStringParameters = meta.queryStringParameters;

      const updateType = meta.updateType;

      let baseURL = `${resource}/${id}`;
      if (updateType) {
        baseURL += `/${updateType}`;
      }

      const requestPayload: TRequestPayload = {};

      for (const key in queryStringParameters) {
        requestPayload[key] = queryStringParameters[key];
      }

      requestPayload.body = params?.data;

      const response = await API.put("TrustAPI", baseURL, requestPayload);
      return { data: response.data };
    } catch (error) {
      console.error("Error updating data:", error);
      throw new Error("Failed to update data");
    }
  },

  updateMany: (): Promise<UpdateManyResult> => Promise.resolve({ data: [] }),
  deleteMany: (): Promise<DeleteManyResult> => Promise.resolve({ data: [] }), // avoids adding a context in tests
};
