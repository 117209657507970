import { useEffect, useState, useMemo, useCallback } from "react";
import {
  useListContext,
  Loading,
  RaRecord,
  RowClickFunction,
  useStore,
} from "react-admin";
import { DatagridConfigurable } from "react-admin";
import { TAccount, TProduct, TService } from "../../types/default";
import DatagridHeader from "./DatagridHeader";

const defaultColumnGetter = (
  prev: string[],
  next: TAccount | TProduct | TService,
) => {
  next.columns.map((column: string) => {
    if (!prev.includes(column)) prev.push(column);
    return false;
  });
  return prev;
};

const Datagrid = (props: DatagridProps) => {
  const {
    hasInfo,
    priorisedColumns,
    preferenceKey,
    fixedColumns,
    dynamicColumn,
    columnsGetter = defaultColumnGetter,
    rowClick,
    expandable = undefined,
  } = props;

  const { data, isFetching, isLoading, filterValues } = useListContext();
  const priorityColumns = useMemo(
    () => priorisedColumns || [],
    [priorisedColumns],
  );
  const [isLoadingColumns, setIsLoadingColumns] = useState<boolean>(true);
  const [columns, setColumns] = useState<string[]>([]);
  const policiesFilter = filterValues["policies"];
  const [omittedColumns, setOmittedColumns] = useStore<string[]>(
    `preferences.${preferenceKey}.omit`,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setVisibleColumns] = useStore<string[]>(
    `preferences.${preferenceKey}.columns`,
  );

  const shouldOmitColumn = (
    allColumns: any,
    column: string,
    policiesFilter: string,
  ) =>
    policiesFilter === "true" &&
    (allColumns[column]?.assigned_policies || 0) === 0;

  const calculateVisibleColumns = useCallback(
    (columns: string[], policiesFilter: string, data: any[]) => {
      const allColumns = data.reduce((acc, item) => ({ ...acc, ...item }), {});
      const omittedColumns = columns.filter((column) =>
        shouldOmitColumn(allColumns, column, policiesFilter),
      );
      const visibleColumnsIndex = Array.from(
        { length: fixedColumns?.length || 0 },
        (_, i) => i.toString(),
      );
      const visibleColumns = columns.filter(
        (column) => !omittedColumns.includes(column),
      );
      const visibleColumnsIndexTotal = visibleColumnsIndex.concat(
        visibleColumns.map((column) =>
          (columns.indexOf(column) + (fixedColumns?.length || 0)).toString(),
        ),
      );
      return [omittedColumns, visibleColumnsIndexTotal];
    },
    [fixedColumns],
  );

  useEffect(() => {
    setIsLoadingColumns(true);
    const initialColumns = data ? data.reduce<string[]>(columnsGetter, []) : [];
    const sortedColumns = priorityColumns.concat(
      initialColumns.filter((column) => !priorityColumns.includes(column)),
    );
    setColumns(sortedColumns);
    setIsLoadingColumns(false);
    return () => {
      setColumns([]);
    };
  }, [data, columnsGetter, priorityColumns]);

  useEffect(() => {
    if (columns.length === 0 || isLoadingColumns) return;
    setIsLoadingColumns(true);
    const [omittedColumns, visibleColumns] = calculateVisibleColumns(
      columns,
      policiesFilter,
      data,
    );
    setOmittedColumns(omittedColumns);
    setVisibleColumns(visibleColumns);
    setIsLoadingColumns(false);
  }, [
    columns,
    policiesFilter,
    calculateVisibleColumns,
    data,
    setOmittedColumns,
    setVisibleColumns,
    isLoadingColumns,
  ]);
  if (isFetching || isLoading || isLoadingColumns) return <Loading />;
  return (
    <DatagridConfigurable
      preferenceKey={preferenceKey}
      header={<DatagridHeader hasInfo={hasInfo} />}
      omit={omittedColumns}
      bulkActionButtons={false}
      rowClick={rowClick}
      isRowExpandable={expandable}
      hover={true}
      sx={{ overflowX: "auto" }}
    >
      {fixedColumns}
      {columns && dynamicColumn && columns.map(dynamicColumn)}
    </DatagridConfigurable>
  );
};

export interface DatagridProps {
  hasInfo?: boolean;
  preferenceKey: string;
  fixedColumns?: JSX.Element[];
  dynamicColumn?: (source: string) => JSX.Element | null;
  columnsGetter?: (prev: string[], next: RaRecord) => string[];
  rowClick?: string | RowClickFunction | undefined;
  expandable?: (record: any) => any | undefined;
  priorisedColumns?: string[];
}

export default Datagrid;
