import { Link, Typography } from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useRecordContext, useShowContext } from "react-admin";
import { TAccount, TMappingTlzUrl, TProduct } from "../../types/default";
import { useLocation } from "react-router-dom";
import {
  DATADOG_LINK_MONITORING,
  PRISMA_ALERTS_STANDARD,
} from "../../definition/definition";

const baseQueryParameters =
  "viewId=default#timeRange[type]=to_now&timeRange[value]=epoch";

const MAPPING: TMappingTlzUrl = {
  EU: {
    baseUrl: "app.eu.prismacloud.io",
    searchId: {
      publicS3: "ba61d0a2-1124-420e-b20f-78f822f678be",
      ec2: "dd6d41a2-55bc-4a2b-a8eb-a68a82a61cad",
      gpType: "6ee0aa7b-b368-4797-b07e-80ea6811e74b",
      volumeState: "ffe1e294-0cfa-4a92-afad-ac086cb93b00",
    },
    alerts: {
      prismaAlerts: `overview?viewId=default&filters=%7B"timeRange"%3A%7B"type"%3A"to_now"%2C"value"%3A"epoch"%7D%2C"timeRange.type"%3A"ALERT_OPENED"%2C"alert.status"%3A%5B"open"%5D%2C"policy.severity"%3A%5B"critical"%2C"high"%5D%7D`,
    },
  },
  CN: {
    baseUrl: "app.prismacloud.cn",
    searchId: {
      publicS3: "eadec851-176b-4bce-99fa-4457f6ca0b5b",
      ec2: "1d72f7b5-fc90-475a-8dcc-f1de543ff14c",
      gpType: "3e23e314-e38d-40d2-9b96-63d2ec778d3e",
      volumeState: "90f748df-ee4f-4f11-8cf4-4681b327207d",
    },
    alerts: {
      prismaAlerts: `overview?page%5Bnumber%5D=1#timeRange%5Btype%5D=relative&timeRange%5Bvalue%5D%5Bamount%5D=24&timeRange%5Bvalue%5D%5Bunit%5D=hour&timeRange.type=ALERT_OPENED&alert.status%5B%5D=open&policy.severity%5B%5D=high&policy.severity%5B%5D=critical`,
    },
  },
  GLB: {
    baseUrl: "app.eu.prismacloud.io",
    searchId: {
      publicS3: "7d954051-6668-4af2-814f-6540cf1bc1d6",
      ec2: "d37b4e0d-ee3a-4353-919e-797bcf1e4f00",
      gpType: "cf379938-ccba-4a5d-9509-333581b86fee",
      volumeState: "cb6179ee-efce-4c87-a58a-098978a89fbe",
    },
    alerts: {
      prismaAlerts: `overview?viewId=default&filters=%7B"timeRange"%3A%7B"type"%3A"to_now"%2C"value"%3A"epoch"%7D%2C"timeRange.type"%3A"ALERT_OPENED"%2C"alert.status"%3A%5B"open"%5D%2C"policy.severity"%3A%5B"high"%2C"critical"%5D%7D`,
    },
  },
};

const DetailButton = (props: DetailButtonProps) => {
  const { standard, service, requirement, policy } = props;

  const record = useRecordContext();
  const showRecord = useShowContext<TAccount | TProduct>();
  const location = useLocation();
  const [baseLink, setBaseLink] = useState<string | undefined>(undefined);
  const [cloudAccount, setCloudAccount] = useState<string | undefined>(
    undefined,
  );
  const [policyCompliance, setPolicyCompliance] = useState<string | undefined>(
    undefined,
  );
  const [policyRequirements, setPolicyRequirements] = useState<
    string | undefined
  >(undefined);
  const [policyName, setPolicyName] = useState<string | undefined>(undefined);
  const [cloudService, setCloudService] = useState<string | undefined>(
    undefined,
  );
  const [queryParameters, setQueryParameters] = useState<string | undefined>(
    undefined,
  );

  const MAPPING_OBJECT = MAPPING[record["tlz_id"]];

  useEffect(() => {
    if (MAPPING_OBJECT?.baseUrl) {
      if (
        (location.pathname.includes("/spd") ||
          location.pathname.includes("/rod")) &&
        standard != undefined
      ) {
        if (standard === PRISMA_ALERTS_STANDARD) {
          setBaseLink(
            `https://${MAPPING_OBJECT["baseUrl"]}/alerts/${MAPPING_OBJECT["alerts"][standard]}`,
          );
        } else {
          setBaseLink(
            `https://${MAPPING_OBJECT["baseUrl"]}/investigate/search?searchId=${MAPPING_OBJECT["searchId"][standard]}`,
          );
        }
      } else if (location.pathname === "/cod/accounts") {
        setBaseLink(
          `https://${MAPPING_OBJECT["baseUrl"]}/inventory/asset_inventory?`,
        );
      } else {
        setBaseLink(`https://${MAPPING_OBJECT["baseUrl"]}/alerts/overview?`);
      }
    }
  }, [location, record]);

  useEffect(() => {
    if (showRecord && "prisma_name" in showRecord) {
      setCloudAccount(
        `cloud.account[]=${encodeURI(`${showRecord.prisma_name}`)}`,
      );
    } else if (record) {
      setCloudAccount(`cloud.account[]=${encodeURI(`${record.prisma_name}`)}`);
    }
    if (record && standard && get(record, standard)) {
      setPolicyCompliance(
        `policy.complianceStandard[]=${encodeURIComponent(
          get(record, standard),
        )}`,
      );
    } else if (standard) {
      setPolicyCompliance(
        `policy.complianceStandard[]=${encodeURIComponent(standard)}`,
      );
    }
    if (record && service && get(record, service)) {
      setCloudService(
        `cloud.service[]=${encodeURIComponent(get(record, service))}`,
      );
    }
    if (requirement) {
      setPolicyRequirements(
        `policy.complianceRequirement[]=${encodeURIComponent(requirement)}`,
      );
    }
    if (policy) {
      setPolicyName(`policy.name[]=${encodeURIComponent(policy)}`);
    }
  }, [showRecord, record, standard, service, requirement, policy]);

  useEffect(() => {
    let queryParameters = baseQueryParameters;
    if (cloudAccount) {
      queryParameters = [queryParameters, cloudAccount].join("&");
    }
    if (policyCompliance) {
      queryParameters = [queryParameters, policyCompliance].join("&");
    }
    if (cloudService) {
      queryParameters = [queryParameters, cloudService].join("&");
    }
    if (policyRequirements) {
      queryParameters = [queryParameters, policyRequirements].join("&");
    }
    if (policyName) {
      queryParameters = [queryParameters, policyName].join("&");
    }
    setQueryParameters(queryParameters);
  }, [
    cloudAccount,
    policyCompliance,
    cloudService,
    policyRequirements,
    policyName,
  ]);

  const openPrisma = async () => {
    location.pathname.includes("/spd") || location.pathname.includes("/rod")
      ? window.open(`${baseLink}`, `prisma`)
      : window.open(`${baseLink}${queryParameters}`, `prisma`);
  };

  if (!queryParameters) return <></>;
  return (
    <Typography variant="subtitle1">
      <Link
        onClick={openPrisma}
        rel="noreferrer"
        target="_blank"
        sx={{ cursor: "pointer" }}
        data-dd-action-name={DATADOG_LINK_MONITORING.PRISMACLOUD}
      >
        Source: Prisma Cloud
      </Link>
      <Typography variant="subtitle2" color="grey">
        Please confirm you are already logged in Prisma Cloud
      </Typography>
    </Typography>
  );
};

interface DetailButtonProps {
  standard?: string;
  service?: string;
  requirement?: string;
  policy?: string;
}

export default DetailButton;
