import { fieldPropTypes } from "../../types/default";
import { Box, Typography, TypographyProps, Tooltip } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { memo, FC, ComponentType } from "react";
import SuccessField from "./kpi/SuccessField";
import ErrorField from "./kpi/ErrorField";
import NAField from "./kpi/NAField";

const BooleanField: FC<BooleanFieldProps> = memo((props) => {
  const {
    source,
    errorLabel,
    successLabel,
    errorValue = false,
    successValue = true,
  } = props;
  const record = useRecordContext(props);

  let valueComponent: React.ReactNode = null;
  switch (record?.[source]) {
    case errorValue:
      valueComponent = <ErrorField isLink={false} value={errorLabel} />;
      break;
    case successValue:
      valueComponent = <SuccessField isLink={false} value={successLabel} />;
      break;
    default:
      valueComponent = (
        <Tooltip classes={{ tooltip: "kpiPopover" }} title="Data not available">
          <NAField />
        </Tooltip>
      );
      break;
  }

  return (
    <>
      <Box sx={{ padding: 1 }}>{valueComponent}</Box>
    </>
  );
});

BooleanField.displayName = "BooleanField";

BooleanField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface BooleanFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  source: string;
  errorLabel?: string;
  successLabel?: string;
  errorValue?: string | boolean;
  successValue?: string | boolean;
  tooltipContent?: ComponentType<any>;
}

export default BooleanField;
