import {
  COST_ADMIN_TOOL_STANDARD,
  DATADOG_LINK_MONITORING,
} from "../../definition/definition";
import { isString } from "../../types/type-guards/type-guards";
import { formatCurrency } from "../../utils/currency-utils";
import { BreakdownProps } from "./Breakdown";
import BreakdownContent from "./BreakdownContent";
import { Link } from "@mui/material";

interface Savings {
  [key: string]: string;
}

const CATBreakdown = (props: BreakdownProps) => {
  const { record } = props;

  const date =
    isString(record?.cost_report_installation_date) &&
    new Date(record.cost_report_installation_date).toDateString();

  const saving_synthesis: Savings = JSON.parse(
    record[COST_ADMIN_TOOL_STANDARD]?.monthly_savings_synthesis || "{}",
  );
  const saving_messages = Object.entries(saving_synthesis).map((saving) => ({
    key: saving[0],
    value: formatCurrency(parseFloat(saving[1])),
  }));

  if (record[COST_ADMIN_TOOL_STANDARD].failed === 1) {
    return (
      <BreakdownContent
        messages={[
          {
            key: "Cost Admin Tool is not installed.",
          },
        ]}
        detailButton={
          <>
            <Link
              href="https://ptfe.prod.dht.live/app/resmed/registry/modules/private/resmed/costsaving-service-deploy/aws"
              rel="noreferrer"
              target="_blank"
              data-dd-action-name={DATADOG_LINK_MONITORING.CAT_INSTALLATION}
            >
              Installation guide
            </Link>
          </>
        }
      />
    );
  }

  return (
    <BreakdownContent
      messages={[
        {
          key: "CAT Version Installed",
          value: `${record.cost_report_product_version}`,
        },
        {
          key: "CAT Installed on",
          value: `${date}`,
        },
        ...saving_messages,
        {
          key: "Total savings",
          value: `${formatCurrency(
            record[COST_ADMIN_TOOL_STANDARD]?.monthly_savings || 0,
          )}`,
        },
      ]}
      detailButton={
        <>
          <Link
            href="https://costreport.amr-sre-stg.dht.live/"
            rel="noreferrer"
            target="_blank"
            data-dd-action-name={DATADOG_LINK_MONITORING.CAT_REPORT}
          >
            CAT report
          </Link>
        </>
      }
    />
  );
};

export default CATBreakdown;
