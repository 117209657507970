import {
  List,
  Datagrid,
  TextField,
  useRecordContext,
  useTranslate,
  FunctionField,
} from "react-admin";
import { LocalDateField } from "../Fields/LocalDateField";
import PageHeader from "../Header/PageHeader";
import ModalField from "../Fields/ModalField";
import { LaunchLinkField } from "../Fields/LaunchLinkField";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BooleanField from "../Fields/BooleanField";
import { Typography } from "@mui/material";
import { SimplePagination } from "../Pagination/SimplePagination";
import { isDefined } from "../../types/type-guards/type-guards";

interface TIngestionStatus {
  id: string;
  pk: string;
  sk: string;
  source: string;
  status: string;
  timestamp: string;
  total: number;
  failed?: number;
  failed_percentage?: number;
  retry?: number;
  retry_count?: number;
  success?: number;
  success_percentage?: number;
}

function AdminIngestions() {
  return (
    <div>
      <PageHeader
        title="Ingestion Failure Monitoring"
        descriptionKey="admin.ingestions.header"
      />
      <List
        resource={`analytics/ingestions`}
        actions={false}
        pagination={<SimplePagination />}
      >
        <Datagrid
          expandSingle
          expand={IngestionDetail}
          bulkActionButtons={false}
        >
          <TextField
            source="source"
            sortable={false}
            sx={{ textTransform: "capitalize", fontWeight: 600 }}
          />
          <LocalDateField
            source="timestamp"
            label="Timestamp (local)"
            sortable={false}
          />
          <BooleanField
            successValue="Success"
            successLabel="Successful Ingestion"
            errorValue="Fail"
            errorLabel="Failed Ingestion"
            source="status"
            sortable={false}
          />
          <TextField
            source="total"
            label="N° of Accounts Ingested"
            sortable={false}
          />
          <TextField
            source="success"
            label="Successful Ingested Accounts"
            sortable={false}
          />
          <TextField
            source="failed"
            label="Failed Ingested Accounts"
            sortable={false}
          />
          <FunctionField
            label="Successful Retry"
            render={({ retry, retry_count }: TIngestionStatus) =>
              isDefined(retry) && isDefined(retry_count)
                ? `${retry} out of ${retry_count}`
                : ""
            }
          />
        </Datagrid>
      </List>
    </div>
  );
}

const IngestionDetail = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h4">
        {translate(`admin.ingestions.sublabel`, {
          _: "Ingestion Failure Details",
        })}
      </Typography>
      <List
        pagination={<SimplePagination />}
        resource={`analytics/ingestions/${record.id}`}
        actions={false}
        storeKey="ingestionFailure"
        disableSyncWithLocation
        component="div"
        sx={{
          border: "1px solid lightGrey",
          borderRadius: "20px",
          padding: "1em",
          margin: "1em 1em 2em 1em",
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <LocalDateField source="timestamp" label="Timestamp (local)" />
          <TextField source="error_type" />
          <ModalField
            source="error_message"
            title="Error Type"
            buttonText="Show Details"
            icon={<RemoveRedEyeIcon />}
          />
          <LaunchLinkField source="error_link" />
        </Datagrid>
      </List>
    </>
  );
};

export default AdminIngestions;
