import { ReferenceArrayInputProps, useTranslate } from "react-admin";
import { FiltersCommonInputProps } from "../../types/default";
import AutoCompleteInput from "./common/AutoCompleteInput";

const AccountNameInput = (
  props: ReferenceArrayInputProps & FiltersCommonInputProps,
) => {
  const translate = useTranslate();
  return (
    <AutoCompleteInput
      {...props}
      label={translate("filter.account_name.label")}
      key="prisma_name"
      attributes_to_get="prisma_name"
      search_attribute="prisma_name"
    />
  );
};

export default AccountNameInput;
