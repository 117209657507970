import React from "react";
import {
  Stack,
  Typography,
  Link,
  Tooltip as MaterialTooltip,
} from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Bar,
  LabelList,
  Legend,
} from "recharts";
import {
  Loading,
  ShowBase,
  WithRecord,
  useNotify,
  useTranslate,
} from "react-admin";
import { cardHeaderStyle } from "../RODLandingPage";
import { TRODChartTrend } from "../../../../types/default";
import { formatCurrency } from "../../../../utils/currency-utils";
import { handleFetchError } from "../../../../errors/ErrorHandling";
import { isDefined } from "../../../../types/type-guards/type-guards";
import { DATADOG_LINK_MONITORING } from "../../../../definition/definition";

interface ProgressionChartProps {
  resource: string;
  id: string;
  description?: boolean;
}

const COLORS = ["#4D95A2", "#005B98", "#D3800C", "#E0642F", "#9D2872"];
const ProgressionChart = React.memo(
  ({ resource, id, description }: ProgressionChartProps) => {
    const translate = useTranslate();
    const notify = useNotify();
    /**
     * Display tick text with different Y position to handle small screen
     */
    const CustomTick = ({ x, y, payload, index }: any) => {
      const yOffset = index % 2 === 0 ? 0 : 12;
      return (
        <text
          x={x}
          y={y + yOffset}
          dy={16}
          textAnchor="middle"
          fontSize={12}
          fill="#666"
        >
          {payload.value}
        </text>
      );
    };

    return (
      <Stack direction="column" width="50%" className="customBoxShadow">
        <ShowBase resource={resource} id={id}>
          <WithRecord<TRODChartTrend>
            render={({ data, isLoading, isFetching, error }) => {
              if (isLoading || isFetching || !isDefined(data)) {
                return <Loading />;
              }

              const dataKeys: string[] = data.accounts.map(
                (account: { account_id: string; name: string }) =>
                  account.account_id,
              );

              if (error) {
                notify(handleFetchError(error, translate));
                return null;
              }

              return (
                <>
                  <Stack
                    direction="row"
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography {...cardHeaderStyle}>
                      {translate(
                        `accounts.finops.dashboard.${data?.user_type}.progressionChart.title`,
                      )}
                    </Typography>
                    {description && (
                      <MaterialTooltip
                        title={translate(
                          `accounts.finops.dashboard.${data?.user_type}.progressionChart.tooltip`,
                          { _: "" },
                        )}
                        placement="bottom-start"
                        arrow
                      >
                        <InfoOutlinedIcon
                          sx={{
                            fontSize: 20,
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        />
                      </MaterialTooltip>
                    )}
                  </Stack>
                  {/* TODO remove hard coded height ? */}
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={data.months}
                      margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                    >
                      <XAxis
                        dataKey="month"
                        width={10}
                        type="category"
                        interval={0}
                        tick={<CustomTick />}
                      />
                      <Legend
                        formatter={(accountId, { color }) => {
                          const accountName = data.accounts.find(
                            (account) => account.account_id == accountId,
                          )?.name;
                          return (
                            <>
                              {accountName}
                              <Link
                                rel="noreferrer"
                                target="_blank"
                                href={`https://console.vantage.sh/reports/rprt_906246c7bd96805c?adhoc_filters[aws][account_id][]=${accountId}&report[include_discounts]=false&report[include_tax]=false&report[amortize]=false&report[include_credits]=false&date_interval=last_6_months&report[include_refunds]=false&report[chart_type]=bar&report[date_bucket]=month`}
                                data-dd-action-name={
                                  DATADOG_LINK_MONITORING.VANTAGE
                                }
                              >
                                <IconButton sx={{ color }}>
                                  <OpenInNew sx={{ fontSize: "17px" }} />
                                </IconButton>
                              </Link>
                            </>
                          );
                        }}
                        wrapperStyle={{ paddingTop: 20 }}
                      />
                      {dataKeys.map((dataKey, index) => (
                        <Bar
                          dataKey={dataKey}
                          stackId="a"
                          fill={COLORS[index]}
                          key={index}
                        >
                          <LabelList
                            dataKey={dataKey}
                            content={({ x, y, width, height, value }: any) => {
                              const PADDING = 5;
                              if (height < 20) {
                                return undefined;
                              }

                              return (
                                <g>
                                  <text
                                    x={x + width / 2}
                                    y={PADDING + y + height / 2}
                                    textAnchor="middle"
                                    fill="white"
                                    opacity={0.5}
                                    fontSize={"0.8rem"}
                                  >
                                    {formatCurrency(Number(value))}
                                  </text>
                                </g>
                              );
                            }}
                          />
                        </Bar>
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </>
              );
            }}
          />
        </ShowBase>
      </Stack>
    );
  },
);

ProgressionChart.displayName = "ProgressionChart";
export default ProgressionChart;
