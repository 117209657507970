import { Stack, Grid, Typography, Box, CircularProgress } from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { handleFetchError } from "../../errors/ErrorHandling";

type IngestionDetailDataType = {
  timestamp?: string;
  total?: number;
  success?: number;
  failed?: number;
  success_percentage?: number;
  failed_percentage?: number;
};

function AdminIngestionOverview() {
  const resource = "analytics/ingestions/latest";
  const [data, setData] = useState<IngestionDetailDataType>({});
  const [error, setError] = useState<string | null>(null);
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  async function fetchData(): Promise<void> {
    try {
      const response: IngestionDetailDataType = await API.get(
        "TrustAPI",
        resource,
        {},
      );
      setData(response);
    } catch (error: any) {
      const errorMessage = handleFetchError(error, translate);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <CircularProgress />;

  if (error)
    return (
      <Stack direction={"column"} gap={3}>
        <Typography variant="h3" marginTop={"2em"}>
          Ingestion Overview
        </Typography>
        <Box
          sx={{
            backgroundColor: "rgba(173, 209, 243, 0.13)",
            padding: "2em",
            borderRadius: "25px",
            minWidth: "50vw",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <ErrorContent error={error} />
        </Box>
      </Stack>
    );

  return (
    <Stack direction={"column"} gap={3}>
      <Typography variant="h3" marginTop={"2em"}>
        Ingestion Overview
      </Typography>
      <Box
        sx={{
          backgroundColor: "rgba(173, 209, 243, 0.13)",
          padding: "2em",
          borderRadius: "25px",
          minWidth: "50vw",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <SuccessContent data={data} />
      </Box>
    </Stack>
  );
}

const ErrorContent = ({ error }: { error: string }) => {
  return <Typography variant="h2">{error}</Typography>;
};

const SuccessContent = ({ data }: { data: IngestionDetailDataType }) => {
  return (
    <Grid container spacing={3} flexWrap={"nowrap"}>
      <Grid item xs={4}>
        <Stack direction={"column"} gap={1}>
          <Typography variant="h3" noWrap fontSize={"1em"} fontWeight={400}>
            Total Ingestions
          </Typography>
          <Typography variant="h2">{data.total}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction={"column"} gap={1}>
          <Typography variant="h3" noWrap fontSize={"1em"} fontWeight={400}>
            Successful Ingestions
          </Typography>
          <Typography variant="h2" color="success.main">
            {`${data.success_percentage}%`}
          </Typography>
          <Typography variant="h5" fontWeight={300}>
            {`${data.success}/${data.total}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction={"column"} gap={1}>
          <Typography variant="h3" noWrap fontSize={"1em"} fontWeight={400}>
            Failed Ingestions
          </Typography>
          <Typography variant="h2" color="error.main">
            {`${data.failed_percentage}%`}
          </Typography>
          <Typography variant="h5" fontWeight={300}>
            {`${data.failed}/${data.total}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction={"column"} gap={1}>
          <Typography variant="h3" noWrap fontSize={"1em"} fontWeight={400}>
            Last Ingestion Timestamp
          </Typography>
          <Typography variant="h2">{data.timestamp}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AdminIngestionOverview;
