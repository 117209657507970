import { useTranslate } from "react-admin";
import PageHeader from "../../../Header/PageHeader";
import AccountList from "../AccountList";
import TableField from "../../../Fields/TableField";
import NameField from "../../../Fields/NameField";
import ListToolbar from "../../../Datagrid/ListToolBar";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import { Typography } from "@mui/material";
import { PRISMA_ALERTS_STANDARD } from "../../../../definition/definition";
import Breakdown from "../../../Breakdown/Breakdown";
import PercentageField from "../../../Fields/PercentageField";
import AlertField from "../../../Fields/dashboards/AlertField";
import ClickableField from "../../../Fields/ClickableField";

export const SecurityOwnerList = () => {
  const translate = useTranslate();
  useSetEntityType("accounts.securityOwners");
  const preferenceKey = "securityOwnerList.datagrid";

  return (
    <AccountList
      hasInfo
      resource="security/owners"
      preferenceKey={preferenceKey}
      storeKey={"securityOwnerList"}
      sort={{ field: "id", order: "ASC" }}
      rowClick=""
      actions={
        <ListToolbar
          enableSearch={true}
          preferenceKey={preferenceKey}
          searchLabel="Search Owners"
          selectableColumns
        />
      }
      fixedColumns={[
        <ClickableField
          source="id"
          key="source"
          label={`accounts.finopsOwners.displayName`}
          cellClick={(id) =>
            `/spd/security-gaps?filter=${encodeURIComponent(
              `{"owner":"${id}"}`,
            )}`
          }
        >
          <NameField
            sx={{ textAlign: "center", display: "inline-block" }}
            source="id"
            label={`accounts.securityOwners.displayName`}
            sortBy="id"
            subtitle={false}
            tooltipTitle={
              <Typography>
                {translate("accounts.securityOwners.email.popover.title")}
              </Typography>
            }
          />
        </ClickableField>,
      ]}
      dynamicColumn={(source) => hoverableColumns(source)}
      header={
        <PageHeader
          title="Owners Overview"
          resource="Accounts"
          descriptionKey="accounts.securityOwners.infoText"
        />
      }
    />
  );
};

export const hoverableColumns = (column: string) => {
  if (column === PRISMA_ALERTS_STANDARD) {
    return (
      <TableField
        isLink={false}
        sx={{ textAlign: "center", display: "inline-block" }}
        source={column}
        label={`accounts.securityOwners.${column}.title`}
        key={`${column}`}
        fieldType={AlertField}
        tooltipContent={Breakdown}
      />
    );
  }

  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block" }}
      source={column}
      label={`security.columns.${column}.title`}
      key={`${column}`}
      fieldType={PercentageField}
      tooltipContent={Breakdown}
    />
  );
};
export default SecurityOwnerList;
