import { Box, Typography, Container } from "@mui/material";
import SearchOff from "@mui/icons-material/SearchOff";

const EmptyGrid = ({ message }: EmptyGridProps) => {
  return (
    <Box
      component={Container}
      sx={{ textAlign: "center", paddingY: 5 }}
      aria-label="No data available"
    >
      <Typography variant="h2" paragraph>
        <SearchOff sx={{ fontSize: 100 }} color="error" />
      </Typography>
      <Typography variant="h5">{message}</Typography>
    </Box>
  );
};

interface EmptyGridProps {
  message: string;
}

export default EmptyGrid;
