import { useContext } from "react";
import { useTranslate } from "react-admin";
import { AppContext } from "../../context/AppContext";
import { BreakdownProps } from "./Breakdown";
import DateUsedBreakdown from "./DateUsedBreakdown";
import { booleanToString } from "../../utils/booleanToString";

interface UsedAccountBreakdownProps extends BreakdownProps {
  timestamp?: string;
}

const UsedAccountBreakdown = (props: UsedAccountBreakdownProps) => {
  const { source, record, timestamp } = props;
  const appContext = useContext(AppContext);
  const entityType = appContext.entityType;
  const accountUsed = record[source];
  const translate = useTranslate();

  const additionalMessages = [
    {
      key: translate(`${entityType}.${source}.popover.userAccess`),
      value: booleanToString(accountUsed?.human_access),
    },
    {
      key: translate(`${entityType}.${source}.popover.provisionedResources`),
      value: booleanToString(accountUsed?.provisioned_resources),
    },
    {
      key: translate(`${entityType}.${source}.popover.CICDDeployments`),
      value: booleanToString(accountUsed?.cicd_access),
    },
  ];
  return (
    <DateUsedBreakdown
      source={source}
      additionalMessages={additionalMessages}
      timestamp={timestamp}
    />
  );
};

export default UsedAccountBreakdown;
