import { ShowContextProvider, useShowController, Loading } from "react-admin";
import { redirect, useParams } from "react-router-dom";
import PageHeader from "../../../Header/PageHeader";
import ListLayout from "../../../Layouts/ListLayout";
import useSetEntityType from "../../../../hooks/useSetEntityType";
import Breakdown, { BreakdownProps } from "../../../Breakdown/Breakdown";
import DetailButton from "../../../Breakdown/DetailButton";
import TableField from "../../../Fields/TableField";
import PercentageField from "../../../Fields/PercentageField";
import NameField from "../../../Fields/NameField";

const AccountShow = () => {
  const { id } = useParams();
  const { record, isLoading } = useShowController({
    resource: `assets/accounts`,
    id: id,
    disableAuthentication: true,
  });
  useSetEntityType("services");

  if (isLoading) return <Loading />;

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!id) redirect("/accounts");

  return (
    <ShowContextProvider value={record}>
      <PageHeader
        resource={"Products"}
        title={record.name}
        descriptionKey={"services.infoText"}
      />
      <ListLayout
        priorisedColumns={["hds", "gdpr", "soc2"]}
        preferenceKey="accountServices.datagrid"
        storeKey="accountServices"
        resource={`compliance/accounts/${encodeURIComponent(
          id as string,
        )}/services`}
        dynamicColumn={hoverableColumns}
        fixedColumns={fixedColumns}
      />
    </ShowContextProvider>
  );
};

const hoverableColumns = (column: string) => {
  const AccountShowBreakdown = (props: BreakdownProps) => {
    return (
      <Breakdown
        {...props}
        detailButton={
          <DetailButton
            standard={`${column}.standard_name`}
            service="prismaId"
          />
        }
      />
    );
  };

  return (
    <TableField
      isLink={false}
      sx={{ textAlign: "center", display: "inline-block" }}
      source={column}
      label={`compliance.columns.${column}`}
      key={`${column}`}
      fieldType={PercentageField}
      tooltipContent={AccountShowBreakdown}
    />
  );
};

export const fixedColumns = [
  <NameField
    sx={{ textAlign: "center", display: "inline-block" }}
    source="name"
    label={`services.displayName`}
    sortBy="name"
  />,
];

export default AccountShow;
