/**
 * Utils to handle range filter. A range filter is a filter with a min (AND/OR) a max floating value
 * A range filter is available in URL following pattern FILTER_NAME.min= OR FILTER_NAME.max=
 */
export const MIN_FILTER_SUFFIX = ".min";
export const MAX_FILTER_SUFFIX = ".max";

export const getMinFilterRange = (filterName: string): string =>
  `${filterName}${MIN_FILTER_SUFFIX}`;
export const getMaxFilterRange = (filterName: string): string =>
  `${filterName}${MAX_FILTER_SUFFIX}`;
