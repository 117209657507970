import { API } from "aws-amplify";

export const Services = {
  getFaqTextByDashboard: (dashboard: string): Promise<string> => {
    return API.get("TrustAPI", `static/faq%2F${dashboard}-faq.html`, {});
  },
  updateFaqTextbyDashboard: (
    dashboard: string,
    content: string,
  ): Promise<any> => {
    return API.put("TrustAPI", `static/faq%2F${dashboard}-faq.html`, {
      body: content,
      headers: {
        "Content-Type": "text/html",
      },
    });
  },
};
