import { fieldPropTypes } from "../../types/default";
import { Typography, TypographyProps, Box, Tooltip } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { memo, FC, ComponentType } from "react";
import Field from "./Field";
import { formatCurrency } from "../../utils/currency-utils";

const CostField: FC<CostFieldProps> = memo((props) => {
  const { source, tooltipContent: Content, field = "cost" } = props;
  const record = useRecordContext(props);
  const costValue = record?.[source]?.[field];
  const value = costValue ? formatCurrency(costValue) : "N/A";
  const textStyle = costValue
    ? { fontWeight: "600", color: "black" }
    : { color: "grey" };

  const costField = (
    <Box sx={{ padding: 1 }}>
      <Field value={value} isLink={false} styles={textStyle} {...props} />
    </Box>
  );

  if (Content && value != "N/A")
    return (
      <Tooltip
        classes={{ tooltip: "kpiPopover" }}
        title={<Content {...props} record={record} />}
      >
        {costField}
      </Tooltip>
    );

  return costField;
});

CostField.displayName = "CostField";

CostField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface CostFieldProps
  extends FieldProps,
    Omit<TypographyProps, "textAlign"> {
  source: string;
  field: string;
  tooltipContent?: ComponentType<any>;
}

export default CostField;
