import {
  Datagrid,
  FilterForm,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import PageHeader from "../Header/PageHeader";
import TagArrayField from "../Fields/TagArrayField";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import AdminUsersEdit from "./AdminUsersEdit";
import { TUser } from "../../types/default";

function AdminUsers() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<TUser | null>(null);

  const handleEditClick = (selectedRecord: TUser) => {
    setSelectedUser(selectedRecord);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpenDialog(false);
  };

  return (
    <>
      <PageHeader title="User Management" descriptionKey="admin.users.header" />
      <List
        actions={
          <TopToolbar>
            <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
              <Tooltip
                title={"Enter email to find matches starting with your input."}
              >
                <FilterForm
                  filters={[
                    <TextInput
                      label={"Search user"}
                      source="search"
                      alwaysOn
                    />,
                  ]}
                />
              </Tooltip>
            </Stack>
          </TopToolbar>
        }
        sx={{
          maxWidth: "66%",
          boxShadow: "none",
          "& .RaList-content": {
            boxShadow: "none",
          },
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="email" label="User" sortable={false} />
          <TagArrayField source="groups" sortable={false} />
          <FunctionField
            render={(record: TUser) => (
              <IconButton
                color="primary"
                onClick={() => {
                  handleEditClick(record);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          />
        </Datagrid>
      </List>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {selectedUser !== null && (
            <AdminUsersEdit
              id={selectedUser.id}
              onClose={handleCloseDialog}
              userGroups={selectedUser.groups}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AdminUsers;
