import { ReferenceInputProps, useTranslate } from "react-admin";
import CheckboxInput, { CheckboxInputProps } from "./common/CheckboxInput";
import { FiltersCommonInputProps } from "../../types/default";
import AutoCompleteInput from "./common/AutoCompleteInput";

const COST_CENTER_FILTER_KEY = "COST_CENTER_FILTER";
const CostCenterInput = (
  props: ReferenceInputProps & FiltersCommonInputProps,
) => {
  const translate = useTranslate();

  return (
    <AutoCompleteInput
      {...props}
      label={translate("filters.cost_center.label")}
      key={COST_CENTER_FILTER_KEY}
      attributes_to_get="cost_center"
      search_attribute="cost_center"
    />
  );
};

export const CostCenterMissingCheckboxInput = (props: CheckboxInputProps) => {
  const translate = useTranslate();
  const { label = translate("filters.missing_cost_center.label"), ...rest } =
    props;
  return (
    <CheckboxInput
      label={label}
      sx={{ mt: 1 }}
      valueOnCheck="false"
      valueOnUnCheck={undefined}
      {...rest}
    />
  );
};

export default CostCenterInput;
