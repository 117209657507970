import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useRecordContext } from "ra-core";
import { TextFieldProps as BaseTextFieldProps } from "react-admin";

interface ModalFieldProps extends BaseTextFieldProps {
  source?: string;
  emptyText?: string;
  title?: string;
  buttonText: string;
  icon?: React.ReactNode;
}

function ModalField(props: ModalFieldProps) {
  const { source, emptyText, title, buttonText, icon } = props;
  const record = useRecordContext(props);
  const value = source !== undefined ? record[source] : undefined;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button startIcon={icon} variant="text" onClick={handleOpenModal}>
        <Typography variant="inherit" noWrap>
          {buttonText}
        </Typography>
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="value-details"
        aria-describedby="value-details-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "33%",
            bgcolor: "white",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <Typography variant="h4" paddingBottom={1} color="primary">
            {title}
          </Typography>
          <Typography variant="body2" id="value-details-description">
            {value != null && typeof value !== "string"
              ? value
              : value || emptyText}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

ModalField.displayName = "ModalField";

export default ModalField;
