import PageHeader from "../../../Header/PageHeader";
import EmbeddedDashboard from "../../../Quicksight/EmbeddedDashboard";

function FocusReport() {
  return (
    <div>
      <PageHeader title="Dashboards focusing on time-sensitive initiatives" />
      <EmbeddedDashboard dashboardName="QuicksightFocusReport" />
    </div>
  );
}

export default FocusReport;
