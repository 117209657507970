import PageHeader from "../../../Header/PageHeader";
import EmbeddedDashboard from "../../../Quicksight/EmbeddedDashboard";

function StorageMonthlyCostReport() {
  return (
    <div>
      <PageHeader title="Storage/DB Cost and usage report" />
      <EmbeddedDashboard dashboardName="QuicksightStorageMonthlyCostReport" />
    </div>
  );
}

export default StorageMonthlyCostReport;
